
import ReactECharts from 'echarts-for-react';

function UsersChart({ activeUsers, suspendedUsers, timeoutedUsers }) {
    const option = {
        title: {
            text: 'Utilisateurs',
            textStyle: {
                color: 'black'
            },
            left: '50%',
            top: '0%',
            textAlign: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: 10,
            left: 'center'
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 20,
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: activeUsers, name: 'Actifs', itemStyle: { color: '#080814' } },
                    { value: timeoutedUsers, name: 'En pause', itemStyle: { color: '#f87d18' } },
                    { value: suspendedUsers, name: 'Suspendus', itemStyle: { color: '#ffcc44' } },
                ]
            }
        ]
    };

    return (
        <div className="d-flex justify-content-center card py-2" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
            <ReactECharts
                style={{ height: "300px", width: "100%" }}
                option={option}
            />
        </div>
    );
}

export default UsersChart;
