import { useEffect, useRef, useState } from "react";
import st from "./establishmentsList.module.css"
import { getEstablishmentsByProviderId, toggleHideEstablishment } from "../../../services/establishmentApi";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Footer from "../../../components/footer/footer";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import { getAllMenus } from "../../../services/menuApi";
import { Link } from "react-router-dom";
import ArchiveEstab from "../../../components/modals/provider/archiveEstab/archiveEstab";

function EstablishmentsList(props) {

  const { user, website } = props;
  const [search, setSearch] = useState('');
  const [establishments, setEstablishments] = useState([]);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const estabs = useRef(null);

  useEffect(() => {
    const id = user && user._id ? user._id : "";
    getEstablishmentsByProviderId(id, (cb) => {
      if (cb.status === 200) {
        if (cb.data && cb.data.establishments && cb.data.establishments.length > 0) {
          setEstablishments(cb.data.establishments.filter(estab => !estab.locked && !estab.deletesAt));
          estabs.current = cb.data.establishments.filter(estab => !estab.locked && !estab.deletesAt);
        }
        setTimeout(() => {
          setLoading(true);
        }, 500);
      }
    })
    getAllMenus((cb) => {
      if (cb.status === 200) {
        setMenus(cb.data.menus);
      }
    })
  }, [user, refresh]);

  useEffect(() => {
    if (search !== "") {
      setEstablishments(estabs.current.filter(establishment =>
        establishment.name.toLowerCase().includes(search.toLowerCase())
      ))
    } else {
      setEstablishments(estabs.current);
    }
  }, [search])

  const edit = (establishment) => {
    setReset(!reset);
    window.location.href = `/account/edit-establishment/${establishment._id}`;
  }

  const toggleHide = (id) => {
    toggleHideEstablishment(id, (cb) => {
      if (cb.status === 200) {
        setEstablishments(pv => pv.map(estb => estb._id === id ?
          {
            ...estb,
            hidden: !estb.hidden
          } : estb));
      }
    })
  }

  const inspectMenus = (e) => {
    window.location.href = `/account/establishment/${e._id}/menus`;
  }

  const expiresAt = (expiresAt) => {
    const today = new Date();
    const expiration = new Date(expiresAt);
    const daysDiff = Math.ceil((expiration - today) / (1000 * 3600 * 24));
    if (daysDiff > 30) return "green";
    if (daysDiff > 0) return "orange";
    return "red";
  };

  return (
    <div className="clientPage">
      <ArchiveEstab modalId='archiveProviderEstablishment' setRefresh={setRefresh} establishment={selectedEstablishment} reset={reset} />
      <div className="container-xl" style={{ minHeight: "49dvh" }}>
        <div className="row">
          <div className="col-sm-6 mt-4">
            <h3 className={`${st.pageTitle}`}>Éstablissements</h3>
          </div>
          <div className="col-sm-6 mt-4 text-end">
            <span className={`${st.expiresIn}`}></span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 mt-4">

          </div>
          <div className="col-lg-6 mt-3">
            <div className={`${st.inputGroup}`}>
              <input onChange={(event) => { setSearch(event.target.value) }} value={search} autoComplete="off"
                type="text" placeholder="Rechercher une établissement" className={`border-0 bg-light ${st.formControl}`} />
              <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
            </div>
          </div>
          <div className="col-lg-3 py-0 text-lg-end text-center mt-3">
            <button onClick={() => window.location.href = '/account/establishment-request'} className={`${st.addEstabs}`} >Demande d'établissement</button>
          </div>
        </div>
        <div className={`row d-flex justify-content-center`} style={{ marginTop: '25px', minHeight: "346.1px " }} >
          {loading ?

            <>
              {
                establishments && establishments.length > 0 ? establishments.map((establishment, i) =>
                  <div key={i} className={`${st.cardCol} mb-4`}>
                    <div className={`${st.card} card`}>
                      {/* {establishment && (new Date(establishment.expiresAt) < new Date()) &&
                        <span className={`${st.forbidden}`} >
                          <strong className={`${st.forbiddenText}`}>Éxpiré</strong>
                        </span>
                      } */}
                      <DeleteOutlineOutlinedIcon
                        data-bs-toggle="modal" data-bs-target={"#archiveProviderEstablishment"}
                        onClick={() => { setReset(!reset); setSelectedEstablishment(establishment); }}
                        className={`${st.delBtn} ${st.buttonsHover}`}
                      />
                      {establishment.hidden ?
                        <VisibilityOffOutlinedIcon
                          onClick={() => { toggleHide(establishment._id); }}
                          className={`${st.hideBtn} ${st.buttonsHover}`} />
                        :
                        <VisibilityOutlinedIcon
                          onClick={() => { toggleHide(establishment._id); }}
                          className={`${st.hideBtn} ${st.buttonsHover}`} />
                      }
                      <BorderColorOutlinedIcon
                        onClick={() => edit(establishment)}
                        className={`${st.editBtn} ${st.buttonsHover}`}
                      />
                      <div className={st.expiration}>
                        <span className="ms-2">Expire en : <span style={{ color: expiresAt(establishment.expiresAt) }}>{establishment.expiresAt.substring(0, 10)}</span></span>
                      </div>
                      <div className="row">
                        <div className={`${st.featuredCol} text-center`}>
                          <span className={`txtSuccess ${st.featured}`} >{establishment && establishment.featured && "Sponsorisé"}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center mt-0 mt-sm-2">
                          <Link to={`/place/${establishment.customUrl}`}>
                            <img alt="logo" className={`${st.estabsLogo}`}
                              src={establishment.logo ? process.env.REACT_APP_SERVER_PUBLIC + "" + establishment.logo : "/images/defaultEstablishment.png"} />
                          </Link>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col mt-2 text-center">
                          <Link to={`/place/${establishment.customUrl}`} className={st.link}>
                            <span className={st.estabsName} >{establishment.name}</span >
                          </Link>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col px-5 text-center">
                          <span>{establishment.type.join("  -  ")}</span>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col text-center">
                          <span>{establishment.location}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className={`${st.menusNumberBox}`}>
                            <span className={`${st.menusNumber}`} >
                              {menus && menus.filter(m => m.establishment === establishment._id).length}</span>
                            <span >
                              <RestaurantMenuIcon onClick={() => inspectMenus(establishment)} className={`mb-2 ${st.menusButton} ${st.buttonsHover}`} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) :
                  (<div className="text-center mt-5"><span>Vous n'avez pas encore des établissements !</span></div>)
              }
            </> :
            (
              <div className="col position-relative">
                <span className={`${st.loader}`}></span>
              </div>
            )
          }

        </div>
      </div>
      <Footer website={website} />
    </div>
  );
}

export default EstablishmentsList;
