import ReactECharts from 'echarts-for-react';

function EstabsRequestsChart(props) {
    const { requests, deniedRequests, notTreatedRequests, treatedRequests } = props;

    const option = {
        title: {
            text: "Demandes d'établissements",
            textStyle: {
                color: 'black'
            },
            left: '50%',
            top: '10%',
            textAlign: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: 10,
            left: 'center'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                center: ['50%', '70%'],
                startAngle: 180,
                endAngle: 360,
                data: [
                    { value: requests, name: 'Nb totales des demandes', itemStyle: { color: '#ffcc44' } },
                    { value: treatedRequests, name: 'Traitées', itemStyle: { color: '#368024' } },
                    { value: deniedRequests, name: 'Refusées' , itemStyle: { color: '#e93313' }},
                    { value: notTreatedRequests, name: 'Non traitées', itemStyle: { color: 'black' } }
                   
                ]
            }
        ]
    };

    return (
        <div className="d-flex justify-content-center card" style={{boxShadow :"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
            <ReactECharts
                style={{ height: "300px", width: "100%" }}
                option={option}
            />
        </div>
    );
}

export default EstabsRequestsChart;
