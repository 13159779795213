import { useEffect, useRef, useState } from "react";
import st from "./deleteEstablishment.module.css"
import { deleteEstablishment } from "../../../../services/establishmentApi";

function DeleteEstablishment(props) {
    const { establishment, setRefresh, reset, modalId } = props;
    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const closeBtn = useRef();

    useEffect(() => {
        setSuccess(false);
        setErr(false);
        setLoading(false);
    }, [reset])

    const removeEstablishment = () => {
        setErr(false);
        setSuccess(false);
        setLoading(true);
        deleteEstablishment(establishment._id, (cb) => {
            if (cb.status === 200) {
                setTimeout(() => {
                    setLoading(false);
                    setSuccess(true);
                    setRefresh(r => !r)
                }, 500);
                setTimeout(() => {
                    setSuccess(false);
                    closeBtn.current.click();
                }, 1500);
                return
            } else {
                if (cb.response && cb.response.status === 422) {
                    setTimeout(() => {
                        setLoading(false);
                        setErr("menus");
                    }, 500);
                    return;
                } else {
                    setTimeout(() => {
                        setLoading(false);
                        setErr("server");
                    }, 500); return;

                }

            }

        })
    }

    return (
        <div className={`modal fade`} id={modalId} tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-md`}>
                <div className={`modal-content ${st.modalContent}`}>
                    {loading ? (<span className={`m-5 ${st.loaderBox}`}><span className={`${st.loader}`}></span></span>) : (<>
                        <div className="row relative">
                            <div className="col">
                                <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                                <h4 className='mt-4 ms-4'>Supprimer l'établissement</h4>
                            </div>
                        </div>
                        <div className="modal-body mt-1 px-0 px-4">
                            <div className="row mb-2">
                                <div className="col">
                                    <span>Souhaitez-vous supprimer
                                        <span style={{ color: "#FFCC44" }} > {establishment && establishment.name} </span>
                                        de façon permanente ? <br />
                                        <i>NB :</i> Toutes les catégories, articles et menus liés à cet établissement seront supprimés automatiquement.
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    <span className={err ? "txtDanger" : success ? "txtSuccess" : ""}>
                                        {success && "Supprimé avec succès"}
                                        {err === "server" && "Un problem est survenue, réessayer plus tard!"}
                                        {err === "menus" && "Veuillez supprimer les menus de cet établissement"}
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-3" >
                                <div className="col text-end">
                                    <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                    <button onClick={removeEstablishment} className={`${st.save}`}>Supprimer</button>
                                </div>
                            </div>
                        </div>
                    </>)}
                </div>
            </div>
        </div>
    );
}

export default DeleteEstablishment;