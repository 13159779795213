import axios from 'axios'
axios.defaults.withCredentials = true;

//--------------------------------------------MENU---------------------------------------------------//

//Add a new menu
export const addMenu = (data, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/menu/add`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Edit the QR code for the menu
export const addMenuQrCode = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/menu/qrcode/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Edit the photo of the menu
export const addMenuPhoto = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/menu/photo/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get a menu by id
export const getMenuById = (id, callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/menu/one/${id}`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get menus by establishment
export const getMenusByEstablishment = (id, callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/menu/establishment/${id}/list`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get menus by establishment
export const getAllMenus = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/menu/all`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Update a menu
export const updateMenu = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/menu/update/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Delete a menu
export const deleteMenu = (id, callback) => {
    axios.delete(`${process.env.REACT_APP_BASE_URL}/menu/delete/${id}`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//--------------------------------------------ITEMS---------------------------------------------------//

//Add an item for the menu
export const getCategoryItems = (data, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/menu/items`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Add an item for the menu
export const addItem = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/menu/item/add`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Update an item from the menu
export const updateItem = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/menu/item/update/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Delete an item from the menu
export const deleteItem = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/menu/item/delete/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//--------------------------------------------CATEGORY---------------------------------------------------//

//Add an item for the menu
export const addCategory = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/menu/category/add`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Add an item for the menu
export const deleteCategory = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/menu/category/delete`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Add an item for the menu
export const updateCategory = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/menu/category/update`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Add an item for the menu
export const sortCategories = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/menu/category/sort`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}