import axios from "axios";

//Send an email to reset the password
export const forgotPassword = (email, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/mail/forgot-password`, email)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Reset the password
export const resetPassword = (newPassword, token, callback) => {
    const headers = {
        authorization: `Bearer ${token}`
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/mail/reset-password`, newPassword, { headers })
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Send an email Verification 
export const verifyEmail = (email, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/mail/verify-email`, email)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Send an email to invite provider
export const inviteProvider = (acceptMail, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/mail/invitation`, acceptMail)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Send an email accept establishment
export const acceptEstablishment = (acceptMail, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/mail/accept-establishment`, acceptMail)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Send email refusing the request
export const declineProvider = (declineMail, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/mail/decline`, declineMail)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Send email refusing the request
export const declineEstablishment = (data, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/mail/decline-establishment`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}