import { useEffect, useState } from 'react';
import st from './menuDisplay.module.css'
import Tooltip from '@mui/material/Tooltip'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getMenusByEstablishment } from '../../../../services/menuApi';

function MenuDisplay(props) {
    const { establishment } = props;
    const [menus, setMenus] = useState()
    const id = establishment ? establishment._id : '';

    useEffect(() => {
        getMenusByEstablishment(id, (cb) => {
            if (cb.status === 200) {
                setMenus(cb.data.menus)
            } else console.error(cb);
        })
    }, [id])


    const openMenu = (menu) => {
        window.location.href = `/menu/${menu._id}`
    }

    return (
        <div className={`card mb-4 ps-2 pe-2 ${st.card}`}>
            {/* Menu */}

            <div className={`accordion accordion-flush`} id="accordionMenuParent">
                <div className={`accordion-item ${st.item}`}>
                    <h6 className="accordion-header">
                        <button style={{ color: "black" }} className={`accordion-button collapsed ${st.button}`} type="button"
                            data-bs-toggle="collapse" data-bs-target="#menuAccordion" aria-expanded="false" aria-controls="menuAccordion">
                            <h5>Menus</h5>
                        </button>
                    </h6>
                    <div id="menuAccordion" className={`accordion-collapse collapse`} data-bs-parent="#accordionMenuParent">
                        <div className="accordion-body px-0 pt-1">
                            <div className="row ps-2 mb-3">
                                {menus && menus.map((menu, i) => (
                                    <div key={i} className={`col-sm-6 pb-2 ${st.separator}`} style={{ borderRight: i % 2 === 0 ? '1px solid #000' : 'none' }}>
                                        <Tooltip title='Voir le menu' arrow>
                                            <div className={`row ms-2 me-2  ${st.menuName}`} type="button" onClick={() => { openMenu(menu) }}>
                                                <div className={`col-9 `}  >{menu.name}</div>
                                                <div className="col-3 text-center" ><VisibilityOutlinedIcon /></div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default MenuDisplay;