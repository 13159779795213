import { useEffect, useState } from "react";
import st from "./estabRequests.module.css"
import Pagination from "../../../pagination/pagination";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { getEstabsRequests } from "../../../../services/adminApis";
import TreatEstab from "../../../modals/treatEstab/treatEstab";
import io from "socket.io-client";
function EstabRequests(props) {
    const { setNotifications, requestNotify } = props;
    const [newRequest, setNewRequest] = useState(null);
    useEffect(() => {
        const socket = io.connect(process.env.REACT_APP_SOCKET_URL);
        socket.on("receiveEstabRequest", (data) => {
            if (data.request) {
                setNewRequest(data.request);
                requestNotify();
            }
        })
        return () => {
            socket.off("receiveEstabRequest");
        };
    }, [requestNotify])
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 10;

    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState('all');
    const [sort, setSort] = useState('recent');

    const [requests, setRequests] = useState([]);
    const [requestsToPaginate, setRequestsToPaginate] = useState([]);

    const [reset, setReset] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState({});

    const [allRequests, setAllRequests] = useState([]);
    const [notTeatedRequests, setNotTreatedRequests] = useState([]);
    const [treatedRequests, setTreatedRequests] = useState([]);
    const [heldRequests, setHeldRequests] = useState([]);
    const [deniedRequests, setDeniedRequests] = useState([]);

    useEffect(() => {
        getEstabsRequests((cb) => {
            if (cb.status === 200) {
                setAllRequests(cb.data.requests);
                setNotTreatedRequests(cb.data.notTreated);
                setTreatedRequests(cb.data.treated);
                setHeldRequests(cb.data.held);
                setDeniedRequests(cb.data.denied);
                if (cb.data && cb.data.notTreated) {
                    setNotifications(pv => pv = {
                        ...pv,
                        establishment: cb.data.notTreated
                    })
                }
            }
        })
        setItemOffset(0)
        setPageCount(0)
    }, [search, refresh, setNotifications])

    useEffect(() => {
        if (newRequest) {
            if (!allRequests.includes(newRequest)) {
                setAllRequests(prv => prv = [...prv, newRequest]);
                setNotTreatedRequests(prv => prv = [...prv, newRequest]);
                setNotifications(pv => pv = {
                    ...pv,
                    establishment: [...pv.establishment, newRequest]
                })
                setNewRequest(null);
            }
        }
    }, [newRequest, allRequests, setNotifications])

    useEffect(() => {
        if (search === "") {
            let filtered;
            if (filter === "all") {
                setRequestsToPaginate(allRequests);
                filtered = allRequests;
            } else if (filter === "treated") {
                setRequestsToPaginate(treatedRequests);
                filtered = treatedRequests;
            } else if (filter === "notTreated") {
                setRequestsToPaginate(notTeatedRequests);
                filtered = notTeatedRequests;
            } else if (filter === "held") {
                setRequestsToPaginate(heldRequests);
                filtered = heldRequests;
            } else if (filter === "denied") {
                setRequestsToPaginate(deniedRequests);
                filtered = deniedRequests;
            }

            sort === "old" && setRequestsToPaginate(filtered)
            sort === "recent" && setRequestsToPaginate([...filtered].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
            sort === "nameAZ" && setRequestsToPaginate([...filtered].sort((a, b) => a.name.localeCompare(b.name)))
            sort === "nameZA" && setRequestsToPaginate([...filtered].sort((a, b) => b.name.localeCompare(a.name)))
        }
        else {
            setRequestsToPaginate(allRequests.filter((request) => {
                for (let key in request) {
                    if (typeof request[key] === 'string' && request[key].toLowerCase().includes(search.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            }));
        }
    }, [search, filter, sort, allRequests, notTeatedRequests, treatedRequests, heldRequests, deniedRequests])

    return (
        <div className="contianer-fluid p-4">
            <TreatEstab request={selectedRequest} reset={reset} refresh={refresh} setRefresh={setRefresh} />
            <div className="row">
                <div className="col-lg-5 ps-5">
                    <h3>Demandes d'ajout d'établissements</h3>
                </div>
                <div className="col-lg-7 mb-2">
                    <div className={`${st.inputGroup}`}>
                        <form autoComplete='off' >
                            <input onChange={(event) => { setSearch(event.target.value) }} value={search} autoComplete='false'
                                type="text" placeholder="Rechercher une demande" className={`border-0 bg-light ${st.formControl}`} />
                            <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row mb-5 mt-1">
                <div className="col position-relative">
                    <div className={`${st.filterBox}`}>
                        <span className={`btn btn-dark dropdown-toggle ${st.filter}`} data-bs-toggle="dropdown" aria-expanded="false">Filter</span>
                        <ul className="dropdown-menu">
                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'all' ? "active" : ""}`} onClick={() => { setFilter('all') }}>Tous</span></li>
                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'treated' ? "active" : ""}`} onClick={() => { setFilter('treated') }}>Traitées</span></li>
                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'held' ? "active" : ""}`} onClick={() => { setFilter('held') }}>En cours</span></li>
                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'notTreated' ? "active" : ""}`} onClick={() => { setFilter('notTreated') }}>Non traitées</span></li>
                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'denied' ? "active" : ""}`} onClick={() => { setFilter('denied') }}>Réfusées</span></li>
                        </ul>
                    </div>
                    <div className={`${st.sortBox}`}>
                        <span className={`btn btn-dark dropdown-toggle ${st.sort}`} data-bs-toggle="dropdown" aria-expanded="false">Sort</span>
                        <ul className="dropdown-menu">
                            <li><span className={`dropdown-item ${st.sortings} ${sort === 'recent' ? "active" : ""}`} onClick={() => { setSort('recent') }}>Récentes</span></li>
                            <li><span className={`dropdown-item ${st.sortings} ${sort === 'old' ? "active" : ""}`} onClick={() => { setSort('old') }}>Ancien </span></li>
                            <li><span className={`dropdown-item ${st.sortings} ${sort === 'nameAZ' ? "active" : ""}`} onClick={() => { setSort('nameAZ') }}>Nom A à Z</span></li>
                            <li><span className={`dropdown-item ${st.sortings} ${sort === 'nameZA' ? "active" : ""}`} onClick={() => { setSort('nameZA') }}>Nom Z à A</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={`row ${st.tableBox}`}>
                <div className={`col`} style={{ overflow: "auto", marginBottom: "10px" }}>
                    {requests && requests.length > 0 ?
                        <table className={`table`}>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "100px", textAlign: "center" }}>Nom </th>
                                    <th style={{ minWidth: "0px", textAlign: "center" }}>Téléphone </th>
                                    <th style={{ minWidth: "98px", textAlign: "center" }}>Adresse</th>
                                    <th style={{ minWidth: "98px", textAlign: "center" }}>Type</th>
                                    <th style={{ minWidth: "96px", textAlign: "center" }}>Status </th>
                                    <th style={{ minWidth: "3px", textAlign: "center" }}>Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {requests.map((req, key) =>
                                    <tr key={key}>
                                        <td style={{ paddingTop: "15px", textAlign: "center" }}>{req.name}</td>
                                        <td style={{ paddingTop: "15px", textAlign: "center" }}>{req.phone}</td>
                                        <td style={{ paddingTop: "15px", textAlign: "center" }}>{req.location}</td>
                                        <td style={{ paddingTop: "15px", textAlign: "center" }}>{req.type && req.type.length > 0 && req.type.join(' - ')}</td>
                                        <td style={{ paddingTop: "15px", textAlign: "center" }}>
                                            {req.status === "notTreated" && <div className={"text-warning"}>Non traitée</div>}
                                            {req.status === "treated" && <div className={"text-success"}>Traitée</div>}
                                            {req.status === "held" && <div className={"text-primary"}>En cours</div>}
                                            {req.status === "denied" && <div className={"text-danger"}>Refusée</div>}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <button onClick={() => { setSelectedRequest(req); setReset(!reset) }} data-bs-toggle="modal" data-bs-target="#treatEstab" style={{ marginRight: "5px" }}
                                                className={`btn ${req.status !== "treated" && st.AddBusinessIcon}`} disabled={req.status === "treated" && true}>
                                                <AddBusinessIcon />
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        : (
                            <div className="text-center mt-5">Aucune demande n'est disponible.</div>
                        )}

                </div>
                <Pagination pageCount={pageCount} setPageCount={setPageCount} itemsPerPage={itemsPerPage} itemOffset={itemOffset} setItemOffset={setItemOffset} items={requestsToPaginate} setCurrentItems={setRequests} />
            </div>
        </div>
    );
}

export default EstabRequests;