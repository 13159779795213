import { useRef } from "react";
import st from "./deleteMenu.module.css"
import { deleteMenu } from "../../../../services/menuApi";
function DeleteMenu(props) {
    const { menus, menu, setActiveMenu, activeMenu, setFeedBack, setErr, setSuccess, setRefresh } = props;
    const closeBtn = useRef(null);
    const removeMenu = () => {
        setSuccess(false);
        setErr(false);
        deleteMenu(menu._id, (cb) => {
            if (cb.status === 200) {
                setSuccess(true);
                setFeedBack('Supprimer avec succès');
                setRefresh(prv => !prv)
                if (menus.length > 0 && menu._id === activeMenu._id) {
                    setActiveMenu(menus[0])
                }
                closeBtn.current.click();
                return;
            } else {
                setErr(true);
                setFeedBack("Un problem est survenu, réessayer plus tard");
            }
        })
    }

    return (
        <div className={`modal fade`} id="deleteMenuModal" tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-md`}>
                <div className={`modal-content ${st.modalContent}`}>
                    <div className="row relative">
                        <div className="col">
                            <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                            <h4 className='mt-4 ms-4'>Supprimer menu</h4>
                        </div>
                    </div>
                    <div className="row mt-2 ps-4 pe-4">
                        <div className="col">
                            <span>Êtes-vous sur de vouloir supprimer {menu && menu.name} ?</span> <br />
                            <span>Tous les articles de ce menu seront supprimés ! </span>
                        </div>
                    </div>
                    <div className="row mt-4 mb-3 pe-md-3" >
                        <div className="col text-md-end text-center">
                            <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                            <button onClick={removeMenu} className={`${st.save}`}>Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteMenu;