import { useEffect, useState, useRef } from "react";
import st from "./updateUserAccount.module.css"
import { updateUserAccount } from "../../../services/adminApis";


function UpdateUserAccount(props) {
    const { user, reset, modalId, setAction } = props;

    const closeBtn = useRef();

    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);
    const initialValues = {
        firstname: "",
        lastname: "",
        phone: "",
        birthdate: "",
        email: ""
    }
    const [formValues, setFormValues] = useState(initialValues);
    const [avatar, setAvatar] = useState();
    const [load, setLoad] = useState(false);
    const [update, setUpdate] = useState(false);

    const imgView = useRef(null);
    const inputFile = useRef(null);

    useEffect(() => {
        setFormValues({
            firstname: user && user.firstname ? user.firstname : "",
            lastname: user && user.lastname ? user.lastname : "",
            phone: user && user.phone ? user.phone : "",
            birthdate: user && user.birthdate ? user.birthdate.slice(0, 10) : "",
            email: user && user.email ? user.email : ""
        })
    }, [user]);

    useEffect(() => {
        setErr(false);
        setSuccess(false);

    }, [reset])

    const uploadImage = (e) => {
        setErr(false)
        if (inputFile.current.files.length > 0) {
            const imgLink = URL.createObjectURL(inputFile.current.files[0]);
            imgView.current.src = imgLink;
            setAvatar(inputFile.current.files[0]);
        } else {
            console.error("No file selected.");
            setLoad(false);
            setErr("file");
        }
    };

    const dragOver = (e) => {
        e.preventDefault();
    }
    const drop = (e) => {
        e.preventDefault();
        inputFile.current.files = e.dataTransfer.files;
        uploadImage();
    }

    const handleChange = (event) => {
        setErr(false)
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const UpdateUserAccount = (e) => {
        e.preventDefault();
        if (!formValues.firstname || !formValues.lastname || !formValues.email) {
            setErr("req");
            return;
        }
        setErr(false)
        setLoad(true);

        const formData = new FormData();

        for (const key in formValues) {
            if (formValues[key] !== null && formValues[key] !== undefined) {
                formData.append(key, formValues[key])
            }
        }
        if (avatar instanceof File) {
            formData.append("avatar", avatar);
        }
        // for (const pair of formData.entries()) {
        //     console.log(pair[0], pair[1]);
        // }
        updateUserAccount(user._id, formData, (cb) => {
            console.log(cb);
            if (cb.status === 200) {
                setTimeout(() => { setLoad(false); setUpdate(true); }, 2000);
                setTimeout(() => { setUpdate(false) }, 4000);
                setAction(a => !a);
            } else if (cb.response.status === 409 && cb.response.data.duplicated === "phone") {
                setTimeout(() => { setLoad(false); setErr('phone'); }, 2000);
            } else if (cb.response.status === 409 && cb.response.data.duplicated === "email") {
                setTimeout(() => { setLoad(false); setErr('email'); }, 2000);
            } else {
                setTimeout(() => { setLoad(false); setErr('server'); }, 2000);
            }
        })
    };

    return (
        <div className={`modal fade`} id={`${modalId ? modalId : ""}`} tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-xl`}>
                <div className={`modal-content ${st.modalContent}`}>
                    <div className="row relative">
                        <div className="col">
                            <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                            <h4 className='mt-4 ms-4'>Modifier le profil</h4>
                        </div>
                    </div>
                    <div className="modal-body mt-1 px-0 ps-4 pe-4">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className={`ps-3 pe-3 mb-3 ${st.photoCard}`}
                                    onDragOver={dragOver}
                                    onDrop={drop}>
                                    <div className="row text-lg-center mt-4">
                                        <h5>Photo de profil</h5>
                                    </div>
                                    <div className="row pt-2 pb-3">
                                        <div className="text-center">
                                            <input ref={inputFile} type="file" accept="image/*" onChange={uploadImage} hidden />
                                            <img id="img-view" ref={imgView} className={`object-fit-cover ${st.img}`}
                                                src={user && user.avatar ? user.avatar.includes('http') ? user.avatar : `${process.env.REACT_APP_SERVER_PUBLIC}${user.avatar}` : "/images/userdefault.png"}
                                                alt="avatar" onClick={() => inputFile.current.click()} />
                                        </div>
                                    </div>
                                    {err === "file" && (
                                        <div className="row text-center mb-2">
                                            <span className='txtDanger'>Impossible de sélectionner l'image</span>
                                        </div>
                                    )}
                                    <div className="row mb-3 text-center">
                                        <h6>
                                            Faites glisser et déposez ou <span className={`${st.upload}`}
                                                onClick={() => inputFile.current.click()}> cliquez ici </span> pour télécharger l'image
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className={`${st.infoCard} ps-lg-5 mb-4`}>
                                    <div className={`mt-4`}>
                                        <h5>Détails du profil </h5>
                                    </div>
                                    <div className={`card-body`}>
                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6 ">
                                                <label className="small mb-1">Nom<span className="text-danger">*</span></label>
                                                <input className={`form-control ${err === "req" && !formValues.lastname ? "is-invalid" : ""}`}
                                                    type="text" placeholder="Entrez votre nom" name="lastname" onChange={handleChange}
                                                    value={formValues.lastname}
                                                />
                                            </div>

                                            <div className="col-md-6 ">
                                                <label className="small mb-1">Prénom<span className="text-danger">*</span></label>
                                                <input
                                                    className={`form-control ${err === "req" && !formValues.firstname ? "is-invalid" : ""}`}
                                                    type="text" placeholder="Entez votre prénom" name="firstname" onChange={handleChange}
                                                    value={formValues.firstname}
                                                />
                                            </div>
                                        </div>

                                        <div className="row gx-3 mb-3">
                                            <div className="col-md-6">
                                                <label className="small mb-1">
                                                    Numéro de téléphone
                                                </label>
                                                <input className={`form-control ${err === "phone" ? "is-invalid" : ""}`}
                                                    type="tel" placeholder="Entez votre numéro de téléphone"
                                                    name="phone" onChange={handleChange} value={!formValues.phone ? '' : formValues.phone}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="small mb-1">Birthdate</label>
                                                <input className={`form-control `} type="date" name="birthdate" onChange={handleChange} value={formValues.birthdate} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="mb-3">
                                                <label className="small mb-1">Adresse E-mail</label>
                                                <input className={`form-control ${err === "email" ? "is-invalid" : ""}`}
                                                    type="email" placeholder="Enter your email address" name="email" onChange={handleChange} value={formValues.email} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            {load && (<div className="d-flex justify-content-center mb-2"><div className={`${st.loader}`}></div></div>)}
                                            {update && (<div className="d-flex justify-content-center mb-2"><div className={`text-success`}>Profil enregistré</div></div>)}
                                            <div className="d-flex justify-content-center mb-2">
                                                <span className={`txtDanger`}>
                                                    {err === "req" && "Veuillez remplir tous les champs obligatoires"}
                                                    {err === "phone" && "Le numéro de téléphone est déjà utilisé"}
                                                    {err === "email" && "L'adresse email est déjà utilisé"}
                                                    {err === "server" && "Un problème est survenu"}
                                                </span>
                                                <span className="txtSuccess" >
                                                    {success && "Enregistré avec succès"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col text-end">
                                <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                <button onClick={UpdateUserAccount} className={`${st.save}`}>Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateUserAccount;