import st from "./footer.module.css"
import { Link } from 'react-router-dom'
function Footer(props) {
    const { website } = props;

    return (

        <div>
            <hr className={st.hr} />
            <div className="container">
                <div className="row mt-5">
                    <div className="col-lg-5">
                        <div className="row mb-4">
                            <div className="col-2">
                                <img src={website && website.logo ? `${process.env.REACT_APP_SERVER_PUBLIC + "" + website.logo}` : "/images/logo-icon.png"} 
                                    alt="MenuOnline" className={`${st.logo}`} /></div>
                            <div className="col-9 px-0 mt-2"><b className={`${st.logoText}`}>{website && website.name ? website.name : "Title"}</b></div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p>Consulter notre répertoire de menu en ligne et choisir la bonne destination pour vous et vos proches.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className={`row mb-3`}><b>Entreprise</b></div>
                        <div className={`row mb-2`}><Link to={"/"} className={`${st.links}`}>Accueil</Link></div>
                        <div className={`row mb-2`}><Link to={"/about-us"} className={`${st.links}`}>À propos</Link></div>
                        <div className={`row mb-2`}><Link to={"/contact"} className={`${st.links}`}>Contact</Link></div>
                        <div className={`row mb-2`}><Link className={`${st.links}`}>Devenir prestataire</Link></div>
                    </div>
                    <div className="col-lg-2">
                        <div className={`row mb-3`}><b>Avantages</b></div>
                        <div className={`row mb-2`}><span className={`${st.links}`}>Économie de coûts</span></div>
                        <div className={`row mb-2`}><span className={`${st.links}`}>Mise à jour facile</span></div>
                        <div className={`row mb-2`}><span className={`${st.links}`}>Visibilité en ligne</span></div>
                        <div className={`row mb-4`}><span className={`${st.links}`}>Accès facile</span></div>
                    </div>
                    <div className="col-lg-3">
                        <div className={`row mb-3`}><b>Contactez-nous</b></div>
                        <div className={`row ${st.links}`}><p>Email: {website && website.email ? website.email : "support@menuonline.tn"}</p></div>
                        <div className={`row ${st.links} mb-2`}>
                            <p>Phone: (+216) {website && website.phone}</p>
                        </div>
                        <div className={`row text-center`}>
                            <div className="col-3">
                                <Link to={`${website && website.socials.facebook ? website.socials.facebook : "http://facebook.com"}`} target="_blank" className={`${st.fb}`}><i className={`${st.socials} bi bi-facebook`}></i></Link>
                            </div>
                            <div className="col-3">
                                <Link to={`${website && website.socials.instagram ? website.socials.instagram : "http://instagram.com"}`} target="_blank" className={`${st.inst}`}><i className={`${st.socials} bi bi-instagram`}></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={st.hr} />
                <div className="row">
                    <div className="col text-center text-muted mt-2 mb-4">
                        {new Date().getFullYear()} © {website && website.name}. All rights reserved. Powered by <Link style={{ color : "black" }}  target="_blank" to={"https://"+website.powered}><b><u>{website && website.powered.split('.')[0]}</u></b></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;