import { useEffect, useRef, useState } from "react";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import st from "./background.module.css";
import { setAboutusBackground, setAuthBackground, setContactBackground, setHomeBackground } from "../../../../../services/website";

function Backgrounds(props) {
    const { title, role, website } = props;
    const imgView = useRef(null);
    const dropArea = useRef(null);
    const inputFile = useRef(null);
    const [photo, setPhoto] = useState(null);
    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (website && (
            (website.authBackground && role === "auth") ||
            (website.homeBackground && role === "home") ||
            (website.aboutusBackground && role === "aboutus") ||
            (website.contactBackground && role === "contact")
        )) {
            dropArea.current.style.display = "none";
            imgView.current.style.display = 'block';
        }
    }, [website, role])

    const upImg = () => {
        setErr(false);
        if (inputFile.current.files.length > 0) {
            const imgLink = URL.createObjectURL(inputFile.current.files[0]);
            dropArea.current.style.display = "none";
            imgView.current.style.display = 'block'
            imgView.current.src = imgLink;
            setPhoto(inputFile.current.files[0]);
        }
    }

    const drop = (e) => {
        e.preventDefault();
        inputFile.current.files = e.dataTransfer.files;
        upImg();
    }

    const submit = () => {
        setErr(false);
        setSuccess(false);
        const formData = new FormData();
        if (role && role === "auth") {
            setLoading(true);
            if (photo instanceof File) {
                formData.append("authBackground", photo);
            }
            setAuthBackground(formData, (cb) => {
                if (cb.status === 200) {
                    setTimeout(() => {
                        setLoading(false);
                        setSuccess(true);
                    }, 500);

                } else {
                    setTimeout(() => {
                        setLoading(false);
                        setErr(true);
                    }, 500);
                }
                setTimeout(() => {
                    setSuccess(false);
                }, 2000);
            });
        }
        if (role && role === "contact") {
            setLoading(true);
            if (photo instanceof File) {
                formData.append("contactBackground", photo);
            }
            setContactBackground(formData, (cb) => {
                if (cb.status === 200) {
                    setTimeout(() => {
                        setLoading(false);
                        setSuccess(true);
                    }, 500);

                } else {
                    setTimeout(() => {
                        setLoading(false);
                        setErr(true);
                    }, 500);
                }
                setTimeout(() => {
                    setSuccess(false);
                }, 2000);
            });
        }
        if (role && role === "home") {
            setLoading(true);
            if (photo instanceof File) {
                formData.append("homeBackground", photo);
            }
            setHomeBackground(formData, (cb) => {
                if (cb.status === 200) {
                    setTimeout(() => {
                        setLoading(false);
                        setSuccess(true);
                    }, 500);

                } else {
                    setTimeout(() => {
                        setLoading(false);
                        setErr(true);
                    }, 500);
                }
                setTimeout(() => {
                    setSuccess(false);
                }, 2000);
            });
        }
        if (role && role === "aboutus") {
            setLoading(true);
            if (photo instanceof File) {
                formData.append("aboutusBackground", photo);
            }
            setAboutusBackground(formData, (cb) => {
                if (cb.status === 200) {
                    setTimeout(() => {
                        setLoading(false);
                        setSuccess(true);
                    }, 500);

                } else {
                    setTimeout(() => {
                        setLoading(false);
                        setErr(true);
                    }, 500);
                }
                setTimeout(() => {
                    setSuccess(false);
                }, 2000);
            });
        }
    }

    return (
        <div className={`card mt-3 px-3 ${st.backgroundCards}`}>
            <h5 className='mt-3 text-center'>{title}</h5>
            <div ref={dropArea} className={`${st.dropArea}`}
                onDragOver={(e) => { e.preventDefault() }}
                onDrop={drop}
                onClick={() => inputFile.current.click()}>
                <CloudUploadOutlinedIcon className={st.cloudUpload} />
            </div>
            <img ref={imgView} className={`${st.imgView} object-fit-cover mb-2`} alt="" style={{ display: "none" }}
                onClick={() => inputFile.current.click()}
                onDragOver={(e) => { e.preventDefault() }}
                onDrop={drop}
                src={website && role &&
                    (role === "auth" && website.authBackground ?
                        `${process.env.REACT_APP_SERVER_PUBLIC}${website.authBackground}` :
                        role === "contact" && website.contactBackground ?
                            `${process.env.REACT_APP_SERVER_PUBLIC}${website.contactBackground}` :
                            role === "home" && website.homeBackground ?
                                `${process.env.REACT_APP_SERVER_PUBLIC}${website.homeBackground}` :
                                role === "aboutus" && website.aboutusBackground ?
                                    `${process.env.REACT_APP_SERVER_PUBLIC}${website.aboutusBackground}` :
                                    "")
                }
            />
            <input ref={inputFile} type="file" accept="image/*" onChange={upImg} hidden />
            <div className="text-center">
                {success && <span className="txtSuccess">Enregistré avec succès</span>}
                {err && <span className="txtDanger">Un problem est survenue, réessayer plus tard !</span>}
            </div>
            {loading && (
                <div className="d-flex justify-content-center mt-2 mb-2">
                    <div className={`${st.loading}`}></div>
                </div>)}
            <button className={`${st.save} mt-2`} onClick={submit}>Enregistrer</button>
        </div>
    );
}

export default Backgrounds;