import React, { useState, useEffect, useRef } from 'react';
import st from "./manageUserAccount.module.css"

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setUserStatus } from '../../../services/adminApis';


function ManageUserAccount(props) {
    const { user, reset, modalId, refresh, setRefresh } = props;
    const closeBtn = useRef();

    const [loading, setLoading] = useState(false);
    const [listDisplay, setListDisplay] = useState(false);
    const [status, setStatus] = useState('');
    const [timeoutValue, setTimeoutValue] = useState(null);
    const [inputId, setInputId] = useState(null);
    const [confirm, setConfirm] = useState(false);

    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setErr(false);
        setSuccess(false);
        setStatus('');
        setInputId(null);
        setTimeoutValue(null)
    }, [reset])

    useEffect(() => {
        if (status === "timeout" && inputId !== "twoMonths" && inputId !== "sixMonths" && inputId !== "other") {
            const today = new Date();
            document.getElementById('oneMonth').checked = true;
            setTimeoutValue((new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())).toISOString());
        }
    }, [status, inputId])

    const handleRadioChange = (e) => {
        setErr(false);
        setInputId(e.target.id)
        const today = new Date();
        e.target.id === "oneMonth" && setTimeoutValue((new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())).toISOString());
        e.target.id === "twoMonths" && setTimeoutValue((new Date(today.getFullYear(), today.getMonth() + 2, today.getDate())).toISOString());
        e.target.id === "sixMonths" && setTimeoutValue((new Date(today.getFullYear(), today.getMonth() + 6, today.getDate())).toISOString());
    }

    const submit = () => {
        setErr(false);
        if (confirm) {
            if (!status) {
                setErr("required");
                return;
            }
            let data = {
                active: false,
                timeout: undefined,
                suspend: false
            };
            if (status === "active") data.active = true;
            if (status === "suspend") data.suspend = true;
            if (status === "timeout") {
                data.timeout = timeoutValue;
            }
            setLoading(true);
            setUserStatus(user._id, data, (cb) => {
                if (cb.status === 200) {
                    setSuccess(true);
                    setRefresh(!refresh);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    setTimeout(() => {
                        closeBtn.current.click();
                    }, 2000);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    setErr("server");
                    console.error(cb);
                }
            })
        } else {
            console.error("Confirm your choice please !");
        }

    }


    return (
        <div className={`modal fade`} id={`${modalId ? modalId : ""}`} tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-md`}>
                <div className={`modal-content ${st.modalContent}`}>
                    {loading ?
                        (<span className={`m-5 ${st.loaderBox}`}><span className={`${st.loader}`}></span></span>)
                        :
                        (<>
                            <div className="row relative">
                                <div className="col">
                                    <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                                    <h4 className='mt-4 ms-4'>Suspension d'utilisateur</h4>
                                </div>
                            </div>
                            <div className="modal-body ps-4 pe-4">
                                <div className="row ms-sm-1 me-sm-1">
                                    <div className="col">
                                        <strong className={`${st.status}`} >Statut actuel : </strong>
                                        {user && !user.isSuspended && !user.isDeleted && (user.timeout && (new Date(user.timeout) > new Date())) &&
                                            <span className='txtWarning'>Suspendu temporairement de {Math.ceil((new Date(user.timeout) - new Date()) / (1000 * 60 * 60 * 24))} jour(s).</span>
                                        }

                                        {user && !user.isSuspended && !user.isDeleted && !(user.timeout && (new Date(user.timeout) > new Date())) &&
                                            <span className='txtSuccess'>Actif</span>
                                        }

                                        {user && user.isSuspended && !user.isDeleted && !(user.timeout && (new Date(user.timeout) > new Date())) &&
                                            <span className='txtDanger'>Suspendu</span>
                                        }

                                        {user && !user.isSuspended && user.isDeleted && !(user.timeout && (new Date(user.timeout) > new Date())) &&
                                            <span className='txtWarning'>Supprimé</span>
                                        }
                                    </div>
                                </div>
                                <div className="row ms-sm-1 me-sm-1 mt-2">
                                    <div className="col-7">
                                        <strong>Choisir une action :</strong>
                                    </div>
                                    <div className="col-5 px-0">
                                        <div className={` ${st.selector}`} onMouseEnter={() => { setListDisplay(true) }} onMouseLeave={() => { setListDisplay(false) }}>
                                            <div className={`${st.selectField}`}>
                                                {
                                                    status === "suspend" ? <span>Suspendre</span> :
                                                        status === "timeout" ? <span>Timeout</span> :
                                                            status === "active" ? <span>Activer</span> :
                                                                <span>Selectionner</span>
                                                }
                                                <span className={`${listDisplay ? st.rotate : st.rotateBack}`} ><ExpandMoreIcon /></span>
                                            </div>
                                            <ul className={`${st.list} ${listDisplay ? st.visible : ''}`} >
                                                <li className={` ${st.options}`} onClick={() => { setStatus('active'); setTimeoutValue(null) }} >Activer</li>
                                                <li className={` ${st.options}`} onClick={() => { setStatus('suspend'); setTimeoutValue(null) }}>Suspendre</li>
                                                <li className={` ${st.options}`} onClick={() => { setStatus('timeout'); setTimeoutValue(null) }}>Timeout</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ms-sm-1 me-sm-1 mt-2">
                                    {status === "timeout" &&
                                        <>
                                            <div className="col">
                                                <div className="form-check">
                                                    <input onChange={handleRadioChange} className={`form-check-input ${st.radio}`} type="radio" name="timeout" id="oneMonth" />
                                                    <label className="form-check-label" htmlFor="oneMonth">
                                                        1 mois
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-check">
                                                    <input onChange={handleRadioChange} className={`form-check-input ${st.radio}`} type="radio" name="timeout" id="twoMonths" />
                                                    <label className="form-check-label" htmlFor="twoMonths">
                                                        2 mois
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-check">
                                                    <input onChange={handleRadioChange} className={`form-check-input ${st.radio}`} type="radio" name="timeout" id="sixMonths" />
                                                    <label className="form-check-label" htmlFor="sixMonths">
                                                        6 mois
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col mt-2 mt-sm-0">
                                                <div className="form-check">
                                                    <input onChange={handleRadioChange} className={`form-check-input ${st.radio}`} type="radio" name="timeout" id="other" />
                                                    <label className="form-check-label" htmlFor="other">
                                                        Autre
                                                    </label>
                                                </div>
                                            </div>
                                            {inputId === "other" &&
                                                <div className="col mt-2">
                                                    <label htmlFor="expiration">Date d'exipration<strong className='txtDanger'>*</strong></label>
                                                    <input type="date" id='expiration' className='form-control'
                                                        onChange={(e) => setTimeoutValue(e.target.value)} />
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                                <div className="row ms-sm-1 me-sm-1 mt-2 ">
                                    <div className="col">
                                        <div className={`form-check`}>
                                            <input className={`form-check-input ${st.checkBox}`} onChange={(e) => { setConfirm(e.target.checked) }} type="checkbox" id="confirm" />
                                            <label className="form-check-label" htmlFor="confirm">
                                                Confirmer l'action pour
                                                <strong> {user && user.lastname} {user && user.firstname} </strong>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ms-sm-1 me-sm-1 mt-2">
                                    <div className="col text-center mb-2">
                                        {err === "server" && <span className='txtDanger'>Un problem est survenue, réessayer plus tard</span>}
                                        {err === "required" && <span className='txtDanger'>Veuillez choisir une action !</span>}
                                    </div>
                                    
                                </div>
                                <div className="row mt-2" >
                                    <div className="col-sm mb-sm-0 mb-3 mt-2 text-center">
                                        {success && <span className='txtSuccess'>Sauvgardé &#10003;</span>}
                                    </div>
                                    <div className="col text-end">
                                        <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                        <button onClick={submit} className={`${st.save} ${confirm ? "" : st.disabledSave}`}>Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </>)
                    }
                </div>
            </div>
        </div>);
}

export default ManageUserAccount;