import { useState, useRef, useEffect } from "react";
import st from "./confirm-deactivate-account.module.css"
import { deleteUser } from "../../../services/userApi";
import { googleLogout } from "@react-oauth/google";
import { logout } from "../../../services/authApi";

function ConfirmDeactivateAccount(props) {
  const {reset} = props;
  const [googleAccount] = useState(localStorage.getItem('googleAccount') || sessionStorage.getItem('googleAccount'))
  const initialValues = {
    password : '',
    confirmPass : ''
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [err, setErr] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [loading,setLoading] = useState(false);
  const closeBtn = useRef(null); 

  useEffect(()=>{
    setFormValues({ password : '', confirmPass : ''})
    setErr(false)
    setLoading(false)
    setShowPass(false)
  },[reset])

  const handleChange = (event) => { 
    setErr(false)
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const deleteAccount = (event)=>{
    event.preventDefault();
    if (googleAccount === 'false') {
      if (!formValues.password ||!formValues.confirmPass) {
        setErr("req");
        return;
      }
      if (formValues.password !== formValues.confirmPass) {
        setErr("notMatch");
        return;
      }
    }
    

    setErr(false)
    setLoading(true)
    const pass = {
      password : formValues.password,
      googleAccount: googleAccount && googleAccount === 'true' ? true : false
    }
    
    deleteUser(pass,(cb)=>{
      if (cb.status === 200) {
        setTimeout(() => { setLoading(false);setErr("success");}, 1000);
        setTimeout(() => { 
          setErr(false);
          logout((cb) => {
            if (cb.status === 200) {
              localStorage.clear();
              sessionStorage.clear();
              googleLogout();
              window.location.href = "/login";
            } else {
              console.error(cb);
            }
          });
        }, 3000);
      }else if (cb.response.status === 401 ) {
        setTimeout(() => { setLoading(false);setErr('wrongPassword')}, 1000);
      }else setTimeout(() => { setLoading(false);setErr('server')}, 1000);
      
    })
  }

  return (
    <div
      className="modal fade"
      id="deleteUserModal"
      tabIndex="-1"
      aria-labelledby="delModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
            <div className="row relative">
                <div className="col">
                    <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.xBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                    <h4 className='mt-4 ms-4'>Désactiver votre compte</h4>
                </div>
            </div>
          <form>
            <div className="modal-body ps-5 pe-5 pb-0">
              {googleAccount === 'true' ? 
              <div>
                <div className="row">
                  <div className="col">
                  Etes-vous sûr de vouloir désactiver votre compte ?
                  </div>
                </div>
              </div> 
              :
                <div>
                    <div className="mb-3">
                    <label className="form-label small mb-1">Veuillez saisir votre mot de passe actuel<span className="txtDanger">*</span></label>
                    <input
                      className={`form-control ${(err === "req" && !formValues.password) || err === "wrongPassword" || err === "server" ? "is-invalid" : ""}`}
                      type={showPass ? "text" : "password"}
                      placeholder="Entrez votre mot de passe actuel"
                      onChange={handleChange}
                      name="password"
                      value={formValues.password}
                    />
                  </div>
                  <div className="mb-2">
                    <label className="form-label small mb-1">Confirmez le mot de passe<span className="txtDanger">*</span></label>
                    <input
                      className={`form-control ${(err === "req" && !formValues.confirmPass) || err === "notMatch" || err === "wrongPassword" || err === "server"? "is-invalid" : ""}`}
                      type={showPass ? "text" : "password"}
                      placeholder="Entrez votre mot de passe actuel"
                      onChange={handleChange}
                      name="confirmPass"
                      value={formValues.confirmPass}
                    />
                  </div>
                  <div className="row">
                      <div className="col txtSuccess">
                      {err === "success" && "le mot de passe a été modifié avec succès"}
                      </div>
                  </div>
                  <div className="row">
                    <div className="col txtDanger">
                      {err === "req" && "Tous les champs sont obligatoires !"}
                      {err === "notMatch" && "Les mots de passe ne correspondent pas !"}
                      {err === "wrongPassword" && "Mot de passe incorrect !"}
                      {err === "server" && "Un problème survenue, réessayer plus tard !"}
                    </div>
                  </div>
                  <div className={`form-check-reverse form-switch ${st.formCheck}`}>
                    <label className="form-check-label">Montrer les mots de passes</label>
                    <input className={`form-check-input ${st.formCheckInput}`} type="checkbox" checked={showPass} onChange={(e) => setShowPass(e.target.checked)} />
                  </div>
                </div>
              }
            </div>
            <div className="row p-3 pe-4">
              <div className="col d-flex justify-content-end">
                {loading && <span className={`${st.loader}`}></span>}
                <button type="button" className={`${st.closeBtn}`} data-bs-dismiss="modal" > Annuler</button>
                <button type="button" className={`${st.myBtn}`} onClick={deleteAccount}> Confirmer </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDeactivateAccount;
