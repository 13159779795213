import { useEffect, useState } from "react";
import st from "./estabMenu.module.css";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import AppNavbar from "../../components/navigation/app-navigation/app-navbar";


import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function EstabMenu(props) {
    const { selectedMenu, user, website } = props;
    const [search] = useState('')
    const [categories, setCategories] = useState([]);
    const [activeCat, setActiveCat] = useState(selectedMenu.categories[0]);

    const isAdmin = user.role === "admin";
    const isProvider = user.role === "provider";
    const isUser = user.role === "user";


    useEffect(() => {
        if (search === '') {
            setCategories(selectedMenu.categories && selectedMenu.categories)
        } else {
            const searching = selectedMenu.categories.filter(
                cat => cat.name.toLowerCase().includes(search.toLowerCase()))
            setCategories(searching)
            setActiveCat(searching.length > 0 ? searching[0] : null)
        }

    }, [setActiveCat, selectedMenu.categories, search])


    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };



    return (
        <>
            {!isAdmin && !isProvider && !isUser && <AppNavbar website={website} />}
            <div className={`${isProvider && "clientPage"} ${!isAdmin && !isProvider && st.page}`}
                style={{ scrollBehavior: "smooth" }}>
                <div className={`${st.body}`}>
                    <span className={`${st.swipeBtn}`} onClick={toggleDrawer("left", true)} > <ArrowForwardIosIcon /> Catégories</span>
                    <div className={st.respDrawer}>
                        <SwipeableDrawer
                            anchor={"left"}
                            open={state["left"]}
                            onClose={toggleDrawer("left", false)}
                            onOpen={toggleDrawer("left", true)}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                '& .MuiDrawer-paper': { width: 300, minWidth: 300 },
                                '& .MuiList-root': { width: 300, minWidth: 300 }
                            }}
                        >
                            <Box role="presentation" >
                                <List>
                                    <div className="position-relative pe-5">
                                        <h3 className={st.menuTitle}>{selectedMenu.name}</h3>
                                        <button className={`bi bi-x-lg ${st.closeBtn}`} onClick={toggleDrawer("left", false)}></button>
                                        <RestaurantMenuIcon className={`${st.caticon}`} />
                                    </div>
                                    <div className="mb-2">
                                        <span className={st.ourCats}>Nos catégories :</span>
                                    </div>
                                    {categories.map((cat, key) => (
                                        <ListItem key={key} disablePadding
                                            onClick={toggleDrawer("left", false)}
                                            onKeyDown={toggleDrawer("left", false)}
                                        >
                                            <ListItemButton className="ms-5">
                                                <ListItemText disableTypography
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            style={cat._id === activeCat._id ? { color: '#FFCC44' } : { color: 'black' }}
                                                            onClick={() => setActiveCat(cat)}
                                                        >
                                                            {cat.name}
                                                        </Typography>
                                                    } />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </SwipeableDrawer>
                    </div>
                    <div className={st.Drawer}>
                        <Drawer
                            variant="permanent"
                            sx={{
                                width: 200,
                                flexShrink: 0,
                                display: { xs: 'none', sm: 'block' },
                                [`& .MuiDrawer-paper`]: { width: 200, height : "100%", boxSizing: 'border-box', marginTop: isProvider ? "130px" : "80px", zIndex: 4, paddingBottom : "15px" },
                                [`& .MuiList-root`]: { width: 200, height : "100%", boxSizing: 'border-box', zIndex: 4 },
                            }}
                        >
                            <Box sx={{ overflow: 'none' }}>
                                <div className="position-relative ">
                                    <h3 className={`${st.menuTitle} mt-1`} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} >{selectedMenu.name}</h3>
                                    <RestaurantMenuIcon className={`${st.caticon} mt-2`} />
                                </div>
                                <List>
                                    <div className="mb-2">
                                        <span className={st.ourCats}>Nos catégories :</span>
                                    </div>
                                    {categories.map((cat, key) => (
                                        <ListItem key={key} disablePadding
                                            onClick={toggleDrawer("left", false)}
                                            onKeyDown={toggleDrawer("left", false)}
                                        >
                                            <ListItemButton>
                                                <ListItemText disableTypography
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            className="ms-3"
                                                            style={cat._id === activeCat._id ? { color: '#FFCC44' } : { color: 'black' }}
                                                            onClick={() => setActiveCat(cat)}
                                                        >
                                                            {cat.name}
                                                        </Typography>
                                                    } />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Drawer>
                    </div>
                    <div className={st.main}>
                        <div className="container-fluid">
                            <div>
                                <h3 className={`text-center mt-3 ${st.activeCatName}`}>{activeCat.name.toUpperCase()}</h3>
                            </div>
                            <div className={`row px-2 d-flex justify-content-center`} style={{ marginTop: '25px', minHeight: "346.1px " }} >
                                {activeCat.items && activeCat.items.map((item, j) => (
                                    <div key={j} className="px-0 mt-2 mb-2 ms-1 me-1" style={{ width: "18rem" }}>
                                        <img src={item.photo ? `${process.env.REACT_APP_SERVER_PUBLIC}${item.photo}` : "/images/dish.png"}
                                            className={`card-img-top ${st.cardImg}`}
                                            style={{ borderRadius: "15px 15px 0px 0px" }} alt="" />
                                        <div className="card-body px-0 py-0">
                                            <div className={`accordion`} id={`accordion${j}`}>
                                                <div className={`accordion-item ${st.Accordion}`}>
                                                    <h2 className="accordion-header position-relative px-2 pb-2">
                                                        <div className="row">
                                                            <span className={`col-8 pe-0 ${st.itemName}`}  style={{ height: "60px", overflowY : "auto", overflowX : 'hidden' }}  >{item.name}</span>
                                                            <span className={`col-4 text-end pe-2 ${st.itemPrice}`}>{item.price} DT</span>
                                                        </div>
                                                        <button className={`bi bi-caret-down-fill ${st.openAccord}`} data-bs-toggle="collapse" data-bs-target={`#accordionPanel${j}`} aria-expanded="true" aria-controls="panelsStayOpen-collapseOne"></button>
                                                    </h2>
                                                    <div id={`accordionPanel${j}`} className="accordion-collapse collapse">
                                                        <div className="accordion-body pt-0">
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default EstabMenu;

