import { useRef, useState } from "react";
import { addEstablishmentLogo } from "../../../../services/establishmentApi";
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import st from './logo.module.css';

function Logo(props) {
    const { establishment } = props;
    const establishmentId = establishment ? establishment._id : "";

    const [logo, setLogo] = useState();
    const [logoErr, setLogoErr] = useState(false);
    const [logoLoading, setLogoLoading] = useState(false);
    const [logoSuccess, setLogoSuccess] = useState(false);

    const imgView = useRef(null);
    const inputFile = useRef(null);

    const logoUpImg = () => {
        setLogoErr(false);
        setLogoLoading(false);
        if (inputFile.current.files.length > 0) {
            const imgLink = URL.createObjectURL(inputFile.current.files[0]);
            imgView.current.src = imgLink;
            setLogo(inputFile.current.files[0]);
        }
    }

    const logoDrop = (e) => {
        e.preventDefault();
        setLogoLoading(false);
        setLogoErr(false);
        inputFile.current.files = e.dataTransfer.files;
        logoUpImg();
    }

    const submitLogo = () => {
        const formData = new FormData();
        if (logo instanceof File) {
            formData.append("logo", logo);
        } else {
            if (establishment.logo) {
                setLogoSuccess(true);
                setTimeout(() => {
                    setLogoSuccess(false);
                }, 3000);
                return;
            } else {
                setLogoErr('empty');
                return;
            }
        }
        if (!logoErr) {
            setLogoLoading(true);
            addEstablishmentLogo(establishmentId, formData, (cb) => {
                if (cb.status === 200) {
                    setTimeout(() => {
                        setLogoLoading(false);
                    }, 500);
                    setTimeout(() => {
                        setLogoSuccess(true);
                    }, 500);
                    setTimeout(() => {
                        setLogoSuccess(false);
                    }, 3000);
                } else {
                    setLogoLoading(false);
                }
            })
        }
    }

    return (
        <div className={`card mt-1 mb-3 ps-3 pe-3 ${st.mediaCard}`}
            onDragOver={(e) => e.preventDefault()}
            onDrop={logoDrop}>
            <div className="row relative text-center mt-4">
                <div className="col">
                    <h5>Logo</h5>
                    <Tooltip title="Faites glisser et déposez ou cliquez sur l'image pour télécharger votre logo" arrow>
                        <ErrorOutlineIcon className={`${st.information}`} />
                    </Tooltip>
                </div>
            </div>
            <div className="row pt-2 pb-3">
                <div className="text-center p-0">
                    <input ref={inputFile} type="file" accept="image/*" onChange={logoUpImg} hidden />
                    <img ref={imgView} id="img-view" alt="avatar" className={`object-fit-cover ${st.img}`}
                        src={establishment && establishment.logo ?
                            `${process.env.REACT_APP_SERVER_PUBLIC}${establishment.logo}`
                            : "/images/uploadLogo.png"}
                        onClick={() => inputFile.current.click()}
                    />
                </div>
            </div>
            {logoErr === "empty" && (
                <div className="row text-center mb-2">
                    <span className='txtDanger'>Veuillez insérer une photo</span>
                </div>
            )}
            {logoSuccess && (
                <div className="row text-center mb-2">
                    <span className='txtSuccess'>Enregistré avec succès</span>
                </div>
            )}

            {logoLoading && (
                <div className="row">
                    <div className="d-flex justify-content-center mb-3">
                        <div className={`${st.loading}`}></div>
                    </div>
                </div>)}

            <div className="row mb-5 text-center">
                <div className="col">
                    <button onClick={submitLogo} className={`${st.saveLogo}`} >Enregistrer</button>
                </div>
            </div>
        </div>
    );
}

export default Logo;