import { useParams } from "react-router-dom";
import st from "./editEstablishment.module.css"
import { getEstablishmentById, updateEstablishment } from "../../../services/establishmentApi";
import { useEffect, useMemo, useRef, useState } from "react";
import Footer from "../../../components/footer/footer";
import General from "../../../components/editEstablishment/general/general";
import Media from "../../../components/editEstablishment/media/media";
import Highlights from "../../../components/editEstablishment/highlights/highlights";
import Socials from "../../../components/editEstablishment/socials/socials";
import OpenHours from "../../../components/editEstablishment/openHours/openHours";

function EditEstablishment(props) {
    const { website, setRefresh, role } = props;

    //get establishment to edit logic
    let { id } = useParams();
    const [establishment, setEstablishment] = useState();
    const oldCustomUrl = useRef(null);
    useEffect(() => {
        getEstablishmentById(id, (cb) => {
            if (cb.status === 200) {
                setEstablishment(cb.data.establishment);
                oldCustomUrl.current = cb.data.establishment.customUrl;
            } else {
                window.location.href = "/";
            }
        })
    }, [id])

    //components and their traject logic
    const [component, setComponent] = useState(0);
    const previousComponent = useRef("general");
    const components = useMemo(() => ['general', 'highlights', 'socials', 'opening', 'maps', 'media'], []);
    useEffect(() => {
        const previousElement = document.getElementById(previousComponent.current);
        previousElement && (previousElement.style.display = "none");

        const element = document.getElementById(components[component]);
        element && (element.style.display = "block");

        previousComponent.current = components[component];

    }, [component, components]);

    //components states 
    const [general, setGeneral] = useState({
        location: "",
        phone: "",
        name: "",
        type: [],
        description: "",
        customUrl: ""
    });

    const [highlights, setHighlights] = useState([]);
    const [openings, setOpenings] = useState([]);
    const [socials, setSocials] = useState([]);

    const [saved, setSaved] = useState(false);

    const [err, setErr] = useState(false);
    const [feedback, setFeedBack] = useState(false);
    const [loading, setLoading] = useState(false);

    const verifyGeneral = () => {
        let g = general;
        if (g.name === "" || g.phone === "" || g.location === "" || g.type.length < 1) {
            setErr(true);
            setFeedBack("Remplir les champs sont obligatoires * !");
            return false;
        }
        if (g.customUrl === "") {
            g.customUrl = Date.now();
            oldCustomUrl.current = g.customUrl;
        } else {
            var customIdPattern = /^[a-zA-Z0-9_-]+$/;
            if (!customIdPattern.test(g.customUrl)) {
                setErr(true);
                setFeedBack("Le format de l'identifiant est invalide !");
                return false;
            }
        }
        let charactersPattern = /.{3,15}/;
        let phonePattern = /[-+ ]?\d{5,}/;
        if (!charactersPattern.test(g.name)) {
            setErr(true);
            setFeedBack("Le format de nom d'établissement est invalide");
            return false;
        } else if (!phonePattern.test(g.phone)) {
            setErr(true);
            setFeedBack("Le téléphone doit être minimum de 5 chiffres");
            return false;
        } else if (!charactersPattern.test(g.location)) {
            setErr(true);
            setFeedBack("Le format d'adresse d'établissement est invalide");
            return false;
        } else {
            setGeneral(g)
            setErr(false);
            setFeedBack(false);
            return true;
        }
    }

    const next = () => {
        console.log(highlights);
        if (components[component] === 'general') {
            if (verifyGeneral()) {
                if (component < 5 && !err) {
                    setComponent(component + 1);
                }
            } else {
                setErr(true);
            }
        } else if (component < 5) {
            setComponent(component + 1);
        }
    }
    const getBack = () => {
        if (component > 0) {
            setComponent(component - 1);
        }
    }
    const submit = () => {
        let g = general;
        if (verifyGeneral()) {
            setLoading(true);
            setErr(false);
            setFeedBack(false);
            setSaved(false)
            const data = {
                location: g.location,
                phone: g.phone,
                name: g.name,
                type: g.type,
                description: g.description,
                customUrl: g.customUrl,
                highlights: highlights,
                socials: socials,
                workingtime: openings
            }
            updateEstablishment(establishment._id, data, (cb) => {
                if (cb.status === 200) {
                    setTimeout(() => {
                        setRefresh(r => !r)
                        setLoading(false);
                        setErr(false);
                    }, 500);
                    setTimeout(() => {
                        setSaved(true)
                    }, 500);
                    setTimeout(() => {
                        setSaved(false)
                    }, 2000);
                }
            })
        }
    }


    return (
        <div className={role === "provider" ? "clientPage" : "adminPage"}>
            {establishment ?
                (
                    <div className="container-lg" style={{ minHeight: "34.5dvh" }}>
                        <div className="row">
                            <div className="col-md-8 mt-4 ">
                                <h4 className='ms-4 me-4'>Modifier votre établissement</h4>
                            </div>
                        </div>
                        <hr className='mb-0 ' />
                        <div className="row ">
                            <div className="col ms-4 me-4 mt-3 pb-3 pb-sm-0 d-flex justify-content-start overflow-x-auto">
                                <span
                                    onClick={() => setComponent(0)}
                                    className={`${st.enabledTab} ${components[component] === "general" && "active"} ${st.tabs} ${st.enabledTab}`}>Général</span>
                                <span
                                    onClick={() => setComponent(1)}
                                    className={` ${st.enabledTab} ${components[component] === "highlights" && "active"}  ${st.tabs} `}>Commodités</span>
                                <span
                                    onClick={() => setComponent(2)}
                                    className={` ${st.enabledTab} ${components[component] === "socials" && "active"}  ${st.tabs} `}>Réseau sociaux</span>
                                <span
                                    onClick={() => setComponent(3)}
                                    className={` ${st.enabledTab} ${components[component] === "opening" && "active"}  ${st.tabs} `}>Horaires</span>
                                <span
                                    onClick={() => setComponent(4)}
                                    className={` ${st.enabledTab} ${components[component] === "maps" && "active"}  ${st.tabs} `}>Maps</span>
                                <span
                                    onClick={() => setComponent(5)}
                                    className={`${st.enabledTab} ${components[component] === "media" && "active"} ${st.tabs}`}>Média</span>
                            </div>
                        </div>
                        <hr className='mt-3' />
                        <div className="row">
                            <div className="col mt-3 mb-2">
                                <div id='general' style={{ display: "block" }}>
                                    <General establishment={establishment} formValues={general} setFormValues={setGeneral}
                                        err={err} setErr={setErr} oldUrl={oldCustomUrl.current} />
                                </div>
                                <div id='media' style={{ display: "none" }}>
                                    <Media establishment={establishment} />
                                </div>
                                <div id='highlights' style={{ display: "none" }}>
                                    <Highlights establishment={establishment} highlights={highlights} setHighlights={setHighlights} />
                                </div>
                                <div id='socials' style={{ display: "none" }}>
                                    <Socials oldSocials={establishment.socials} setSocials={setSocials} />
                                </div>
                                <div id='opening' style={{ display: "none" }}>
                                    <OpenHours oldOpenings={establishment.workingtime} setOpenings={setOpenings} />
                                </div>
                                <div className='text-center mt-2 pe-1 ps-1'>
                                    {err && components[component] === "general" && <span className='txtDanger'> {feedback} </span>}
                                    {saved && <span className='txtSuccess'>L'établissement a été modifié avec succès </span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {loading && (
                                <div className="d-flex justify-content-center mt-2 mb-2">
                                    <div className={`${st.loading}`}></div>
                                </div>)}
                        </div>
                        <hr className='mb-0' />
                        <div className="row">
                            <div className="col-lg pe-lg-4 p-0 mt-4 text-lg-end text-center">
                                {components[component] === "general" ?
                                    role === "provider" ?
                                        <button onClick={() => window.location.href = '/account/establishments'} className={`${st.close}`}>Annuler</button> :
                                        <button onClick={() => window.location.href = '/admin'} className={`${st.close}`}>Annuler</button> :
                                    <button onClick={getBack} className={`${st.close}`}>Retour</button>
                                }
                                <button onClick={submit} className={`${st.save} mb-2`}>Enregistrer</button>
                                {component !== components.length - 1 &&
                                    <button onClick={next} className={`${st.save} ms-3`}>Suivant</button>
                                }
                            </div>
                        </div>
                    </div>
                )
                :
                (
                    <div className="text-center mt-5" style={{ minHeight: "34.5dvh" }} >{setInterval(() => {
                        "Un problem est produit"
                    }, 2000)}</div>
                )}

            <Footer website={website} />
        </div>
    );
}

export default EditEstablishment;