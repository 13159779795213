import { useEffect, useState, useRef } from 'react';
import ResponsiveCard from './cards/responsiveCard/responsiveCard';
import st from './establishments.module.css';
import AsyncSelect from 'react-select/async'
import makeAnimated from 'react-select/animated';
import NormalCard from './cards/normalCard/normalCard';
import { getEstablishmentHighlights } from '../../../services/website';
import { getEstablishmentsLocations, getEstablishmentsNames } from '../../../services/establishmentApi';

function Establishments(props) {
    const { establishments, menus } = props;
    const cardDisplayRef = useRef(null);

    const [nameOptions, setNameOptions] = useState([]);
    const [nameLoad, setNameLoad] = useState(true);
    const [selectedName, setSelectedName] = useState(null);

    const [locationOptions, setLocationOptions] = useState([]);
    const [locationLoad, setlocationLoad] = useState(true);
    const [selectedLocation, setSelectedLocation] = useState(null);

    const [highlightOptions, sethighlightOptions] = useState([]);
    const [highlightLoad, sethighlightLoad] = useState(true);
    const [selectedHighlight, setSelectedHighlight] = useState(null);

    const [filteredEstablishments, setFilteredEstablishments] = useState([]);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth]);

    useEffect(() => {
        getEstablishmentsNames((cb) => {
            setNameLoad(false);
            if (cb.status === 200) {
                setNameOptions(cb.data.names.map(names => ({ value: names, label: names })))
            }
        })

        getEstablishmentsLocations((cb) => {
            setlocationLoad(false);
            if (cb.status === 200) {
                setLocationOptions(cb.data.locations.map(location => ({ value: location, label: location })))
            }
        })

        getEstablishmentHighlights((cb) => {
            sethighlightLoad(false);
            if (cb.status === 200) {
                sethighlightOptions(cb.data.highlights.map(highlight => ({ value: highlight.name, label: highlight.name })))
            }
        })
    }, [])

    useEffect(() => {
        let filtered = []
        if (establishments) {
            filtered = establishments.filter(estab => {
                if (selectedName && estab.name !== selectedName.value) {
                    return false;
                }
                if (selectedLocation && estab.location !== selectedLocation.value) {
                    return false;
                }
                if (selectedHighlight && selectedHighlight.length > 0) {
                    const establishmentHighlights = estab.highlights.map(highlight => highlight.name);
                    if (!selectedHighlight.every(highlight => establishmentHighlights.includes(highlight.value))) {
                        return false;
                    }
                }
                return true;
            });
        }

        setFilteredEstablishments(filtered);
    }, [establishments, selectedName, selectedLocation, selectedHighlight]);

    const handelChange = (option, selectType) => {
        if (selectType === 'highlight') {
            setSelectedHighlight(option);
        } else if (selectType === 'name') {
            setSelectedName(option);
        } else if (selectType === 'location') {
            setSelectedLocation(option);
        }
    }


    const loadHighlightOptions = (searchValue, cb) => {
        const filtered = highlightOptions
            .filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase()))
            .sort((a, b) => a.label.localeCompare(b.label));
        cb(filtered);
    }

    const loadLocationOptions = (searchValue, cb) => {
        const filtered = locationOptions
            .filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase()))
            .sort((a, b) => a.label.localeCompare(b.label));
        cb(filtered);
    }

    const loadNamesOptions = (searchValue, cb) => {
        const filtered = nameOptions
            .filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase()))
            .sort((a, b) => a.label.localeCompare(b.label));
        cb(filtered);
    }


    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: "white" }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#f7c437a1" : 'white',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? '#f7c437d0'
                            : '#f7c437d0'
                        : undefined,
                },
            }
        },
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: "#f7c437a1"
            }
        },
        multiValueRemove: (styles) => {
            return {
                ...styles,
                color: "black",
                cursor: 'pointer', backgroundColor: '#ffcc44',
                ':hover': { color: 'black' }
            }
        }
    }
    const animatedComponents = makeAnimated();
    return (
        <div className={`container-sm py-0 px-2 ${st.container}`}>
            <div className={st.title} >
                <h1>Découvrez votre restaurant préféré</h1>
            </div>
            <div className={`${st.searchBox}`}>
                <div className="row">
                    <div className="col">
                        <span className={`${st.recherche}  ms-3`}>Rechercher</span>
                    </div>
                </div>
                <div className="row pb-2">
                    <div className="col-md-4">
                        <span className={`ps-2 ${st.label}`}>Nom</span>
                        {!nameLoad &&
                            <div className="pt-1">
                                <AsyncSelect
                                    loadOptions={loadNamesOptions}
                                    components={animatedComponents}
                                    isClearable
                                    defaultOptions
                                    onChange={(option) => handelChange(option, 'name')}
                                    styles={colorStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#f7c437a1',
                                            primary: 'black',
                                        },
                                    })}
                                    placeholder="Nom"
                                />
                            </div>
                        }
                    </div>
                    <div className="col-md-4">
                        <span className={`ps-2 ${st.label}`}>Adresse</span>
                        {!locationLoad &&
                            <div className="pt-1">
                                <AsyncSelect
                                    loadOptions={loadLocationOptions}
                                    components={animatedComponents}
                                    isClearable
                                    defaultOptions
                                    onChange={(option) => handelChange(option, 'location')}
                                    styles={colorStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#f7c437a1',
                                            primary: 'black',
                                        },
                                    })}
                                    placeholder="Adresse"
                                />
                            </div>
                        }
                    </div>
                    <div className="col-md-4">
                        <span className={`ps-2 ${st.label}`}>Commodités</span>
                        {!highlightLoad &&
                            <div className="pt-1">
                                <AsyncSelect
                                    loadOptions={loadHighlightOptions}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    defaultOptions
                                    isMulti
                                    onChange={(option) => handelChange(option, 'highlight')}
                                    styles={colorStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#f7c437a1',
                                            primary: 'black',
                                        },
                                    })}
                                    placeholder="Commodités"
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <>
                {screenWidth > 768 &&
                    <div ref={cardDisplayRef} className={`mt-3 pt-4 row d-flex justify-content-center ${st.cardContainer}`}>
                        {filteredEstablishments && filteredEstablishments.length > 0 && filteredEstablishments.map((estab, i) => (
                            <span key={i} className={`${st.cardCol} my-2`}>
                                <NormalCard establishment={estab} menus={menus} />
                            </span>
                        ))}
                    </div>}
                {screenWidth < 768 && <div ref={cardDisplayRef} className={`mt-3 pt-4 row d-flex justify-content-center ${st.cardContainer}`}>
                    {filteredEstablishments && filteredEstablishments.length > 0 && filteredEstablishments.map((estab, i) => (
                        <span key={i} className={`${st.cardColResp} my-2 px-1`}>
                            <ResponsiveCard establishment={estab} menus={menus} />
                        </span>
                    ))}
                </div>}
            </>

        </div>
    );
}

export default Establishments;