import st from "./blog.module.css";
import AppNavbar from '../../components/navigation/app-navigation/app-navbar';
import Footer from '../../components/footer/footer';

function Blog(props) {
    const { user, website } = props;
    const isAdmin = user.role === "admin";
    const isProvider = user.role === "provider";
    const isUser = user.role === "user";

    return (
        <>
            {!isAdmin && !isProvider && !isUser && <AppNavbar website={website} />}
            <div className={`${isProvider && "clientPage"} ${!isAdmin && !isProvider && st.page}`}>
                <div className={`${st.body}`}>
                    <div style={{ backgroundColor: "#2A2A2A" }} className="position-relative">
                        <img src="/images/banner.jpg" alt=""
                            className={`${st.pageTitleImage} object-fit-cover`} />
                        <div className={`${st.pageTitleBar} container-lg position-relative`}>
                            <span className={`${st.pageTitle}`}>Blog</span>
                        </div>
                    </div>
                </div>
                <Footer website={website} />
            </div>
        </>
    );
}

export default Blog;