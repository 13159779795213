import axios from 'axios'
axios.defaults.withCredentials = true;

//submit contact
export const submitContact = (data, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/contact`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//submit contact
export const getContacts = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/contact`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//delete contacts
export const deleteContacts = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/contact`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}