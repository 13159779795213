import { useEffect, useRef, useState } from "react";
import st from "./addMenu.module.css"
import { useParams } from "react-router-dom";
import { addMenu } from "../../../../services/menuApi";
function AddMenu(props) {
    const { setRefresh, reset } = props;
    const establishmentId = useParams().id;
    const closeBtn = useRef(null);
    const saveBtn = useRef(null);

    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setName('');
        setErr(false);
        setSuccess(false);
    }, [reset])

    const handleNameChange = (e) => {
        setErr(false);
        setSuccess(false);
        setName(e.target.value);
    }

    const createMenu = () => {
        setErr(false);
        setLoading(true);
        if (name === "") {
            setTimeout(() => {
                setLoading(false);
                setErr('req');
            }, 500);
            return;
        }
        addMenu({ establishment: establishmentId, name: name.charAt(0).toUpperCase() + name.slice(1) }, (cb) => {
            if (cb.status === 201) {
                setRefresh(pvRefresh => !pvRefresh);
                setTimeout(() => {
                    setLoading(false);
                    setSuccess(true);
                    setName('');
                }, 500);
                setTimeout(() => {
                    setSuccess(false);
                    closeBtn.current.click();
                }, 1000);
            } else {
                setTimeout(() => {
                    setLoading(false);
                    setErr("server");
                }, 500);
                console.error(cb);
            }

        })
    }

    return (
        <div className={`modal fade`} id="addMenuModal" tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-md`}>
                <div className={`modal-content ${st.modalContent}`}>
                    {loading ? (<span className={`mb-5 mt-4 ${st.loaderBox}`}><span className={`${st.loader}`}></span></span>) : (<>
                        <div className="row relative">
                            <div className="col">
                                <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                                <h4 className='mt-4 ms-4'>Ajouter un menu</h4>
                            </div>
                        </div>
                        <div className="row px-4 py-2 pb-0 mb-2">
                            <div className="col">
                                <div className="form-group">
                                    <label className="from-label">Nom<span className="txtDanger">*</span> </label>
                                    <input className="form-control mt-2" type="text" placeholder="Nom de menu"
                                        onKeyUp={(e) => e.key === 'Enter' && saveBtn.current.click()}
                                        value={name} onChange={handleNameChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 mb-2">
                            <div className="col text-center">
                                {err === "req" && <span className="txtDanger">Le nom est un champ obligatoire !</span>}
                                {err === "server" && <span className="txtDanger">Un problem est survenu, réessayer plus tard !</span>}
                                {success && <span className="txtSuccess">Enregistrer avec succès</span>}
                            </div>
                        </div>
                        <div className="row mt-2 mb-3 pe-md-3" >
                            <div className="col text-md-end text-center">
                                <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                <button ref={saveBtn} onClick={createMenu} className={`${st.save}`}>Enregistrer</button>
                            </div>
                        </div>
                    </>)}

                </div>
            </div>
        </div>
    );
}

export default AddMenu;