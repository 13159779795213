import { NavLink, Link } from "react-router-dom";
import st from "./app-navbar.module.css"
import "../navigation.css"
import { useEffect, useState } from "react";
import { googleLogout } from '@react-oauth/google';
import { logout } from "../../../services/authApi";
import ProviderRequest from "../../modals/provider/providerRequest";

function AppNavbar(props) {
  const { website, user } = props
  const username = localStorage.getItem("username") || sessionStorage.getItem("username");


  const [isHovered, setHovered] = useState(false);
  const [isUserStuffsOpen, setUserStuffsOpen] = useState(false);
  const [avatar, setAvatar] = useState("/images/userdefault.png");
  const role = user && user.role ? user.role : "";


  const [err, setErr] = useState(false);
  const [resetValues, setResetValues] = useState(false);

  const toggleUserStuffs = () => {
    setUserStuffsOpen(!isUserStuffsOpen);
  };
  useEffect(() => {
    let img
    if (user && user.avatar) {
      img = user.avatar;
      if (img.includes("http")) {
        setAvatar(img);
      } else {
        setAvatar(process.env.REACT_APP_SERVER_PUBLIC + "" + img);
      }
    }
  }, [user]);
  useEffect(() => {
    const userStuffs = document.getElementById("userStuffs");
    const userArrow = document.getElementById("userArrow");
    if (isUserStuffsOpen && userStuffs && userArrow) {
      !role ? userStuffs.style.height = "65px" : role === "provider" ? userStuffs.style.height = "160px" : userStuffs.style.height = "95px";


      userArrow.style.transform = "rotate(180deg)";
    } else if (userStuffs && userArrow) {
      userStuffs.style.height = "0px";
      userArrow.style.transform = "rotate(0deg)";
    }
  }, [isUserStuffsOpen, role]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const signout = () => {
    logout((cb) => {
      if (cb.status === 200) {
        localStorage.clear();
        sessionStorage.clear();
        googleLogout();
        window.location.href = "/login";
      } else {
        console.error(cb);
      }
    })
  }


  return (

    <>
      <ProviderRequest user={user} err={err} setErr={setErr} resetValues={resetValues} />

      <nav className={`navbar navbar-expand-lg fixed-top ${st.normalNav}`}>
        <div className={`container-lg ${st.navbar}`}>
          <NavLink className={`navbar-brand`} to="/">
            <img fetchpriority="high" src={website && website.logo ? `${process.env.REACT_APP_SERVER_PUBLIC + "" + website.logo}` : "/images/logo-icon.png"}
              alt="logo" className={`${st.logo}`} />
          </NavLink>
          <Link to="/" className={`${st.title}`}>
            <strong>{website && website.name} </strong>
            {role === "provider" && (<span> | Prestataire</span>)}
            {role === "admin" && (<span>| Administration</span>)}
          </Link>

          <div className="collapse navbar-collapse">
            <ul className={`navbar-nav me-auto mb-2 mb-lg-0`}></ul>
            <ul className={`navbar-nav mb-2 mb-lg-0 d-flex justify-content-end ${st.heads}`}>
              {role !== "admin" && <>
                <li className="nav-item">
                  <NavLink to="/" className={`${st.navlinks} nav-link`}>Accueil</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/about-us" className={`${st.navlinks} nav-link`}>À propos</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact" className={`${st.navlinks} nav-link`}>Contact</NavLink>
                </li>
              </>}

              {!username && <li className="nav-item">
                <Link style={{ marginLeft: "13px" }}
                  onClick={() => { window.location.href = '/login' }} className={`${st.navlinks} nav-link`}>Connexion</Link>
              </li>}
              {!username && <li className="nav-item">
                <Link
                  onClick={() => { window.location.href = '/signup' }} className={`${st.navlinks} nav-link`}>S'inscrire</Link>
              </li>}
            </ul>
            <ul className={`navbar-nav mb-2 mb-lg-0`}>
              {role === "admin" &&
                <li className={`nav-item text-center ${st.backOffice}`}>
                  <span onClick={() => { window.location.href = "/admin"; sessionStorage.removeItem('component') }} className={`${st.backOfficeLink} ${st.navlinks} nav-link`}>
                    Back Office
                  </span>

                </li>}
            </ul>
            <ul className={`navbar-nav mb-2 mb-lg-0`}>
              <li className="nav-item">
                {username && user && user.firstname ? <div className={st.navItem} onMouseEnter={handleMouseEnter}>
                  <Link className={`nav-link ${st.dropdown} mt-1`}>
                    <img src={avatar} className={`${st.avatar}`} alt="" />
                    {user.firstname}
                  </Link>
                  {isHovered && (
                    <ul className={`dropdown-menu ${st.dropdownMenu}`}>

                      <li><NavLink className={`${st.dropdownLinks}`} to="/account/profile">Profil</NavLink></li>
                      <li><NavLink className={`${st.dropdownLinks}`} to="/account/security">Securité</NavLink></li>
                      {role === "provider" &&
                        <>
                          <li><NavLink className={`${st.dropdownLinks}`} to="/account/establishments">Établissements</NavLink></li>
                          <li><NavLink className={`${st.dropdownLinks}`} to="/account/dashboard">Tableau de bord</NavLink></li>
                        </>
                      }
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li><Link onClick={signout} className={`${st.dropdownLinks}`}>Déconnexion <i className="bi bi-box-arrow-right"></i></Link></li>
                    </ul>
                  )}
                </div> : ""}
              </li>
            </ul>
            {(role === "user" || !role) &&
              <button onClick={() => { setErr(false); setResetValues(!resetValues) }}
                data-bs-toggle="modal" data-bs-target="#providerRequest" className={`${st.prestataire} mt-1`}>
                Devenir prestataire
              </button>}
          </div>
        </div>
      </nav>
      <nav className={`navbar fixed-top ${st.responsiveNav}`}>
        <div className={`container-lg`}>
          <NavLink className="navbar-brand" to="/">
            <img fetchpriority="high" src={website && website.logo ? `${process.env.REACT_APP_SERVER_PUBLIC + "" + website.logo}` : "/images/logo-icon.png"}
              alt="logo" className={`${st.logo}`} />
          </NavLink>
          {role === "admin" &&
            <span className={`nav-item text-center`}>
              <span onClick={() => { window.location.href = "/admin"; sessionStorage.removeItem('component') }} className={`${st.backOfficeResp}`}>
                Back Office
              </span>

            </span>}
          <button className={`${st.navbarToggler}`} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span className="bi bi-list"></span>
          </button>
          <div className={`offcanvas offcanvas-start ${st.offcanvas}`} tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="row" style={{ width: "100%", marginLeft: "0px" }}>
              <div className="col-9 mb-4">
                {username ?
                  <div>
                    <Link className={`nav-link ${st.dropdown}`} id="openUserStuffs" onClick={toggleUserStuffs}>
                      <div className="row no-gutters">
                        <div className="col-9">
                          <div className="row" style={{ marginLeft: "5px" }}>
                            <div className="col-4 mt-3 px-0"><img className={st.respAvatar} src={avatar} alt="" /></div>
                            <div className="col-8 mt-4 px-0">{username}</div>
                          </div>
                        </div>
                        <div className="col-3 mt-4 px-0 text-end">
                          <div id="userArrow" className={`text-center ${st.userArrow}`}>
                            <i className={`bi bi-chevron-down`}></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div id="userStuffs" className={`${st.userStuffs}`}>
                      <ul style={{ listStyleType: "none", marginTop: "5px" }}>

                        <li><NavLink className={`${st.respDropdown}`} to="/account/profile">Mon Profil</NavLink></li>
                        <li><NavLink className={`${st.respDropdown}`} to="/account/security">Securité</NavLink></li>
                        {role === "provider" &&
                          <>
                            <li><NavLink className={`${st.respDropdown}`} to="/account/establishments">Établissements</NavLink></li>
                            <li><NavLink className={`${st.respDropdown}`} to="/account/dashboard">Tableau de bord</NavLink></li>
                          </>
                        }
                        <li>
                          <Link onClick={signout} className={`${st.respDropdown}`}>Déconnexion <i className="bi bi-box-arrow-right"></i></Link>
                        </li>
                      </ul>
                    </div>
                  </div> :

                  <div>
                    <Link className={`nav-link ${st.dropdown}`} id="openUserStuffs" onClick={toggleUserStuffs}>
                      <div className="row no-gutters">
                        <div className="col-9">
                          <div className="row" style={{ marginLeft: "5px" }}>
                            <div className="col-4 mt-3 px-0"><img  fetchpriority="high" src={website && website.logo ? `${process.env.REACT_APP_SERVER_PUBLIC + "" + website.logo}` : "/images/logo-icon.png"} className={st.respWebsiteAvatar} alt="" /></div>
                            <div className="col-8 mt-4 px-0">{website && website.name}</div>
                          </div>
                        </div>
                        <div className="col-3 mt-4 px-0 text-end">
                          <div id="userArrow" className={`text-center ${st.userArrow}`}>
                            <i className={`bi bi-chevron-down`}></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div id="userStuffs" className={`${st.authStuffs}`}>
                      <ul style={{ listStyleType: "none", marginTop: "5px" }}>
                        <li>
                          <Link onClick={() => { window.location.href = '/login' }} className={`${st.respDropdown}`}>Connexion <i className="bi bi-box-arrow-right"></i> </Link>
                        </li>
                        <li>
                          <Link onClick={() => { window.location.href = '/signup' }} className={`${st.respDropdown}`}>S'inscrire <i className="bi bi-person-add"></i> </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                }

              </div>
              <div className="col-3 px-0 py-0 text-end">
                <button type="button" className="btn-close m-4" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
            </div>
            <div style={{ borderBottom: "0.5px solid gray", margin: "0px 10px" }}></div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">

                {(role === "user" || !role) && <button onClick={() => { setErr(false); setResetValues(!resetValues) }} data-bs-toggle="modal" data-bs-target="#providerRequest" style={{ marginBottom: "15px" }} className={`${st.prestataire}`}>Devenir prestataire</button>}
                {role !== "admin" && <>
                  <li className="nav-item">
                    <NavLink to="/" className={`${st.respLinks} nav-link`} href="#">Accueil</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/about-us" className={`${st.respLinks} nav-link`} href="#">À propos</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/contact" className={`${st.respLinks} nav-link`} href="#">Contact</NavLink>
                  </li></>}


              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default AppNavbar;