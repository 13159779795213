import React, { useState } from 'react';

import st from "./admin-sidebar.module.css"
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';

import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import MailIcon from '@mui/icons-material/Mail';

function AdminSidebar(props) {

    const { collapsed, toggled, setToggled, setComponent, website, setRefreshComponents, notifications } = props;
    const [hasImage] = useState(false);
    const [theme] = useState('light');

    const themes = {
        light: {
            sidebar: {
                backgroundColor: '#ffffff',
                color: '#607489',
            },
            menu: {
                menuContent: '#fbfcfd',
                icon: '#ffcc44',
                hover: {
                    backgroundColor: '#000000',
                    color: '#ffffff',
                },
                disabled: {
                    color: '#9fb6cf',
                },
            },
        },
        dark: {
            sidebar: {
                backgroundColor: '#0b2948',
                color: '#8ba1b7',
            },
            menu: {
                menuContent: '#082440',
                icon: '#59d0ff',
                hover: {
                    backgroundColor: '#00458b',
                    color: '#b6c8d9',
                },
                disabled: {
                    color: '#3e5e7e',
                },
            },
        },
    };
    const hexToRgba = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };
    const menuItemStyles = {
        root: {
            fontSize: '13px',
            fontWeight: 400,
        },
        icon: {
            color: themes[theme].menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: '#b6b7b9',
        },
        subMenuContent: ({ level }) => ({
            backgroundColor:
                level === 0
                    ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
                    : 'transparent',
        }),
        button: {
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
                color: themes[theme].menu.hover.color,
            },
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    };
    return (

        <div className={`${st.mainContainer}`}>
            <Sidebar
                width="250px"
                className={`${st.sidebar}`}
                collapsed={collapsed}
                toggled={toggled}
                onBackdropClick={() => setToggled(false)}
                breakPoint="md"
                backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}

            >
                <div className={`${st.head} row`} >
                    <div className={`col-4 ps-4 ${st.logoContainer}`}>
                        <img className={`${st.logo}`} alt='logo'
                            src={website && website.logo ? `${process.env.REACT_APP_SERVER_PUBLIC + "" + website.logo}` : "/images/logo-icon.png"} /></div>
                    <div className={`${st.title} col-8 px-0`}><strong>{website && website.name}</strong></div>
                </div>

                <div className={`${st.overflow}`}>
                    <Menu menuItemStyles={menuItemStyles}>
                        <SubMenu icon={<TravelExploreIcon />} label="Site web">
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("home") }}>Accueil</MenuItem>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("about") }}>À propos</MenuItem>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("contact") }}>Contact</MenuItem>
                        </SubMenu>
                        <MenuItem icon={<GridViewRoundedIcon />} onClick={() => { setComponent("dashboard"); setRefreshComponents(r => !r) }}>Tableau de bord</MenuItem>
                        <MenuItem icon={<MailIcon />} onClick={() => { setComponent("inbox"); setRefreshComponents(r => !r) }}>
                            Boîte de réception
                            {notifications && notifications.message && notifications.message.length > 0 && <span className={`${st.notificationIcon} me-2`} >{notifications.message.length}</span>}
                        </MenuItem>

                        <SubMenu icon={<RestaurantOutlinedIcon />} label={
                            <div className='position-relative'>
                                <span>Prestataires</span>
                                {notifications && notifications.provider && notifications.provider.length > 0 && <span className={`${st.notificationIconGeneral}`}></span>}
                            </div>
                        }>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("requestsList") }} className='position-relative'>
                                Demandes d'adhésion
                                {notifications && notifications.provider && notifications.provider.length > 0 && <span className={`${st.notificationIcon} me-2`} >{notifications.provider.length}</span>}
                            </MenuItem>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("providersList") }}> Liste des prestataires</MenuItem>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("archivedProvidersList") }}> Liste des prestataires archivés</MenuItem>
                        </SubMenu>
                        <SubMenu icon={<StoreOutlinedIcon />} label={
                            <div className='position-relative'>
                                <span>Établissements</span>
                                {notifications && notifications.establishment && notifications.establishment.length > 0 && <span className={`${st.notificationIconGeneral}`}></span>}
                            </div>
                        }>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("estabsRequests") }} className='position-relative'>
                                Demandes d'établissements
                                {notifications && notifications.establishment && notifications.establishment.length > 0 && <span className={`${st.notificationIcon} me-2`} >{notifications.establishment.length}</span>}
                            </MenuItem>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("estabsList") }}> Liste des établissements</MenuItem>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("archivedEstabsList") }}> Liste des établissements archivés</MenuItem>
                        </SubMenu>
                        <SubMenu icon={<ManageAccountsOutlinedIcon />} label="Utilisateurs" >
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("usersList") }}> Liste des utilisateurs</MenuItem>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("archivedUsersList") }}> Liste des utilisateurs archivés</MenuItem>
                        </SubMenu>
                        <SubMenu icon={<SettingsOutlinedIcon />} label="Configurations" >
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("mailConfig") }} > E-mail</MenuItem>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("websiteInfos") }} > Informations du site</MenuItem>
                            <MenuItem onClick={() => { setRefreshComponents(r => !r); setComponent("estabConfig") }} > Configuration d'établissements </MenuItem>
                        </SubMenu>
                    </Menu>
                </div>
            </Sidebar>
        </div>


    );
}

export default AdminSidebar;