import { useEffect, useRef, useState } from "react";
import st from "./editItem.module.css";
import { updateItem } from "../../../../services/menuApi";

function EditItem(props) {
    const { menuId, activeCategory, reset, item, setRefresh } = props;
    const imgView = useRef(null);
    const inputFile = useRef(null);
    const closeBtn = useRef(null);

    const initialImgSrc = item.photo && item.photo !== "" ?
        `${process.env.REACT_APP_SERVER_PUBLIC}${item.photo}` :
        "/images/uploadLogo.png";

    const [formValues, setFormValues] = useState({ name: "", price: "", description: "" });
    const [photo, setPhoto] = useState();
    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErr(false);
        setSuccess(false);
        setFormValues({ name: "", price: "", description: "" })
    }, [reset]);
    
    useEffect(() => {
        setFormValues({
            name: item ? item.name : "",
            price: item ? item.price : "",
            description: item ? item.description : ""
        })
    }, [item])

    const handleChange = (e) => {
        setErr(false);
        const { value, name } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const uploadImage = (e) => {
        setErr(false)
        if (inputFile.current.files.length > 0) {
            const imgLink = URL.createObjectURL(inputFile.current.files[0]);
            imgView.current.src = imgLink;
            setPhoto(inputFile.current.files[0]);
        } else {
            console.error("No file selected.");
            setLoading(false);
            setErr("emptyFile");
        }
    };

    const drop = (e) => {
        e.preventDefault();
        inputFile.current.files = e.dataTransfer.files;
        uploadImage();
    }

    const editItem = () => {
        const formData = new FormData();
        if (formValues.name === "") {
            setErr("nameReq");
            return;
        }
        if (formValues.price === "") {
            setErr("priceReq");
            return;
        }

        setLoading(true);
        setErr(false);
        setSuccess(false);

        formData.append("menu", menuId);
        formData.append("category", activeCategory._id);

        for (const key in formValues) {
            if (formValues[key] !== null && formValues[key] !== undefined) {
                formData.append(key, formValues[key])
            }
        }

        if (photo instanceof File) {
            formData.append("photo", photo);
        }

        // for (const pair of formData.entries()) {
        //     console.log(pair[0], pair[1]);
        // }
        updateItem(item._id, formData, (cb) => {
            if (cb.status === 200) {
                setRefresh(r => !r);
                setTimeout(() => {
                    setLoading(false);
                    setSuccess(true);
                }, 500);
                setTimeout(() => {
                    setSuccess(false);
                    closeBtn.current.click();
                }, 2000);
                return;
            } else {
                setTimeout(() => {
                    setLoading(false);
                    setErr("server");
                }, 500);
            }
        })
    }

    return (
        <div className={`modal fade`} id="editItemModal" tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-lg`}>
                <div className={`modal-content ${st.modalContent}`}>
                    {loading ? (<span className={`m-5 ${st.loaderBox}`}><span className={`${st.loader}`}></span></span>) : (<>
                        <div className="row relative">
                            <div className="col">
                                <button type="button" ref={closeBtn} className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                                <h4 className='mt-4 ms-4'>Modifier l'article</h4>
                            </div>
                        </div>
                        <div className="row mt-1 mx-1 px-lg-5 px-sm-3 ">
                            <div className={`col-lg-3 ${st.photoColumn}`}
                                onDragOver={(e) => { e.preventDefault(); }}
                                onDrop={drop}>
                                <input ref={inputFile} type="file" accept="image/*" onChange={uploadImage} hidden />
                                <img
                                    id="img-view" ref={imgView}
                                    className={`object-fit-fillq ${st.photo}`}
                                    src={initialImgSrc}
                                    alt=""
                                    onClick={() => inputFile.current.click()}
                                />
                            </div>
                            <div className={`col-lg ${st.detailsColumn}`}>
                                <div className="row">
                                    <div className="col-8">
                                        <input placeholder="Nom du plat" name="name" type="text" className="form-control"
                                            onChange={handleChange} value={formValues.name} />
                                    </div>
                                    <div className="col-4 text-end ps-0 ps-sm-2">
                                        <input placeholder="Prix" name="price" type="number" className="form-control"
                                            onChange={handleChange} value={formValues.price} />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col">
                                        <textarea rows={3} className="form-control" name="description" placeholder="Description"
                                            onChange={handleChange} value={formValues.description} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <span className={err ? "txtDanger" : success ? "txtSuccess" : ""}>
                                    {err === "nameReq" && "Le nom d'article est requis !"}
                                    {err === "priceReq" && "Le prix d'article est requis !"}
                                    {err === "server" && "Un problem est servenue, réessayer plus tard !"}
                                    {success && "Modifié avec succès !"}
                                </span>
                            </div>
                        </div>
                        <div className="row mt-3 mt-lg-2 mb-3 pe-md-3" >
                            <div className="col text-lg-end text-center">
                                <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                <button onClick={editItem} className={`${st.save}`}>Modifier</button>
                            </div>
                        </div>
                    </>)}
                </div>
            </div>
        </div>
    );
}

export default EditItem;