import AppNavbar from '../../../components/navigation/app-navigation/app-navbar';
import st from './establishmentPage.module.css';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import Tooltip from '@mui/material/Tooltip';
import MenuDisplay from '../../../components/establishments/establishmentDisplay/menuDispaly/menuDisplay';
import InfoDisplay from '../../../components/establishments/establishmentDisplay/infoDispaly/infoDisplay';
import { useEffect, useRef, useState } from 'react';
import Reviews from '../../../components/establishments/establishmentDisplay/reviews/reviews';

function EstablishmentsPage(props) {
    const { user, website, establishment } = props;
    const nextBtn = useRef(null);
    const isAdmin = user.role === "admin";
    const isProvider = user.role === "provider";
    const isUser = user.role === "user";
    const [estabStatus, setEstabStatus] = useState(false);

    useEffect(() => {
        if (establishment && establishment.photos && establishment.photos.length > 0) {
            const intervalId = setInterval(() => {
                nextBtn.current.click();
            }, 5000);
            return () => clearInterval(intervalId);
        }
    }, [establishment]);

    useEffect(() => {
        if (establishment.workingtime) {
            const checkOpenStatus = () => {
                const now = new Date();
                const currentDay = now.toLocaleDateString('en-US', { weekday: 'long' });
                const currentTime = now.getHours() * 60 + now.getMinutes();

                const todaySchedule = establishment.workingtime.find(schedule => schedule.day === currentDay);

                if (todaySchedule) {
                    const openingTime = parseTime(todaySchedule.openingTime);
                    const closingTime = parseTime(todaySchedule.closingTime);
                    return openingTime <= currentTime && currentTime <= closingTime;
                }

                return false;
            };

            const parseTime = (timeString) => {
                const [hours, minutes] = timeString.split(':').map(Number);
                return hours * 60 + minutes;
            };

            setEstabStatus(checkOpenStatus());

            const intervalId = setInterval(() => {
                setEstabStatus(checkOpenStatus());
            }, 60000); // Check every minute

            return () => clearInterval(intervalId);
        }
    }, [establishment])

    return (
        <>
            {!isAdmin && !isProvider && !isUser && <AppNavbar website={website} />}
            <div className={`${isProvider ? "clientPage" : st.page}`}>
                <div className={`${st.body}`}>
                    {establishment &&
                        <div>
                            <div className="row p-0">
                                <div className="col">
                                    <div id="carousel" className="carousel slide" data-ride="carousel" data-bs-ride="carousel" >
                                        <div className="carousel-inner">
                                            {establishment.photos && establishment.photos.length > 0 ? establishment.photos.map((p, i) => (
                                                <div key={i} className={`carousel-item ${i === 0 && "active"}`}>
                                                    <img src={`${process.env.REACT_APP_SERVER_PUBLIC + "" + p.photo}`} alt={p.photo} className={`w-100 ${st.img} object-fit-cover`} />
                                                </div>
                                            )) :
                                                <div className={`carousel-item active`}>
                                                    <img src={`/images/cover.jpg`} alt='' className={`w-100 ${st.img} object-fit-cover`} />
                                                </div>}
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        </button>
                                        <button ref={nextBtn} className="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={`row position-relative`}>
                                {/* profile picture */}
                                <div>
                                    <div className={`position-absolute ${st.profile}`} >
                                        <img src={establishment.logo ? process.env.REACT_APP_SERVER_PUBLIC + "" + establishment.logo : "/images/defaultEstablishment.png"} className={`object-fit-fill ${st.pic}`} alt="" />
                                    </div>
                                </div>

                                {/* restaurant name/status/rating/type  */}
                                <div className={`col mb-lg-0 mb-3  ${st.col}`} style={{ minHeight: '120px' }}>
                                    {/* name */}
                                    <div className="row">
                                        <div className="col">
                                            <div className={`${st.estName}`}>
                                                <b>{establishment.name} </b>
                                                <span className='position-absolute'>
                                                    <Tooltip title="L'annonce a été vérifiée et appartient au propriétaire ou au gestionnaire de l'entreprise." arrow>
                                                        <VerifiedOutlinedIcon className='ms-1 mb-4 text-success' />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={`col ${st.info}`}>
                                            {/* rating */}
                                            <span>
                                                <span className={st.estRating}>
                                                    {establishment.rating}
                                                    <span className='position-absolute small'><StarOutlinedIcon /></span>
                                                </span>
                                                <span className='ms-4'>({establishment.reviews.length} avis)</span>
                                            </span>
                                            {/* status */}
                                            <span className={`ps-3 ${estabStatus ? 'text-success' : 'text-danger'} ${st.estStatus}`}>
                                                {estabStatus ? 'Ouvert' : 'Fermé'}
                                            </span>
                                        </div>
                                    </div>
                                    {/* type */}
                                    <div className="row">
                                        <div className={`col ${st.estType}`}>
                                            <span>
                                                {establishment.type.join(' | ')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container mt-lg-5">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <InfoDisplay establishment={establishment} />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col">
                                                <MenuDisplay establishment={establishment} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-l">
                                        <Reviews />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default EstablishmentsPage;