//import st from './media.module.css';
import Logo from './logo/logo';
import Photos from './photos/photos';

function Media(props) {
    return (
        <div className='row'>
            <div className="col-lg-3">
                {/* <div className={`card mt-1 mb-3 ps-3 pe-3 ${st.mediaCard}`}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={logoDrop}>
                    <div className="row relative text-center mt-4">
                        <div className="col">
                            <h5>Logo</h5>
                            <Tooltip title="Faites glisser et déposez ou cliquez sur l'image pour télécharger votre logo" arrow>
                                <ErrorOutlineIcon className={`${st.information}`} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="row pt-2 pb-3">
                        <div className="text-center p-0">
                            <input ref={inputFile} type="file" accept="image/*" onChange={logoUpImg} hidden />
                            <img ref={imgView} id="img-view" alt="avatar" className={`object-fit-cover ${st.img}`}
                                src={establishment && establishment.logo ?
                                    `${process.env.REACT_APP_SERVER_PUBLIC}${establishment.logo}`
                                    : "/images/uploadLogo.png"}
                                onClick={() => inputFile.current.click()}
                            />
                        </div>
                    </div>
                    {logoErr === "empty" && (
                        <div className="row text-center mb-2">
                            <span className='txtDanger'>Veuillez insérer une photo</span>
                        </div>
                    )}
                    {logoSuccess && (
                        <div className="row text-center mb-2">
                            <span className='txtSuccess'>Enregistré avec succès</span>
                        </div>
                    )}

                    {logoLoading && (
                        <div className="row">
                            <div className="d-flex justify-content-center mb-3">
                                <div className={`${st.loading}`}></div>
                            </div>
                        </div>)}

                    <div className="row mb-5 text-center">
                        <div className="col">
                            <button onClick={submitLogo} className={`${st.saveLogo}`} >Enregistrer</button>
                        </div>
                    </div>
                </div> */}
                <Logo establishment={props.establishment} />
            </div>
            <div className="col-lg-9">
                <Photos establishment={props.establishment} />
            </div>
        </div>
    );
}

export default Media;
