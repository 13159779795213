import { getEstablishmentHighlights } from '../../../services/website';
import st from './highlights.module.css';
import { useEffect, useState } from 'react';

function Highlights(props) {
    const { establishment, highlights, setHighlights } = props;
    const [highlightsData, setHighlightsData] = useState([]);

    useEffect(() => {
        getEstablishmentHighlights((cb) => {
            if (cb.status === 200) {
                setHighlightsData(cb.data.highlights)
            }
        })
    }, []);

    useEffect(() => {
        if (establishment && establishment.highlights && establishment.highlights.length > 0) {
            setHighlights(establishment.highlights);
        }
    }, [establishment, setHighlights])

    const handleChecked = (id) => {
        const selectedHighlight = highlightsData.find(h => h._id === id);
        if (highlights.find(h => h._id === id)) {
            console.log('tfasa5ha');
            setHighlights(highlights.filter(h => h._id !== selectedHighlight._id));
        } else {
            console.log('tzidha');
            setHighlights([...highlights, selectedHighlight]);
        }
    };

    return (
        <div className={st.highlights}>
            <div className="row text-center">
                {highlightsData.map((h, index) => (
                    <div key={index} className={`col-sm-3 col-4 d-flex justify-content-center`}>
                        <div className={`${highlights.find(hl => hl._id === h._id) && st.checked} ${st.box}`} onClick={() => handleChecked(h._id)}>
                            <img className={`mt-2 ${st.icon}`} src={`${process.env.REACT_APP_SERVER_PUBLIC}${h.photo}`} alt={""} />
                            <h6 className={`mt-2 ${st.title}`}>{h.name}</h6>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Highlights;
