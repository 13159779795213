import st from "./signup-verify.module.css";
import { useState } from "react";
import { loginWithGoogle } from "../../services/authApi";
import { useGoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import { verifyEmail } from "../../services/mailApi";

function SignupVerify(props) {
  const {website} = props;
  const [email, setEmail] = useState("");
  const [err, setErr] = useState(false);
  const [gglErr, setgglErr] = useState(false);
  const [timeOut, setTimeOut] = useState(false);

  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const verification = (event) => {
    event.preventDefault();
    setSent(false);
    setErr(false);
    if (!email) {
      setErr("req");
      return;
    }

    let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setErr("email");
      return;
    }
    setLoading(true);
    verifyEmail({ email }, (cb) => {
      if (cb.status === 200) {
        setTimeout(() => { setLoading(false); setSent(true); }, 2000);
      } else if (cb.response.status === 422) {
        setTimeout(() => { setLoading(false); setSent(false); setErr("inUse"); }, 1000);
      } else if (cb.response.status === 423) {
        setTimeout(() => { setLoading(false); setSent(false); setErr("deleted"); }, 1000);
      } else if (cb.response.status === 401) {
        setTimeout(() => { setLoading(false); setSent(false); setErr("suspended"); }, 1000);
      } else if (cb.response.status === 403) {
        let remainingSeconds = (new Date(cb.response.data.timeout)) - (new Date());
        let remainingDays = Math.ceil(remainingSeconds / (1000 * 60 * 60 * 24));
        setTimeOut(remainingDays);
        
        setTimeout(() => {
          setLoading(false);
          setSent(false);
          setErr("timeout");
        }, 1000);
      } else {
        setTimeout(() => { setLoading(false); setSent(false); setErr("notSent"); }, 1000);
        console.error(cb);
      }
    })
  };

  const googleSignup = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (res) => {
      loginWithGoogle(res.code, (cb) => {
        if (cb.status === 200) {
          sessionStorage.setItem("username", cb.data.username);
          window.location.href = "/";
        } else if (cb.status === 201) {
          sessionStorage.setItem("username", cb.data.username);
          window.location.href = "/";
        } else if (cb.response.status === 401) {
          setgglErr("suspended")
          return;
        } else if (cb.response.status === 403 && cb.response.data && cb.response.data.message === "Timeout") {
          let remainingSeconds = (new Date(cb.response.data.timeout)) - (new Date());
          let remainingDays = Math.ceil(remainingSeconds / (1000 * 60 * 60 * 24));
          setTimeOut(remainingDays);
          setgglErr("timeout")
          return;
        } else {
          console.error(cb);
        }
      });
    },
    onError: (errorResponse) => console.log({ errorResponse }),
  });

  return (
    <form onSubmit={verification}>
      <div className="row mb-4">
        <div className="col-4 d-flex justify-content-center">
          <div>
            <img onClick={()=>window.location.href = "/"}
              className={st.logo}
              src={website && website.logo ? `${process.env.REACT_APP_SERVER_PUBLIC + "" + website.logo}` : "/images/logo-icon.png"}
              alt="menuonline"
            />
          </div>
        </div>
        <div className="col-8">
          <p className={`${st.formtitle}`}>S'inscrire</p>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-12 mb-2">
          <div className="form-group">
            <label className="form-label">Pour créer un nouveau compte, veuillez fournir votre adresse e-mail.</label>
            <input
              type="text"
              className={`form-control ${err ? "is-invalid" : ""}`}
              value={email}
              name="email"
              placeholder="Adresse e-mail"
              onChange={(e) => { setSent(false); setErr(false); setEmail(e.target.value) }}
            />
          </div>
        </div>
      </div>

      {loading && <div className="d-flex justify-content-center"><div className={`${st.loader}`}></div></div>}
      {sent && <div className="text-center text-success mt-1 mb-1">Un email vous a été envoyé avec succès.Vérifiez votre boîte de réception</div>}
      <div className=" text-danger">
        {err === "req" && "Ce champ est obligatoire !"}
        {err === "email" && "Le format d'adresse e-email est invalide !"}
        {err === "inUse" && "Cette adresse e-mail est déjà utilisée !"}
        {err === "notSent" && "Erreur lors de l'envoi de l'email"}
        {err === "suspended" && "Votre compte est suspendu !"}
        {err === "timeout" && timeOut ? "Vous êtes suspendu pendant " + timeOut + " jour(s)" : ""}
        {err === "deleted" && (
          <span> Vous avez supprimé votre compte.{" "}
            <span onClick={() => { window.location.href = "/login" }} className={`${st.loginError}`}>
              Identifiez-vous
            </span>{" "}
            pour accéder à votre compte.
          </span>
        )}
      </div>

      <div className="row mt-2 mb-2">
        <div className="col-lg d-flex justify-content-center text-center">
          <button type="submit" className={st.btn}>
            S'inscrire
          </button>
        </div>
      </div>

      <div className="row no gutters mb-2">
        <div className="col-3 px-0">
          <hr />
        </div>
        <div className="col-6 mt-1 px-0 text-center">
          Ou identifiez-vous avec
        </div>
        <div className="col-3 px-0">
          <hr />
        </div>
      </div>

      <div className="row d-flex justify-content-center text-center mb-2">
        <button
          type="button"
          onClick={googleSignup}
          className={`${st.customBtn}`}
        >
          <img className={st.icon} src="./images/google.png" alt="icon" />
          <span className={`${st.buttonText}`}>Google</span>
        </button>
      </div>
      <div className="row">
        <span className="text-danger text-center mt-1">
          {gglErr === "timeout" && timeOut ? "Vous êtes suspendu pendant " + timeOut + " jour(s)" : ""}
          {gglErr === "suspended" && "Votre compte est suspendu !"}
        </span>
      </div>

      <div className="row mt-2 text-center">
        <div className="col">
          Vous avez déjà un compte?{" "}
          <Link to="/login" className={`${st.login}`}>
            Connectez-vous ici
          </Link>
        </div>
      </div>
    </form>
  );
}

export default SignupVerify;
