import { useEffect, useState, useRef } from "react";
import st from "./manageEstablishment.module.css";
import { changeExpiration } from "../../../../services/adminApis";


function ManageEstablishment(props) {

    const { establishment, reset, setRefresh, modalId } = props;
    const [expiration, setExpiration] = useState('');
    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const closeBtn = useRef();


    useEffect(() => {
        setErr(false);
        setSuccess(false);
        if (establishment && establishment.expiresAt) {
            setExpiration(establishment.expiresAt.slice(0, 10));
        }
    }, [reset, establishment]);

    const submit = () => {
        if (establishment) {
            setLoading(true);

            changeExpiration(establishment._id, { expiration }, (cb) => {
                if (cb.status === 200) {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    setSuccess(true);
                    setRefresh(r => !r);
                    setTimeout(() => {
                        closeBtn.current.click();
                    }, 1800);
                    return;
                } else if (cb.response && cb.response.status === 304) {
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                    setErr("oldValue");
                    return
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                    setErr("server")
                }
            });
        }
    };

    return (
        <div className={`modal fade`} id={modalId ? modalId : ""} tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-md`}>
                <div className={`modal-content ${st.modalContent}`}>
                    {loading ? (<span className={`m-5 ${st.loaderBox}`}><span className={`${st.loader}`}></span></span>) : (<>
                        <div className="row relative">
                            <div className="col">
                                <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                                <h4 className='mt-4 ms-4'>Gérer l'adhésion</h4>
                            </div>
                        </div>
                        <div className="modal-body ps-4 pe-4">
                            <div className="row  ms-1 me-1">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label">Date d'éxpiration<span className="text-danger">*</span></label>
                                        <input type="date" value={expiration}
                                            onChange={(e) => { setExpiration(e.target.value); setErr(false); setSuccess(false) }}
                                            className={`form-control ${err ? "is-invalid" : success ? "is-valid" : ""}`} />
                                    </div>
                                    <span className={`${err ? "text-danger" : success ? "text-success" : ""}`}>
                                        {err === "oldValue" && "Entrer une nouvelle date !"}
                                        {err === "server" && "Un problem est survenue, réessayez plus tard !"}
                                        {success && "Le changement a été effectuée avec succée "}
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-4" >
                                <div className="col px-0 text-end">
                                    <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                    <button onClick={submit} className={`${st.save}`}>Enregistrer</button>
                                </div>
                            </div>
                        </div>
                    </>)}


                </div>
            </div>
        </div>
    );
}

export default ManageEstablishment;