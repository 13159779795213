import { useEffect, useState } from 'react';
import st from './establishmentRequest.module.css'
import General from '../../../components/establishments/general/general';
import Footer from '../../../components/footer/footer';
import { submitEstablishmentRequest } from '../../../services/website';
import io from "socket.io-client";
function EstablishmentRequest(props) {
    const { website } = props;
    const [socket, setSocket] = useState(null);
    useEffect(() => {
        socketRefresh();
    }, []);

    const socketRefresh = () => {
        const newSocket = io.connect(process.env.REACT_APP_SOCKET_URL);
        setSocket(newSocket);
        return () => {
            newSocket.disconnect();
        };
    }

    const [generalFormValues, setGeneralFormValues] = useState({
        location: "",
        phone: "",
        name: "",
        type: []
    });

    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);
    const [feedback, setFeedBack] = useState(false);
    const [saved, setSaved] = useState(false);

    const getBack = () => {
        window.location.href = "/account/establishments"
    }

    const submitForm = () => {
        let genForm = generalFormValues;
        if (genForm.name === "" || genForm.phone === "" || genForm.location === "" || genForm.type.length < 1) {
            setErr(true);
            setFeedBack("Remplir les champs sont obligatoires * !");
            return;
        }
        let charactersPattern = /.{3,15}/;
        let phonePattern = /[-+ ]?\d{5,}/;
        if (!charactersPattern.test(genForm.name)) {
            setErr(true);
            setFeedBack("Le format de nom d'établissement est invalide");
            return;
        } else if (!phonePattern.test(genForm.phone)) {
            setErr(true);
            setFeedBack("Le téléphone doit être minimum de 5 chiffres");
        } else if (!charactersPattern.test(genForm.location)) {
            setErr(true);
            setFeedBack("Le format d'adresse d'établissement est invalide");
        } else {
            setFeedBack(false)
            setLoading(true);
            submitEstablishmentRequest(genForm, (cb) => {
                if (cb.status === 200) {
                    console.log(cb.data.estabRequest);
                    socket.emit('sendEstabRequest', { request: cb.data.estabRequest });
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    setTimeout(() => {
                        setSaved(true);
                    }, 500);
                } else {
                    setErr(true);
                    setFeedBack("Un problem est survenue, réessayer plus tard !");
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                }
            })
        }
    }


    return (
        <div className="clientPage">
            <div className="container-lg" style={!saved ? { minHeight: "49vh" } : {}}>
                <div className="row">
                    <div className="col-md-8 mt-4 ">
                        <h4 className='ms-4 me-4'>{saved ? "Demande envoyée" : "Demande d'éstablissement"}</h4>
                    </div>
                </div>
                {saved ?
                    (
                        <div className="row">
                            <div className="col mt-3 mb-2 mx-5">
                                <span>Votre demande a été soumise avec succès ! </span><br />
                                <span>Vous recevrez une notification par e-mail dès que le responsable du site aura traité votre demande.</span>
                            </div>
                        </div>
                    ) :
                    (
                        <div className="row">
                            <div className="col mt-3 mb-2">
                                <General formValues={generalFormValues} setFormValues={setGeneralFormValues}
                                    setErr={setErr} setFeedBack={setFeedBack} />
                                <div className='text-center mt-2 pe-1 ps-1'>
                                    {err && <span className='txtDanger'> {feedback} </span>}
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="row">
                    {loading && (
                        <div className="d-flex justify-content-center mt-2 mb-2">
                            <div className={`${st.loading}`}></div>
                        </div>)}
                </div>
                {
                    saved ?
                        (
                            <div className="row">
                                <div className="col pe-lg-4 p-0 mt-2 text-lg-end text-center">
                                    <button onClick={getBack} className={`${st.close}`}>Retour</button>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className="row">
                                <div className="col pe-lg-4 p-0 mt-2 text-lg-end text-center">
                                    <button onClick={getBack} className={`${st.close}`}>Annuler</button>
                                    <button onClick={submitForm} className={`${st.save}`}>Envoyer</button>
                                </div>
                            </div>
                        )
                }
            </div>
            <Footer website={website} />
        </div>
    );
}

export default EstablishmentRequest;