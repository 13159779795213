import { useState } from "react";
import st from "./resetPassword.module.css";
import { resetPassword } from "../../services/mailApi";

function ResetPassword() {
  const initialValues = {
    newpass: "",
    confirmNewPass: "",
  };

  const [err, setErr] = useState(false);
  const [resetFormValues, setResetFormValues] = useState(initialValues);
  const [showPass, setShowPass] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErr(false);
    setResetFormValues({ ...resetFormValues, [name]: value });
  };

  const resetPass = (event) => {
    event.preventDefault();

    let passwordPattern = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;

    if (!resetFormValues.newpass || !resetFormValues.confirmNewPass)
    {
      setErr("required");
      return ;
    } 
    if (!passwordPattern.test(resetFormValues.newpass)) {
      setErr("pattern");
      return;
    }
    if (resetFormValues.newpass !== resetFormValues.confirmNewPass)
    {
      setErr("match");
      return ;
    }

      const newPassword = resetFormValues.newpass;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const token = urlSearchParams.get('token');
      
      resetPassword({newPassword},token,(cb)=>{
        if (cb.status === 200) {
          window.location.href='/login'
        }else if (cb.response.status === 403){
          setErr("session")
          setTimeout(()=>{window.location.href='/login'},4000)
        }
        else {
          console.error(cb);
        }
      })
    
  };

  return (
    <div className={`${st.page} `}>
      <div className="container d-flex justify-content-center align-items-center">
        <div className={`card ${st.loginCard}`}>
          <form onSubmit={resetPass}>
            <div className="row d-flex text-center">
              <div className="col">
                <div>
                  <img
                    className={st.logo}
                    src="./images/logo-icon.png"
                    alt="menuonline"
                  />
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col">
                <p className={`${st.title}`}>Réinitialiser le mot de passe</p>
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label className="form-label">
                  Nouveau Mot de passe<span className="text-danger">*</span>
                </label>
                <input
                  type={showPass ? "text" : "password"}
                  value={resetFormValues.newpass}
                  onChange={handleChange}
                  name="newpass"
                  className={`form-control ${(err === "required" && !resetFormValues.newpass) || err === "match" || err === "pattern" || err === "session" ? "is-invalid" : ""}`}
                />
              </div>
            </div>
            <div>
              <div className="form-group mt-3">
                <label className="form-label">
                  Confirmer le mot de passe<span className="text-danger">*</span>
                </label>
                <input
                  type={showPass ? "text" : "password"}
                  value={resetFormValues.confirmNewPass}
                  onChange={handleChange}
                  name="confirmNewPass"
                  className={`form-control ${(err === "required" && !resetFormValues.confirmNewPass) || err === "match" || err === "session" ? "is-invalid" : ""}`}
                />
              </div>
            </div>
            <div
              className="text-danger errs mb-2 mt-1"
            >
              {err === "match" && "Les mots de passe ne correspondent pas !"}
              {err === "session" && "Session expirée !"}
              {err === "required" && "Tous les champs sont obligatoires !"}
              {err === "pattern" && "Le mot de passe doit contenir au moins 8 caractères et inclure une lettre majuscule ainsi qu'un chiffre."}
            </div>
            <div className="row">
              <div className="col d-flex justify-content-end">
                <div className={`form-check-reverse form-switch ${st.formCheck}`}>
                  <label className="form-check-label">Montrer les mots de passes</label>
                  <input className={`form-check-input ${st.formCheckInput}`} type="checkbox" checked={showPass} onChange={(e) => setShowPass(e.target.checked)} />
                </div>
              </div>

            </div>

            <div className="row d-flex justify-content-center mt-2">
              <button type="submit" className={`${st.btn}`}>Réinitialiser</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
