import { useEffect, useState, useRef } from "react";
import { changeUserPassword } from "../../../services/adminApis";
import st from "./changePassword.module.css";


function ChangePass(props) {
    const { user, reset, modalId } = props;
    const [password, setPassword] = useState("");

    const closeBtn = useRef();
    const [showPassword, setShowPassword] = useState(false);

    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setErr(false);
        setSuccess(false);
        setShowPassword(false);
        setPassword('');
    }, [reset])

    const changePassword = () => {
        if (password === "") {
            setErr("req");
            return;
        }
        let passwordPattern = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
        if (!passwordPattern.test(password)) {
            setErr("format");
            return;
        }
        const id = (user && user._id) || "";
        setLoading(true);
        changeUserPassword(id, { password }, (cb) => {
            if (cb.status === 200) {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                setSuccess(true);
                setTimeout(() => {
                    closeBtn.current.click();
                }, 2000);
            }
            cb.response && cb.response.status === 400 && setErr('old');
            cb.response && cb.response.status === 422 && setErr('req');
            cb.response && cb.response.status === 500 && setErr('server');
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        })
    }

    return (
        <div className={`modal fade`} id={`${modalId ? modalId : ""}`} tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-md`}>
                <div className={`modal-content ${st.modalContent}`}>
                    {loading ? (<span className={`m-5 ${st.loaderBox}`}><span className={`${st.loader}`}></span></span>) : (<>
                        <div className="row relative">
                            <div className="col">
                                <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                                <h4 className='mt-4 ms-4'>Éditer le mot de passe</h4>
                            </div>
                        </div>
                        <div className="modal-body mt-1 px-0 ps-4 pe-4">
                            <div className="row ms-1 me-1">
                                {user && user.password ?
                                    (<div className="col">
                                        <h6>Vous allez <strong className="text-primary">changer</strong> le mot de passe de <strong>{user && user.lastname} {user && user.firstname}</strong></h6>
                                        <div className="form-group mt-3 position-relative">
                                            <label className="form-label">Mot de passe<span className="text-danger">*</span> (8 caractères, maj, min et num )</label>
                                            <div className={`${st.passwordToggle}`}>
                                                <input type={showPassword ? "text" : "password"} value={password}
                                                    onChange={(e) => { setPassword(e.target.value); setErr(false); setSuccess(false) }}
                                                    placeholder="Mot de passe" className={`form-control ${st.pass} ${err ? "is-invalid" : success ? "is-valid" : ""}`} />
                                                <div className={`${st.passwordToggleIcon}`}>
                                                    <span className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => setShowPassword(!showPassword)} ></span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="text-danger">
                                            {err === "req" && "Le champ est obligatoire !"}
                                            {err === "format" && "Le format est invalide !"}
                                            {err === "old" && "Entrez un nouveau mot de passe !"}
                                            {err === "server" && "Un problem est survenue, réessayez plus tard !"}
                                        </span>
                                        <span className="text-success">
                                            {success && "Le mot de passe a été modifié avec succès"}
                                        </span>
                                    </div>)
                                    :
                                    (<div className="col">
                                        <h6>Vous allez <strong className="text-success">ajouter</strong> un mot de passe pour <strong>{user && user.lastname} {user && user.firstname}</strong></h6>
                                        <div className="form-group mt-3 position-relative">
                                            <label className="form-label">Mot de passe<span className="text-danger">*</span> (8 caractères, maj, min et num )</label>
                                            <div className={`${st.passwordToggle}`}>
                                                <input type={showPassword ? "text" : "password"} value={password}
                                                    onChange={(e) => { setPassword(e.target.value); setErr(false); setSuccess(false) }}
                                                    placeholder="Entrez le nouveau mot de passe" className={`form-control ${err ? "is-invalid" : success ? "is-valid" : ""}`} />
                                                <div className={`${st.passwordToggleIcon}`}>
                                                    <span className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => setShowPassword(!showPassword)} ></span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="text-danger">
                                            {err === "req" && "Le champ est obligatoire !"}
                                            {err === "format" && "Le format est invalide !"}
                                            {err === "old" && "Entrez un nouveau mot de passe !"}
                                            {err === "server" && "Un problem est survenue, réessayez plus tard !"}
                                        </span>
                                        <span className="text-success">
                                            {success && "Le mot de passe a été modifié avec succès"}
                                        </span>
                                    </div>)}
                            </div>
                            <div className="row mt-4" >
                                <div className="col text-end">
                                    <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                    <button onClick={changePassword} className={`${st.save}`}>Enregistrer</button>
                                </div>
                            </div>
                        </div>
                    </>)}
                </div>
            </div>
        </div>
    );
}

export default ChangePass;