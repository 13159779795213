import axios from 'axios'
axios.defaults.withCredentials = true;

//Get website information
export const getWebsiteInfos = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/website`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Set website information
export const setWebsiteInfos = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/website`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get mail information
export const getMailConfig = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/website/mail-config`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Set mail information
export const changeMailConfig = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/website/mail-config`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Submit a partnership request
export const submitRequest = (data, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/website/request`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Submit a partnership request
export const submitEstablishmentRequest = (data, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/website/establishment-request`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Set establishments types
export const setEstablishmentsTypes = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/website/estabConfig/types`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Add establishments highlight
export const addEstablishmentHighlight = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/website/estabConfig/highlight`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Delete establishments highlight
export const deleteEstablishmentHighlight = (id, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/website/estabConfig/highlight/${id}`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get establishments highlights
export const getEstablishmentHighlights = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/website/estabConfig/highlights`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//setHomeBackground
export const setHomeBackground = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/website/background/home`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//setAuthBackground
export const setAuthBackground = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/website/background/auth`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//setContactBackground
export const setContactBackground = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/website/background/contact`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//setAboutusBackground
export const setAboutusBackground = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/website/background/aboutus`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}