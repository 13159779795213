import axios from 'axios'
axios.defaults.withCredentials = true;

//Get all users
export const getUsers = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/users`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get all providers
export const getProviders = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/providers`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Set establishment expiration
export const changeExpiration = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/admin/set-establishment-expiration/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get all requests
export const getRequests = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/requests`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get all establishment requests
export const getEstabsRequests = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/establishments-requests`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Change user password
export const changeUserPassword = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/admin/change-password/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Change user password
export const updateUserAccount = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/admin/update-user-account/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Set user status
export const setUserStatus = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/admin/set-user-status/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Update a partnership request's status
export const updateStatus = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/admin/request/set-status/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Update a partnership request's status
export const updateEstablishmentStatus = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/admin/request/set-establishment-status/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}