import PlaceIcon from '@mui/icons-material/Place';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import st from './responsiveCard.module.css'
function ResponsiveCard(props) {
    const { establishment, menus } = props;
    const [rltMenus, setRltMenus] = useState([]);

    useEffect(() => {
        if (menus && establishment) {
            setRltMenus(menus.filter(m => m.establishment === establishment._id))
        }
    }, [establishment, menus])
    return (
        <div className={`position-relative ${st.card}`} >
            <Link className={st.link} to={"/place/" + establishment.customUrl}>
                <img src={establishment.logo ? `${process.env.REACT_APP_SERVER_PUBLIC}${establishment.logo}` : "/images/defaultEstablishment.png"} className={st.img} alt="" />
            </Link>
            <small className='position-absolute top-0 end-0 mt-1 me-1 txtSuccess fw-medium' style={{ fontSize: '10px' }}>Ouvert</small>
            <small className='position-absolute top-0 start-0 mt-1 ms-2 fw-medium'><span style={{ fontSize: '12px' }}>{rltMenus && rltMenus.length > 0 && rltMenus.length} </span> <img src='/images/menu.png' alt='' className={st.menuicon} /> </small>
            {rltMenus && rltMenus.length < 1 && <small className='position-absolute top-0 start-0 fw-medium'><img src='/images/no.png' alt='' className={st.noicon} /></small>}
            <div className={st.content}>
                <div className="row">
                    <div className="col text-center">
                        <Link className={`text-decoration-none`} to={"/place/" + establishment.customUrl}>
                            <span className={st.name} >{establishment.name}</span>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{ height: '21px' }}>
                        <FoodBankIcon className={st.typeicon} />
                        <small className={st.type}>{establishment.type.join(' | ')}</small>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <span className={st.location}>
                            <PlaceIcon className={st.locationicon} />
                            <small>{establishment.location}</small>
                        </span>
                    </div>
                </div><div className="row">
                    <div className="col text-end me-1">
                            <StarOutlinedIcon className={st.ratingicon} />0
                            <small>({establishment.reviews.length} avis)</small>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResponsiveCard;