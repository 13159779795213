import { useEffect, useState } from "react";
import st from "./general.module.css";

import MyLocationIcon from '@mui/icons-material/MyLocation';
import { getEstablishmentsTypes } from "../../../services/establishmentApi";

function General(props) {
    const { err, setErr, formValues, setFormValues } = props;
    const [providedTypes, setProvidedTypes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);

    useEffect(() => {
        getEstablishmentsTypes((cb) => {
            setProvidedTypes(cb.data.establishmentsTypes);
        })
    }, [])

    const handleChange = (e) => {
        setErr(false);
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }

    useEffect(() => {
        setFormValues(prv => ({ ...prv, type: selectedTypes }));
    }, [selectedTypes, setFormValues])

    const toggleSelectCard = (type) => {
        let updatedSelectedTypes;
        if (selectedTypes.includes(type)) {
            if (selectedTypes.length > 1) {
                updatedSelectedTypes = selectedTypes.filter((selectedType) => selectedType !== type);
            }
        } else {
            updatedSelectedTypes = [...selectedTypes, type];
        }
        if (updatedSelectedTypes) {
            setSelectedTypes(updatedSelectedTypes);
        }
    }



    return (
        <div>
            <div className="pt-4 ps-4 pe-4">
                <div className="row mb-2">
                    <div className="col">
                        <span><strong>IMPORTANT* : </strong>Cette demande sera traitée par un responsable du site. En cas d'acceptation, un établissement sera créé en utilisant les coordonnées fournies.</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className={`form-group`}>
                            <label className='form-label'>Nom<span className="text-danger">*</span></label>
                            <input type="text" name='name' placeholder="Nom d'établissement"
                                className={`form-control ${err && formValues.name === "" && "is-invalid"}`}
                                value={formValues.name} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className={`form-group`}>
                            <label className='form-label'>Téléphone<span className="text-danger">*</span></label>
                            <input type="number" name='phone' placeholder='Téléphone'
                                className={`form-control ${err && formValues.phone === "" && "is-invalid"}`}
                                value={formValues.phone} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <div className={`form-group`}>
                            <label className='form-label'>Adresse<span className="text-danger">*</span></label>
                            <div className={`${st.myLocation}`}>
                                <input type="text" value={formValues.location}
                                    onChange={handleChange} name='location'
                                    placeholder="EX : Midoun, Djerba, Tunisie" className={`form-control ${st.location} ${err && "is-invalid"}`} />
                                <div className={`${st.myLocationIcon}`}>
                                    <MyLocationIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <div className={`position-relative ${st.typesContainer}`}>
                            <label className='form-label'>Type d'établissement<span className="text-danger">*</span></label>
                            <div className="row px-5">
                                {
                                    providedTypes.map((type, key) => (
                                        <div key={key} onClick={() => { toggleSelectCard(type) }}
                                            className={`${selectedTypes && selectedTypes.includes(type) ? st.selectedCard : st.card} col-lg m-2 position-relative card py-2 d-flex align-items-center justify-content-center`}>
                                            <span>{type}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default General;
