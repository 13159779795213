import AppNavbar from "../../components/navigation/app-navigation/app-navbar";
import st from "./notFound.module.css"
function NotFound(props) {
    const { user, website } = props;
    const isAdmin = user.role === "admin";
    const isProvider = user.role === "provider";
    const isUser = user.role === "user";
    return (
        <>
            {!isAdmin && !isProvider && !isUser && <AppNavbar website={website} />}
            <div className={`${isProvider && "clientPage"} ${!isAdmin && !isProvider && st.page}`}>
                <div className={`${st.body} text-center`}>
                    <img src="/images/notFound.gif" alt="Not Found" className={st.img} />
                </div>
            </div>
        </>
    );
}

export default NotFound;