import st from './infoDisplay.module.css'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useState, useRef, useEffect } from 'react';

function InfoDisplay(props) {
    const { establishment } = props;
    const [accord, setAccord] = useState(false);
    const socialRef = useRef(null);
    const [height, setHeight] = useState('0px');

    const getIcon = (name) => {
        switch (name) {
            case 'Email': return <EmailOutlinedIcon className={st.contactIcon} />;
            case 'Facebook': return <FacebookOutlinedIcon className={st.contactIcon} />;
            case 'Instagram': return <InstagramIcon className={st.contactIcon} />;
            case 'Tiktok': return <i className={`bi bi-tiktok ms-2 ${st.contactIcontiktok}`}></i>
            case 'Pinterest': return <PinterestIcon className={st.contactIcon} />;
            case 'X': return <XIcon className={st.contactIcon} />;
            case 'LinkedIn': return <LinkedInIcon className={st.contactIcon} />;
            default: return null;
        }
    };

    const displaySocials = () => {
        setAccord(!accord);
    };

    useEffect(() => {
        if (socialRef.current) {
            setHeight(accord ? `${socialRef.current.scrollHeight}px` : '0px');
        }
    }, [accord]);

    return (
        <div>
            <div className={`card mb-4 ps-2 pe-2 ${st.card}`}>
                <div className={`accordion accordion-flush`} id="accordionFlushExample">
                    <div className={`accordion-item ${st.item}`}>
                        <h6 className="accordion-header">
                            <button style={{ color: "black" }} className={`accordion-button collapsed ${st.button}`} type="button"
                                data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"
                                onClick={displaySocials}
                            >
                                <h5>Description</h5>
                            </button>
                        </h6>
                        <div id="flush-collapseOne" className={`accordion-collapse collapse`} data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body px-0 pt-1">
                                <div className={`row p-2 pt-0 text-center`}>
                                    <div className="col">
                                        <p className='m-0'>
                                            {establishment.description}
                                        </p>
                                    </div>
                                </div>

                                {/* highlights */}
                                {establishment.highlights && establishment.highlights.length > 0 &&
                                    <>
                                        <hr className={`m-0 p-0 mt-1 mb-1`} />
                                        <div className={`row ps-2 pt-1 mb-1`}>
                                            <div className="col">
                                                <h5>Commodités</h5>
                                            </div>
                                        </div>
                                        <div className={`row ps-2 pe-2`}>
                                            {establishment.highlights.map((highlight, i) => (
                                                <div key={i} className="col-xl-2 col-md-2 col-lg-3 col-3 p-0 mb-3 text-center">
                                                    <Tooltip title={highlight.name} arrow>
                                                        <img src={highlight.photo && process.env.REACT_APP_SERVER_PUBLIC + "" + highlight.photo} className={st.icon} alt='' />
                                                    </Tooltip>
                                                </div>
                                            ))}
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={`m-0 p-0 mt-1 mb-1`} />
                <div className={`row ps-2 pt-1`}>
                    <div className="col">
                        <h5>Contact</h5>
                    </div>
                </div>
                <div className="row mb-2 m-0 p-0">
                    <div className={`col px-0`} >
                        <table className={`m-0  p-1 ${st.table}`}>
                            <tbody>
                                {/* location */}
                                <tr>
                                    <td style={{ width: '40px' }}>
                                        <LocationOnOutlinedIcon className={st.contactIcon} />
                                    </td>
                                    <td>
                                        <p className='m-0 mt-1'><Link className={st.contactInfo} to={establishment.location} target="_blank">{establishment.location}</Link></p>
                                    </td>
                                </tr>

                                {/* phone */}
                                <tr>
                                    <td style={{ width: '40px' }}>
                                        <LocalPhoneOutlinedIcon className={st.contactIcon} />
                                    </td>
                                    <td>
                                        <p className='m-0 mt-1'><span className={st.contactInfo}>{establishment.phone} </span></p>
                                    </td>
                                </tr>

                                {/* socials */}
                                <tr>
                                    <td colSpan={2} style={{ padding: 0 }}>
                                        <div ref={socialRef} style={{ height, overflow: 'hidden', transition: 'height 0.35s ease' }}>
                                            <table style={{ width: '100%' }} className={st.table}>
                                                <tbody>
                                                    {establishment.socials && establishment.socials.map((social, i) => (
                                                        social && social.url && (
                                                            <tr key={i}>
                                                                <td style={{ width: '40px' }}>
                                                                    {getIcon(social.name)}
                                                                </td>
                                                                <td>
                                                                    <Link className={st.contactInfo} to={social.url} target="_blank">{social.url}</Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoDisplay;
