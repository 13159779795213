import { useEffect, useState } from "react";
import st from "./itemsList.module.css";
import AddItem from "../addItem/addItem";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditItem from "../editItem/editItem";
import DeleteItem from "../deleteItem/deleteItem";
import { getCategoryItems } from "../../../../services/menuApi";

function ItemsList(props) {
    const { activeCategory, activeMenu, setActiveCategory } = props;
    const [item, setItem] = useState('');
    const [items, setItems] = useState([]);
    const [search, setSearch] = useState('');
    const [reset, setReset] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [feedBack, setFeedBack] = useState(false);
    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (activeCategory) {
            const data = {
                menu: activeMenu._id,
                category: activeCategory._id
            }
            getCategoryItems(data, (cb) => {
                setLoading(true);
                if (cb.status === 200) {
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                    if (search === "") {
                        setItems(cb.data.items);
                    } else {
                        const searching = cb.data.items.filter(
                            item => item.name.toLowerCase().includes(search.toLowerCase())
                        );
                        setItems(searching);
                    }
                }
            })
        }
    }, [activeMenu, activeCategory, search, refresh]);


    return (
        <>
            {activeCategory ?
                <div>
                    <AddItem
                        activeCategory={activeCategory}
                        setActiveCategory={setActiveCategory}
                        reset={reset}
                        menuId={activeMenu._id}
                        setItems={setItems}
                        setRefresh={setRefresh} />

                    <EditItem
                        menuId={activeMenu._id}
                        activeCategory={activeCategory}
                        reset={reset}
                        item={item}
                        setItems={setItems}
                        items={items}
                        setRefresh={setRefresh}
                    />
                    <DeleteItem
                        setRefresh={setRefresh}
                        item={item}
                        menuId={activeMenu._id}
                        activeCategory={activeCategory}
                        setErr={setErr}
                        setFeedBack={setFeedBack}
                        setSuccess={setSuccess} />
                    <div className="row">
                        <div className="col mt-4 text-center">
                            <h4>{activeCategory && activeCategory.name}</h4>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-8">
                            <div className={`${st.inputGroup}`}>
                                <input onChange={(event) => { setSearch(event.target.value) }} value={search} autoComplete="off"
                                    type="text" placeholder="Rechercher" className={`border-0 bg-light ${st.formControl}`} />
                                <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-3 my-lg-0 text-end">
                            <button onClick={() => setReset(!reset)} data-bs-toggle="modal" data-bs-target={"#addItemModal"} className={`${st.addBtn}`}>
                                Ajouter
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mt-2 text-center">
                            {err && <span className='txtDanger' >{feedBack}</span>}
                            {success && <span className='txtSuccess' >{feedBack}</span>}
                        </div>
                    </div>
                    <hr />
                    {loading ? (<span className={`m-5 ${st.loaderBox}`}><span className={`${st.loader}`}></span></span>) : (
                        <div className="row">
                            <div className={`col ${st.overflow}`} >
                                {items && items.length > 0 && items.map((item, i) => (
                                    <div key={i} className={`row`} >
                                        <div className={`col py-2`}>
                                            <div className={`p-2 ${st.ItemCard}`}>
                                                <div className={`text-end`} >
                                                    <span type='button' data-bs-toggle="modal" data-bs-target="#deleteItemModal" onClick={() => { setItem(item); setReset(!reset) }}>
                                                        <DeleteOutlineOutlinedIcon className={st.icon} />
                                                    </span>
                                                    <span type="button" data-bs-toggle="modal" data-bs-target="#editItemModal" onClick={() => { setItem(item) }}>
                                                        <EditOutlinedIcon className={st.icon} />
                                                    </span>
                                                </div>
                                                <div className={`row ${st.filter}`}>
                                                    <div className={`col-sm-3 text-center`}>
                                                        <div>
                                                            <img src={item.photo && item.photo !== "" ? `${process.env.REACT_APP_SERVER_PUBLIC}${item.photo}` : "/images/dish.png"} className={`${st.ItemPhoto}`} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className={`col-sm-9 ${st.cardcontent}`}>
                                                        <div className={`row`}>
                                                            <div className="col-7 p-0 text-start">
                                                                <h5> {item.name} </h5>
                                                            </div>
                                                            <div className="col-5 p-0 m-0 text-end">
                                                                <h6 className='m-0 mt-1'>{item.price} DT</h6>
                                                            </div>
                                                        </div>
                                                        <div className={`row`}>
                                                            <div className={`col text-start small p-0 ${st.description}`} >
                                                                <span>{item.description}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>)}

                </div>
                :
                <div className="text-center m-5 ">Aucuns articles</div>
            }
        </>
    );
}

export default ItemsList;