import React, { useState, useRef, useEffect } from 'react';
import st from "./mailing.module.css";
import { getMailConfig, changeMailConfig } from '../../../../services/website';

function Mailing() {
    const initialValues = {
        auth: {
            user: "",
            password: "",
        },
        host: "",
        port: "",
        name: "",
        service: ""
    }
    const [mailConfig, setMailConfig] = useState();

    const [formValues, setFormValues] = useState(initialValues);
    const [logo, setLogo] = useState();

    const [showPass, setShowPass] = useState(false);

    const [loaded, setLoaded] = useState(false);
    const [reset, setReset] = useState(false);
    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const imgView = useRef(null);
    const inputFile = useRef(null);

    useEffect(() => {
        getMailConfig((cb) => {
            if (cb.status === 200) {
                setMailConfig(cb.data.config);
                setFormValues(cb.data.config);
                setLoaded(true);
            } else {
                setLoaded(true);
            }
        })
    }, [reset])

    const upImg = () => {
        setErr(false)
        if (inputFile.current.files.length > 0) {
            const imgSize = inputFile.current.files[0].size;
            imgSize > 2097152 && setErr("image");
            if (imgSize <= 2097152) {
                const imgLink = URL.createObjectURL(inputFile.current.files[0]);
                imgView.current.src = imgLink;
                setLogo(inputFile.current.files[0]);
            }
        }
    }
    const dragOver = (e) => {
        e.preventDefault();
    }
    const drop = (e) => {
        e.preventDefault();
        inputFile.current.files = e.dataTransfer.files;
        upImg();
    }

    const handleChange = (e) => {
        setErr(false)
        const { name, value } = e.target;
        if (name !== "password" && name !== "email") {
            setFormValues({ ...formValues, [name]: value });
        } else if (name === "password") {
            setFormValues({ ...formValues, auth: { ...formValues.auth, password: value } });
        } else if (name === "email") {
            setFormValues({ ...formValues, auth: { ...formValues.auth, user: value } });
        }
    }

    const submit = (e) => {
        e.preventDefault();
        let f = formValues;
        if (!f.name || !f.host || !f.service || !f.port || !f.auth.user || !f.auth.password) {
            setErr("required");
            return;
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(formValues.auth.user)) {
            setErr("email");
            return;
        }
        const formData = new FormData();
        for (const key in formValues) {
            if (typeof formValues[key] === 'object') {
                for (const subKey in formValues[key]) {
                    formData.append(`${key}.${subKey}`, formValues[key][subKey]);
                }
            } else {
                formData.append(key, formValues[key]);
            }
        }
        if (logo instanceof File) {
            formData.append("logo", logo);
        }
        for (const pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }
        changeMailConfig( formData, (cb) => {
            setLoading(true);
            if (cb.status === 200) {
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
                setTimeout(() => {
                    setSuccess(true);
                }, 2000);
                setTimeout(() => {
                    setSuccess(false);
                }, 4000);
            } else {
                setTimeout(() => {
                    setLoading(false)
                }, 2000);
                setErr("server");
            }
        })
    }


    return (
        <div className="container-fluid p-4">
            <div className="row">
                <div className="col ps-5">
                    <h3>Configuration E-mail</h3>
                </div>
            </div>
            {loaded ? (
                <div className="row">
                    <div className="col-xl-3">
                        <div className={`card mt-3 ps-3 pe-3 ${st.logoCard}`}
                            onDragOver={dragOver}
                            onDrop={drop}>
                            <div className="row text-center mt-4">
                                <h5>Logo de courriel</h5>
                            </div>
                            <div className="row pt-2 pb-3">
                                <div className="text-center">
                                    <input ref={inputFile} type="file" accept="image/*" onChange={upImg} hidden />
                                    <img ref={imgView} id="img-view" alt="avatar" className={`object-fit-cover ${st.img}`}
                                        src={mailConfig && mailConfig.logo ?
                                            `${process.env.REACT_APP_SERVER_PUBLIC}${mailConfig.logo}`
                                            : "/images/uploadLogo.png"}
                                        onClick={() => inputFile.current.click()}
                                    />
                                </div>
                            </div>
                            {err === "image" && (
                                <div className="row text-center mb-2">
                                    <span className='txtDanger'>La taille d'image doit être inferieur à 2048 ko</span>
                                </div>
                            )}
                            <div className="row mb-5 text-center">
                                <h6>
                                    Faites glisser et déposez ou
                                    <span className={`${st.upload}`}
                                        onClick={() => inputFile.current.click()}> cliquez ici </span>
                                    pour télécharger l'image
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9">
                        <div className={`card mt-3 ${st.infoCard}`}>
                            <div className={`row ps-5 pt-5 ${st.infoCardTitle}`}>
                                <h5>Configurations :</h5>
                            </div>
                            <div className="ps-5 pe-5">
                                <div className="row mt-4">
                                    <div className="col-lg-6">
                                        <div className={`form-group`}>
                                            <label className='form-label'>Nom<span className="text-danger">*</span></label>
                                            <input type="text" name='name'
                                                className={`form-control ${err && formValues.name === "" && "is-invalid"}`}
                                                value={formValues.name} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className={`form-group`}>
                                            <label className='form-label'>Hôte<span className="text-danger">*</span></label>
                                            <input type="text" name='host'
                                                className={`form-control ${err && formValues.host === "" && "is-invalid"}`}
                                                value={formValues.host} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-6">
                                        <div className={`form-group`}>
                                            <label className='form-label'>Port<span className="text-danger">*</span></label>
                                            <input type="number" name='port'
                                                className={`form-control ${err && formValues.port === "" && "is-invalid"}`}
                                                value={formValues.port} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className={`form-group`}>
                                            <label className='form-label'>Service<span className="text-danger">*</span></label>
                                            <input type="text" name='service'
                                                className={`form-control ${err && formValues.service === "" && "is-invalid"}`}
                                                value={formValues.service} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2 mb-2">
                                    <div className="col-lg-6">
                                        <div className={`form-group`}>
                                            <label className='form-label'>Adresse e-mail<span className="text-danger">*</span></label>
                                            <input type="text" name='email'
                                                className={`form-control ${err && formValues.auth.user === "" && "is-invalid"}`}
                                                value={formValues.auth.user} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group position-relative">
                                            <label className="form-label">Mot de passe<span className="text-danger">*</span></label>
                                            <div className={`${st.passwordToggle}`}>
                                                <input type={showPass ? "text" : "password"} value={formValues.auth.password}
                                                    onChange={handleChange} name='password'
                                                    className={`form-control ${err && formValues.auth.password === "" && "is-invalid"}`} />
                                                <div className={`${st.passwordToggleIcon}`}>
                                                    <span className={showPass ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => setShowPass(!showPass)} ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {loading && (
                                    <div className="d-flex justify-content-center mt-2 mb-2">
                                        <div className={`${st.loading}`}></div>
                                    </div>)}
                                {success && (
                                    <div className="d-flex justify-content-center mt-1 mb-2">
                                        <span className={`txtSuccess`}>Configuration enregistrée</span>
                                    </div>)}
                                {err && (
                                    <div className="d-flex justify-content-center mt-1 mb-2">
                                        <span className={`txtDanger`}>
                                            {err === "email" && "Format d'adresse e-mai lest invalide"}
                                            {err === "required" && "Verifiez les champs obligatoires"}
                                            {err === "server" && "Un problem est survenue, réessayez plus tard."}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="row mt-2 mb-4">
                                <div className="col text-center">
                                    <button className={`${st.resetBtn}`} onClick={() => { setReset(!reset); setErr(false) }}>Réinitialiser</button>
                                    <button className={`${st.save}`} onClick={submit} >Enregistrer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <div className={`${st.loadingBox}`}>
                    <span className={`${st.loader}`} ></span>
                </div>
            )}</div>


    );
}


export default Mailing;

