import { useEffect, useRef, useState } from "react";
import Pagination from "../../pagination/pagination";
import st from "./inbox.module.css"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';

import { deleteContacts, getContacts } from "../../../services/contact";
import InspectMessage from "./inspectMessage/inspectMessage";
import io from "socket.io-client";

function Inbox(props) {
    const { setNotifications, messageNotify } = props;
    const [newMessage, setNewMessage] = useState(null);
    useEffect(() => {
        const socket = io.connect(process.env.REACT_APP_SOCKET_URL);
        socket.on("receiveMessage", (data) => {
            if (data.message) {
                setNewMessage(data.message);
                messageNotify();
            }
        })
        return () => {
            socket.off("receiveMessage");
        };
    }, [messageNotify])

    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 8;

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState('recent');

    const [messages, setMessages] = useState([]);
    const [reset, setReset] = useState(false);
    const [refresh] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState({});
    const [messagesToPaginate, setMessagesToPaginate] = useState([]);
    const oldMessagesToPaginate = useRef(null);

    const [messagesToDelete, setMessagesToDelete] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getContacts((cb) => {
            if (cb.status === 200) {
                setMessagesToPaginate(cb.data.contacts);
                oldMessagesToPaginate.current = cb.data.contacts;
                setNotifications(pv => pv = {
                    ...pv,
                    message: cb.data.contacts
                });
                if (search === "") {
                    let filtered;
                    setMessagesToPaginate(cb.data.contacts);
                    filtered = cb.data.contacts;
                    sort === "recent" && setMessagesToPaginate(filtered)
                    sort === "old" && setMessagesToPaginate([...filtered].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
                }
                else {
                    setMessagesToPaginate(cb.data.contacts.filter((request) => {
                        for (let key in request) {
                            if (typeof request[key] === 'string' && request[key].toLowerCase().includes(search.toLowerCase())) {
                                return true;
                            }
                        }
                        return false;
                    }));
                }
            }
        })
        setItemOffset(0)
        setPageCount(0)
    }, [search, refresh, sort, setNotifications]);

    useEffect(() => {
        if (newMessage) {
            if (!oldMessagesToPaginate.current.includes(newMessage)) {
                setMessagesToPaginate([...messagesToPaginate, newMessage]);
                oldMessagesToPaginate.current = [...messagesToPaginate, newMessage];
                setNotifications(pv => pv = {
                    ...pv,
                    message: [...pv.message, newMessage]
                })
                setNewMessage(null);
            }
        }
    }, [newMessage, setNotifications, messagesToPaginate])


    const selectAll = () => {
        if (selectAllChecked) {
            setMessagesToDelete([]);
        } else {
            const all = messagesToPaginate.map(msg => msg._id);
            setMessagesToDelete(all)
        }
        setSelectAllChecked(!selectAllChecked);

    }

    const checkMessage = (id) => {
        if (messagesToDelete.includes(id)) {
            setMessagesToDelete(messagesToDelete.filter(m => m !== id));
        } else {
            setMessagesToDelete([...messagesToDelete, id])
        }
    }

    const removeMessages = () => {
        const data = { contacts: messagesToDelete }
        setLoading(true);
        deleteContacts(data, (cb) => {
            if (cb.status === 200) {
                setMessagesToPaginate(messagesToPaginate.filter(msg => !messagesToDelete.includes(msg._id)));
                setNotifications(pv => pv = {
                    ...pv,
                    message: messagesToPaginate.filter(msg => !messagesToDelete.includes(msg._id))
                })
                setTimeout(() => {
                    setLoading(false);
                }, 500);
                setMessagesToDelete([]);
                setSelectAllChecked(false);
            }
        })
    }

    return (
        <>
            <InspectMessage message={selectedMessage} />
            <div className="contianer-fluid p-4">
                <div className="row">
                    <div className="col-lg-5 ps-5">
                        <h3>Boîte de réception</h3>
                    </div>
                    <div className="col-lg-7 mb-2">
                        <div className={`${st.inputGroup}`}>
                            <form autoComplete='off' >
                                <input onChange={(event) => { setSearch(event.target.value) }} value={search} autoComplete='false'
                                    type="text" placeholder="Rechercher un message" className={`border-0 bg-light ${st.formControl}`} />
                                <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row mb-5 mt-1">
                    <div className="col position-relative">
                        <div className={`${st.sortBox}`}>
                            <span className={`btn btn-dark dropdown-toggle ${st.sort}`} data-bs-toggle="dropdown" aria-expanded="false">Sort</span>
                            <ul className="dropdown-menu">
                                <li><span className={`dropdown-item ${st.sortings} ${sort === 'recent' ? "active" : ""}`} onClick={() => { setSort('recent') }}>Récents</span></li>
                                <li><span className={`dropdown-item ${st.sortings} ${sort === 'old' ? "active" : ""}`} onClick={() => { setSort('old') }}>Anciens </span></li>
                            </ul>
                        </div>
                        <div className={`${st.loaderBox}`}>
                            {loading && <div className={`${st.loader}`}></div>}
                        </div>
                        <div className={`${st.deleteBox}`}>
                            <button className="btn btn-dark" onClick={removeMessages}><DeleteOutlineIcon /> ( {messagesToDelete && messagesToDelete.length} ) </button>
                        </div>
                    </div>
                </div>
                <div className={`row ${st.tableBox}`}>
                    <div className={`col`} style={{ overflow: "auto", marginBottom: "10px" }}>
                        {messages && messages.length > 0 ?
                            <table className={`table`}>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: "20px", maxWidth: "20px" }}>
                                            <div className={`form-check`}>
                                                <input className={`form-check-input ${st.checkBox}`} checked={selectAllChecked}
                                                    onChange={selectAll}
                                                    type="checkbox" id="confirm" />
                                            </div>
                                        </th>
                                        <th style={{ minWidth: "100px" }}>Nom </th>
                                        <th style={{ minWidth: "100px" }}>Prénom </th>
                                        <th style={{ minWidth: "0px" }}>Téléphone </th>
                                        <th style={{ minWidth: "0px" }}>Adresse email </th>
                                        <th style={{ minWidth: "98px" }}>Soumise le</th>
                                        <th style={{ minWidth: "96px" }}>Message </th>
                                        <th style={{ minWidth: "3px", textAlign: "center" }}>Actions </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {messages.map((msg, key) =>
                                        <tr key={key}>
                                            <td style={{ minWidth: "40px", maxWidth: "40px", paddingTop: "16px" }}>
                                                <div className={`form-check`}>
                                                    <input className={`form-check-input ${st.checkBox}`} onChange={() => { checkMessage(msg._id) }}
                                                        type="checkbox" id={`checkbox_${key}`}
                                                        checked={messagesToDelete.includes(msg._id)} />
                                                </div>
                                            </td>
                                            <td style={{ paddingTop: "15px" }}>{msg.lastname}</td>
                                            <td style={{ paddingTop: "15px", minWidth: "138px" }}>{msg.firstname}</td>
                                            <td style={{ paddingTop: "15px" }}>{msg.phone}</td>
                                            <td style={{ paddingTop: "15px" }}>{msg.email}</td>
                                            <td style={{ paddingTop: "15px" }}>{msg.createdAt.slice(0, 10)}</td>
                                            <td style={{ paddingTop: "15px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px" }}>{msg.message}</td>
                                            <td style={{ textAlign: "center", minWidth: "120px" }}>
                                                <button onClick={() => { setSelectedMessage(msg); setReset(!reset) }}
                                                    data-bs-toggle="modal" data-bs-target="#inspectMessage"
                                                    className={`btn ${st.AddBusinessIcon}`}>
                                                    <SearchIcon />
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            : ""}

                    </div>
                    <Pagination pageCount={pageCount} setPageCount={setPageCount} itemsPerPage={itemsPerPage} itemOffset={itemOffset} setItemOffset={setItemOffset} items={messagesToPaginate} setCurrentItems={setMessages} />
                </div>
            </div>
        </>
    );
}

export default Inbox;