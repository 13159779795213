import st from "./categoryList.module.css";
import Edit from '@mui/icons-material/Edit';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from "react";
import { addCategory, updateCategory } from "../../../../services/menuApi";
//import { sortCategories } from "../../../../services/menuApi";
import DeleteCategory from "../deleteCategory/deleteCategory";

function CategoryList(props) {
    const { setMenus, setRefresh, activeMenu, activeCategory, setActiveCategory, edit, setEdit, categories, setCategories, setRefreshItems } = props;

    const [search, setSearch] = useState('');
    const [name, setName] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(false);
    const [feedBack, setFeedBack] = useState(false);
    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);



    useEffect(() => {
        if (search === "") {
            setCategories(activeMenu && activeMenu.categories);
            setActiveCategory(prv => activeMenu.categories.length > 0 ? activeMenu.categories[0] : prv);
        } else {
            const searching = activeMenu.categories.filter(
                cat => cat.name.toLowerCase().includes(search.toLowerCase())
            );
            setCategories(searching);
            setActiveCategory(prv => searching.length > 0 ? searching[0] : prv);
        }
    }, [activeMenu, search, setActiveCategory, setCategories]);


    useEffect(() => {
        setTimeout(() => {
            setErr(false);
            setFeedBack(false);
            setSuccess(false);
        }, 2000);
    }, [feedBack, success, err]);

    const handleNameChange = (e) => {
        setName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    }

    const createCategory = () => {
        setErr(false);
        setFeedBack(false);
        setSuccess(false);
        const data = {
            category: { name: name },
            menu: activeMenu._id
        }
        if (name) {
            addCategory(data, (cb) => {
                if (cb.status === 200) {
                    setCategories([...categories, cb.data.newCategory])
                    setActiveCategory(cb.data.newCategory)
                    setSuccess(true);
                    setFeedBack('Catégorie est créée avec succès');
                    setName('');
                    setRefresh(r => !r);
                    return
                } else {
                    setErr(true);
                    setFeedBack("Un probleme est survenue, réessayer plus tard!")
                    console.error(cb.response);
                }
            })
        } else {
            setErr(true);
            setFeedBack("Veuillez enter un nom!")
        }
    }

    const handleCategoryName = (value, index) => {
        categories.map((cat, key) => cat._id === index ? cat.name = value.charAt(0).toUpperCase() + value.slice(1) : cat)
        setMenus(prevMenus => prevMenus.map(menu => menu._id === activeMenu._id ?
            {
                ...menu,
                categories: menu.categories.map(cat =>
                    cat._id === edit ? { ...cat, name: value.charAt(0).toUpperCase() + value.slice(1) } : cat
                )
            }
            : menu
        )
        );
    }

    const saveCategory = () => {
        setErr(false);
        setFeedBack(false);
        setSuccess(false);
        let category;
        categories.map(cat => cat._id === edit ? category = cat : cat);
        const data = {
            menu: activeMenu._id,
            category: category
        }
        if (data.category.name === "") {
            setErr(true);
            setFeedBack("Veulliez entrer un nom!");
            return;
        }
        updateCategory(data, (cb) => {
            if (cb.status === 200) {
                setEdit(null);
                setSuccess(true);
                setFeedBack('Le nom de catégorie est changé avec succès');
                return;
            } else {
                setErr(true);
                setFeedBack("Un probleme est survenue, réessayer plus tard!")
            }
        })
    }

    // const dragCategory = useRef(null);
    // const draggedOverCategory = useRef(null);

    // const handleSort = () => {
    //     // const categoriesClone = [...categories];
    //     // const temp = categoriesClone[dragCategory.current];
    //     // categoriesClone[dragCategory.current] = categoriesClone[draggedOverCategory.current];
    //     // categoriesClone[draggedOverCategory.current] = temp;
    //     // setCategories(categoriesClone);
    //     // let currentMenu
    //     // menus.map((menu) => menu._id === activeMenu._id ? currentMenu = menu : menu);
    //     // currentMenu.categories = categoriesClone;

    //     // const data = {
    //     //     menu: activeMenu._id,
    //     //     categories: categoriesClone
    //     // }
    //     // console.log(data);
    //     // // sortCategories(data, (cb) => {
    //     // //     if (cb.status === 200) {
    //     // //         setMenus(menus.map(
    //     // //             (menu) => menu._id === currentMenu._id
    //     // //                 ? menu = currentMenu
    //     // //                 : menu
    //     // //         ));
    //     // //         setRefresh(r => !r);
    //     // //     } else {
    //     // //         console.error(cb);
    //     // //     }

    //     // // })
    // };

    return (
        <div>
            <DeleteCategory
                setMenus={setMenus} menuId={activeMenu._id}
                selectedCategory={selectedCategory}
                activeCategory={activeCategory} setActiveCategory={setActiveCategory}
                categories={categories} setCategories={setCategories}
                setErr={setErr}
                setSuccess={setSuccess}
                setFeedBack={setFeedBack}
                setRefreshItems={setRefreshItems}
            />
            <div className="row">
                <div className="col text-center" >
                    <h4 className='mt-4 ms-1'>Catégories de {activeMenu.name}</h4>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    <div className={`${st.inputGroup}`}>
                        <input onChange={(event) => { setSearch(event.target.value) }} value={search} autoComplete="off"
                            type="text" placeholder="Rechercher" className={`border-0 bg-light ${st.formControl}`} />
                        <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <div className={`${st.addCategory}`}>
                        <input value={name} onChange={handleNameChange} onKeyUp={(e) => e.key === 'Enter' && createCategory()}
                            placeholder='Ajouter une categorie' className={`form-control ${st.categoryControl}`} type="text" />
                        <i className={`${st.saveCategory}`}>
                            <Tooltip title="Enregistrer" arrow>
                                <Done onClick={createCategory} />
                            </Tooltip>
                        </i>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col mt-2 text-center">
                    {err && <span className='txtDanger' >{feedBack}</span>}
                    {success && <span className='txtSuccess' >{feedBack}</span>}
                </div>
            </div>
            <hr />

            <div className="row d-flex justify-content-center mt-3">
                <div className={`col`}>
                    <div className={`${categories.length > 2 ? st.categoriesBox : categories.length === 0 ? st.emptyBox : st.minimizedcategoriesBox}`}>
                        {
                            categories.length > 0 && categories.map((cat, key) => (
                                <div
                                    key={key} onClick={() => setActiveCategory(cat)}
                                    // draggable
                                    // onDragStart={() => (dragCategory.current = key)}
                                    // onDragEnter={() => (draggedOverCategory.current = key)}
                                    // onDragEnd={handleSort}
                                    onDragOver={(e) => e.preventDefault()}
                                    className={`${st.cardList} ${activeCategory._id === cat._id && st.selectedcardList} card`} >
                                    <div className="row">
                                        <div className="col relative">
                                            {edit === cat._id ? (
                                                <div>
                                                    <div className={`${st.nameBox}`}>
                                                        <input type="text" className={`form-control ${st.categoryNameControl}`}
                                                            onClick={(e) => e.stopPropagation()}
                                                            onKeyUp={(e) => e.key === 'Enter' && saveCategory()}
                                                            onChange={(e) => handleCategoryName(e.target.value, cat._id)} value={cat.name}
                                                        />
                                                        <Tooltip title="Enregistrer" arrow>
                                                            <Done className={`${st.saveCategory}`} onClick={(e) => { saveCategory(); e.stopPropagation(); }} />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            ) : (
                                                <span >
                                                    {cat.name}
                                                    <span onClick={(e) => { setEdit(cat._id); e.stopPropagation() }}>
                                                        <Tooltip title="Modifer le nom de category" arrow>
                                                            <Edit className={`${st.editCategory}`} />
                                                        </Tooltip>
                                                    </span>
                                                </span>
                                            )}
                                            <Tooltip title="Supprimer" arrow>
                                                <Close data-bs-toggle="modal" data-bs-target={"#deleteCategoryModal"}
                                                    className={`${st.deleteCategory}`}
                                                    onClick={(e) => { e.stopPropagation(); setSelectedCategory(cat); }}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>

        </div >
        //<Categories />
    );
}

export default CategoryList;