import st from "./aboutus.module.css";
import AppNavbar from '../../components/navigation/app-navigation/app-navbar';
import Footer from '../../components/footer/footer';

function AboutUs(props) {
    const { user, website } = props;
    const isAdmin = user.role === "admin";
    const isProvider = user.role === "provider";
    const isUser = user.role === "user";

    return (
        <>
            {!isAdmin && !isProvider && !isUser && <AppNavbar website={website} />}
            <div className={`${isProvider && "clientPage"} ${!isAdmin && !isProvider && st.page}`}>
                <div className={`${st.body}`}>
                    <div style={{ backgroundColor: "#2A2A2A" }} className="position-relative">
                        <img fetchpriority="high" src={`${process.env.REACT_APP_SERVER_PUBLIC}${website.aboutusBackground}`} alt=""
                            className={`${st.pageTitleImage} object-fit-cover`} />
                        <div className={`${st.pageTitleBar} container-lg position-relative`}>
                            <span className={`${st.pageTitle}`}>À Propos</span>
                        </div>
                    </div>
                    <div className="container-lg my-5 p-4">
                        <div className="row mb-5">
                            <div className="col-lg-6 mb-5  d-flex justify-content-center">
                                <div className={`${st.aboutUsIconBox}`} >
                                    <b className={`bi bi-graph-up-arrow ${st.chartIcon}`}></b>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={`${st.aboutUsContent}`}>
                                    <span><i>Qui sommes nous ?</i></span>
                                    <h3 className="mt-2">Notre Entreprise</h3>
                                    <p className={`mt-5 ${st.text}`}>Nous appartenons à l’agence <strong>{website && website.powered}</strong> dont les compétences dans l’univers
                                        digital évoluent parallèlement aux nouvelles technologies.
                                    </p>
                                    <p className={`mt-5 ${st.text}`}>
                                        Notre mission c’est de vous faire bénéficier des meilleures solutions innovantes
                                        centrées sur l’expérience utilisateur. Transparence, confiance, créativité et engagement
                                        sont nos atouts pour booster votre activité.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col text-center">
                                <h1>{website && website.name}.com</h1>
                                <p>Cette solution innovante se démarque comme la technologie la plus tendance
                                    dans l’avenir digital de la restauration
                                </p>
                            </div>
                        </div>
                        <div className="row mt-4 mb-5">
                            <div className="col-md-4 mb-4 ">
                                <img className={`${st.cardsImg}`} src="/images/restRepo.jpg" alt="" />
                                <div className={`${st.cardsContent} text-center text-md-start mt-3`}>
                                    <h5 className={`${st.cardsContentTitle}`}>Répertoire de restaurants</h5>
                                    <i style={{ textAlign: "justify !important" }} >{website && website.name} est un site de répertoire de restaurants en ligne qui
                                        propose une liste complète de restaurants de qualité dans différentes régions.
                                    </i>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <img className={`${st.cardsImg}`} src="/images/sharedMenus.jpg" alt="" />
                                <div className={`${st.cardsContent} text-center text-md-start mt-3`}>
                                    <h5 className={`${st.cardsContentTitle}`}>Menu Partagé</h5>
                                    <i style={{ textAlign: "justify !important" }} >
                                        Avec {website && website.name}, vous pouvez facilement rechercher les meilleurs
                                        restaurants dans votre région en fonction de vos préférences de cuisine, de prix,
                                        de localisation et plus encore.
                                    </i>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <img className={`${st.cardsImg}`} src="/images/news.jpg" alt="" />
                                <div className={`${st.cardsContent} text-center text-md-start mt-3`}>
                                    <h5 className={`${st.cardsContentTitle}`}>Répertoire de restaurants</h5>
                                    <i style={{ textAlign: "justify !important" }} >
                                        Nous travaillons avec les meilleurs restaurants pour vous offrir des menus mis à jour
                                        régulièrement, des avis clients et des photos de qualité pour vous aider à trouver
                                        le restaurant parfait pour vous.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 mt-5">
                                <hr className={st.hr} />
                            </div>
                            <div className="col-md-10 mt-5">
                                <span className="text-muted" style={{ textTransform: "uppercase" }}>{website && website.name} VOTRE SOLUTION GAGNANTE</span>
                                <h1 className={`${st.solutionTitle}`}>Brillez dans l'univers de la restauration.</h1>
                                <p className={`text-muted ${st.text}`}>
                                    Avoir un menu en ligne est essentiel dans le contexte actuel où de plus
                                    en plus de clients recherchent des informations sur les restaurants
                                    en ligne. Cela permet de répondre à leurs attentes et de rester
                                    compétitif sur le marché. Un menu en ligne offre une accessibilité
                                    24 heures sur 24, 7 jours sur 7, permettant aux clients de consulter
                                    les plats  à tout moment. Il simplifie également la gestion des
                                    commandes pour les restaurants, réduisant les erreurs de communication
                                    et améliorant l’efficacité du service.
                                    Enfin, un menu en ligne facilite les mises à jour fréquentes,
                                    que ce soit pour les changements de menu, les promotions ou
                                    les ajustements en fonction des demandes des clients.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer website={website} />
            </div >
        </>
    );
}

export default AboutUs;