import React, { useState, useRef, useEffect } from 'react';
import st from "./websiteInformations.module.css";
import { getWebsiteInfos, setWebsiteInfos } from '../../../../services/website';
import Backgrounds from './backgrounds/background';

function WebsiteInformations(props) {
    const { website, refresh, setRefresh } = props;
    const initialValues = {
        socials: {
            facebook: "https://www.facebook.com",
            instagram: "https://www.instagram.com",
            pinterest: "https://www.pinterest.com",
            x: "https://www.x.com",
            youtube: "https://www.youtube.com",
        },
        powered: "",
        phone: "",
        name: "",
        email: ""
    }

    const [formValues, setFormValues] = useState(initialValues);
    const [logo, setLogo] = useState();

    const [reset, setReset] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const imgView = useRef(null);
    const inputFile = useRef(null);
    useEffect(() => {
        getWebsiteInfos((cb) => {
            if (cb.status === 200) {
                let infos = cb.data.infos
                setFormValues({
                    socials: {
                        facebook: infos && infos.socials.facebook ? infos.socials.facebook : "https://www.facebook.com",
                        instagram: infos && infos.socials.instagram ? infos.socials.instagram : "https://www.instagram.com",
                        pinterest: infos && infos.socials.pinterest ? infos.socials.pinterest : "https://www.pinterest.com",
                        x: infos && infos.socials.x ? infos.socials.x : "https://www.x.com",
                        youtube: infos && infos.socials.youtube ? infos.socials.youtube : "https://www.youtube.com",
                    },
                    powered: infos && infos.powered ? infos.powered : "",
                    phone: infos && infos.phone ? infos.phone : "",
                    name: infos && infos.name ? infos.name : "",
                    email: infos && infos.email ? infos.email : "",
                });
                setLoaded(true);
            } else {
                setLoaded(true);
            }
        })
    }, [reset])

    const upImg = () => {
        setErr(false)
        if (inputFile.current.files.length > 0) {
            const imgSize = inputFile.current.files[0].size;
            imgSize > 2097152 && setErr("image");
            if (imgSize <= 2097152) {
                const imgLink = URL.createObjectURL(inputFile.current.files[0]);
                imgView.current.src = imgLink;
                setLogo(inputFile.current.files[0]);
            }
        }
    }
    const dragOver = (e) => {
        e.preventDefault();
    }
    const drop = (e) => {
        e.preventDefault();
        inputFile.current.files = e.dataTransfer.files;
        upImg();
    }

    const handleChange = (e) => {
        setErr(false)
        const { name, value } = e.target;
        if (name !== ("facebook" || "instagram" || "youtube" || "x")) {
            setFormValues({ ...formValues, [name]: value });
        }
        name === "facebook" && setFormValues({ ...formValues, socials: { ...formValues.socials, facebook: value } });
        name === "instagram" && setFormValues({ ...formValues, socials: { ...formValues.socials, instagram: value } });
        name === "youtube" && setFormValues({ ...formValues, socials: { ...formValues.socials, youtube: value } });
        name === "x" && setFormValues({ ...formValues, socials: { ...formValues.socials, x: value } });
    }
    const submit = (e) => {
        e.preventDefault();
        let f = formValues;
        if (!f.name || !f.powered || !f.email || !f.phone) {
            setErr("required");
            return;
        }

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const urlPattern = /^(?:https?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/;

        if (!emailPattern.test(formValues.email)) {
            setErr("email");
            return;
        }
        if (!urlPattern.test(formValues.socials.facebook)) {
            setErr("facebook");
            return;
        }
        if (!urlPattern.test(formValues.socials.instagram)) {
            setErr("instagram");
            return;
        }
        if (!urlPattern.test(formValues.socials.youtube)) {
            setErr("youtube");
            return;
        }
        if (!urlPattern.test(formValues.socials.x)) {
            setErr("x");
            return;
        }

        const formData = new FormData();
        for (const key in formValues) {
            if (typeof formValues[key] === 'object') {
                for (const subKey in formValues[key]) {
                    formData.append(`${key}.${subKey}`, formValues[key][subKey]);
                }
            } else {
                formData.append(key, formValues[key]);
            }
        }
        if (logo instanceof File) {
            formData.append("logo", logo);
        }
        // for (const pair of formData.entries()) {
        //     console.log(pair[0], pair[1]);
        // }
        setWebsiteInfos(formData, (cb) => {
            setLoading(true);
            if (cb.status === 200) {
                setTimeout(() => {
                    setLoading(false);
                    setRefresh(!refresh);
                }, 2000);
                setTimeout(() => {
                    setSuccess(true);
                }, 2000);
                setTimeout(() => {
                    setSuccess(false);
                }, 4000);
            } else {
                setTimeout(() => {
                    setLoading(false)
                }, 2000);
                setErr("server");
            }
        })
    }



    return (
        <div className="container-fluid p-4">
            <div className="row">
                <div className="col ps-5">
                    <h3>Informations de site web</h3>
                </div>
            </div>
            {loaded ? (
                <>
                    <div className="row">
                        <div className="col-xl-3">
                            <div className={`card mt-3 px-3 ${st.logoCard}`}
                                onDragOver={dragOver}
                                onDrop={drop}>
                                <div className="row text-center mt-4">
                                    <h5>Logo du site</h5>
                                </div>
                                <div className="row pt-2 pb-3">
                                    <div className="text-center">
                                        <input ref={inputFile} type="file" accept="image/*" onChange={upImg} hidden />
                                        <img ref={imgView} id="img-view" alt="avatar" className={`object-fit-cover ${st.img}`}
                                            src={website && website.logo ?
                                                `${process.env.REACT_APP_SERVER_PUBLIC}${website.logo}`
                                                : "/images/uploadLogo.png"}
                                            onClick={() => inputFile.current.click()}
                                        />
                                    </div>
                                </div>
                                {err === "image" && (
                                    <div className="row text-center mb-2">
                                        <span className='txtDanger'>La taille d'image doit être inferieur à 2048 ko</span>
                                    </div>
                                )}
                                <div className="row mb-5 text-center">
                                    <h6>
                                        Faites glisser et déposez ou
                                        <span className={`${st.upload}`}
                                            onClick={() => inputFile.current.click()}> cliquez ici </span>
                                        pour télécharger l'image
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9">
                            <div className={`card mt-3 ${st.infoCard}`}>
                                <div className={`row ps-5 pt-5 ${st.infoCardTitle}`}>
                                    <h5>Configurations :</h5>
                                </div>
                                <div className="ps-5 pe-5">
                                    <div className="row mt-4">
                                        <div className="col-lg-6">
                                            <div className={`form-group`}>
                                                <label className='form-label'>Nom<span className="text-danger">*</span></label>
                                                <input type="text" name='name'
                                                    className={`form-control ${err && formValues.name === "" && "is-invalid"}`}
                                                    value={formValues.name} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className={`form-group`}>
                                                <label className='form-label'>Adresse e-mail<span className="text-danger">*</span></label>
                                                <input type="email" name='email'
                                                    className={`form-control ${err === "email" && formValues.email === "" && "is-invalid"}`}
                                                    value={formValues.email} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-6">
                                            <div className={`form-group`}>
                                                <label className='form-label'>Téléphone<span className="text-danger">*</span></label>
                                                <input type="number" name='phone'
                                                    className={`form-control ${err && formValues.phone === "" && "is-invalid"}`}
                                                    value={formValues.phone} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className={`form-group`}>
                                                <label className='form-label'>Alimenté par<span className="text-danger">*</span></label>
                                                <input type="text" name='powered'
                                                    className={`form-control ${err && formValues.powered === "" && "is-invalid"}`}
                                                    value={formValues.powered} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-6">
                                            <div className={`form-group`}>
                                                <label className='form-label'>Facebook URL</label>
                                                <input type="text" name='facebook'
                                                    className={`form-control ${err === 'facebook' && "is-invalid"}`}
                                                    value={formValues.socials.facebook} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className={`form-group`}>
                                                <label className='form-label'>Instagram URL</label>
                                                <input type="text" name='instagram'
                                                    className={`form-control ${err === 'instagram' && "is-invalid"}`}
                                                    value={formValues.socials.instagram} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-lg-6">
                                            <div className={`form-group`}>
                                                <label className='form-label'>Youtube URL</label>
                                                <input type="text" name='youtube'
                                                    className={`form-control ${err === 'youtube' && "is-invalid"}`}
                                                    value={formValues.socials.youtube} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className={`form-group`}>
                                                <label className='form-label'>X URL</label>
                                                <input type="text" name='x'
                                                    className={`form-control ${err === 'x' && "is-invalid"}`}
                                                    value={formValues.socials.x} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {loading && (
                                        <div className="d-flex justify-content-center mt-2 mb-2">
                                            <div className={`${st.loading}`}></div>
                                        </div>)}
                                    {success && (
                                        <div className="d-flex justify-content-center mt-1 mb-2">
                                            <span className={`txtSuccess`}>Configuration enregistrée</span>
                                        </div>)}
                                    {err && (
                                        <div className="d-flex justify-content-center mt-1 mb-2">
                                            <span className={`txtDanger`}>
                                                {err === "instagram" && "Format du URL de Instagram est invalide"}
                                                {err === "facebook" && "Format du URL de Facebook est invalide"}
                                                {err === "x" && "Format du URL de X est invalide"}
                                                {err === "youtube" && "Format du URL de Youtube est invalide"}
                                                {err === "required" && "Verifiez les champs obligatoires"}
                                                {err === "email" && "Format d'adresse e-mail est invalide"}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="row mt-2 mb-4">
                                    <div className="col text-center">
                                        <button className={`${st.resetBtn}`} onClick={() => { setReset(!reset); setErr(false) }}>Réinitialiser</button>
                                        <button className={`${st.save}`} onClick={submit}>Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mt-4 px-5">
                            <h3>Arrière-plans de site web</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`${st.backgroundsCols} col-lg-3`}>
                            <Backgrounds title='Authentification' website={website} role='auth' />
                        </div>
                        <div className={`${st.backgroundsCols} col-lg-3`}>
                            <Backgrounds title='Accueil' website={website} role='home' />
                        </div>
                        <div className={`${st.backgroundsCols} col-lg-3`}>
                            <Backgrounds title='À propos' website={website} role='aboutus' />
                        </div>
                        <div className={`${st.backgroundsCols} col-lg-3`}>
                            <Backgrounds title='Contact' website={website} role='contact' />
                        </div>
                    </div>
                </>


            ) : (
                <div className={`${st.loadingBox}`}>
                    <span className={`${st.loader}`} ></span>
                </div>
            )}</div>


    );
}

export default WebsiteInformations;