import { useEffect, useState, useRef } from "react";
import st from "./treatProvider.module.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { declineProvider, inviteProvider } from "../../../services/mailApi";
import { updateStatus } from "../../../services/adminApis";

function TreatProvider(props) {
    const { request, reset, refresh, setRefresh } = props;
    const [listDisplay, setListDisplay] = useState(false);
    const [text, setText] = useState('');
    const [extra, setExtra] = useState(false)
    const [msgValue, setMsgValue] = useState('')
    const [membership, setMembership] = useState('')
    const [err, setErr] = useState(false)
    const [loading, setLoading] = useState(false)
    const modalClose = useRef(null);

    useEffect(() => {
        if (request && request.status) {
            request.status === 'notTreated' && setText('Non traitée')
            request.status === 'held' && setText('En attente')
            request.status === 'treated' && setText('Acceptée')
            request.status === 'denied' && setText('Refusée')
        }
        setExtra(false);
        setMembership('')
    }, [request, reset])

    const changeStatus = () => {
        if (text === 'Acceptée') {
            if (membership === '') {
                setErr('req');
                return;
            }
            const acceptMail = { ...request, expiresAt: membership };
            setLoading(true);
            inviteProvider(acceptMail, (cb) => {
                if (cb.status === 200) {
                    updateStatus(request._id, { status: 'treated' }, (cb) => {
                        if (cb.status === 200) {
                            setTimeout(() => { setLoading(false) }, 500);
                            setErr('success');
                            setRefresh(!refresh)
                            setTimeout(() => { setErr(false); modalClose.current.click() }, 1500);
                        }
                        else { setTimeout(() => { setLoading(false); setErr('server') }, 500); }
                    })
                } else { setTimeout(() => { setLoading(false); setErr('server') }, 500); }
            })
        }

        if (text === 'Refusée') {
            const declineMail = msgValue === "" ? { email: request.email, msgValue: "non spécifié" } : { email: request.email, msgValue }
            setLoading(true);
            declineProvider(declineMail, (cb) => {
                if (cb.status === 200) {
                    updateStatus(request._id, { status: 'denied' }, (cb) => {
                        if (cb.status === 200) {
                            setTimeout(() => { setLoading(false) }, 500);
                            setErr('success');
                            setRefresh(!refresh)
                            setTimeout(() => { setErr(false); modalClose.current.click() }, 1500);
                        }
                        else { setTimeout(() => { setLoading(false); setErr('server') }, 500); }
                    })
                } else { setTimeout(() => { setLoading(false); setErr('server') }, 500); }
            })
        }

        if (text === 'En attente') {
            setLoading(true);
            updateStatus(request._id, { status: 'held' }, (cb) => {
                if (cb.status === 200) {
                    setRefresh(!refresh)
                    setTimeout(() => { setLoading(false); setErr('success') }, 500);
                    setTimeout(() => { setErr(false); modalClose.current.click() }, 1500);
                }
                else { setTimeout(() => { setLoading(false); setErr('server') }, 500); }
            })
        }
    }
    return (
        <div className={`modal fade`} id="treatProvider" tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-lg`}>
                <div className={`modal-content `}>
                    <div className="row">
                        <div className="col-10 pt-4 ps-5">
                            <h4 >Traitement de la demande</h4>
                        </div>
                        <div className="col-2 text-end pt-3 pe-4">
                            <button ref={modalClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        {request &&
                            <div className="row p-3">
                                <div className="col-lg-4">
                                    <p><strong className="">Nom : </strong>{request && request.lastname}</p>
                                    <p><strong className="">Prénom : </strong>{request && request.firstname}</p>
                                    <p><strong className="">Téléphone : </strong>{request && request.phone} <br /></p>
                                    <p><strong className="">Adresse e-mail : </strong>{request && request.email} <br /></p>
                                    <p><strong className="">Nom d'établissement : </strong>{request && request.estabname} <br /></p>
                                    <p><strong className="">Adresse : </strong>{request && request.location}</p>
                                    <p><strong className="">Type : </strong>{request && request.type && request.type.length > 0 && request.type.join(' - ')}</p>
                                </div>
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-4"><strong>Statut :</strong></div>
                                        <div className="col-8 px-0">
                                            <div className={` ${st.selector}`} onMouseEnter={() => { setListDisplay(true) }} onMouseLeave={() => { setListDisplay(false) }}>
                                                <div className={`${st.selectField}`}>
                                                    <span>{text}</span>
                                                    <span className={`${listDisplay ? st.rotate : st.rotateBack}`} ><ExpandMoreIcon /></span>
                                                </div>
                                                <ul className={`${st.list} ${listDisplay ? st.visible : ''}`} >
                                                    <li className={` ${st.options}`} onClick={() => { setText('En attente'); setExtra(false) }} >En attente </li>
                                                    <li className={` ${st.options}`} onClick={() => { setText('Acceptée'); setExtra('membership') }}>Accepter</li>
                                                    <li className={` ${st.options}`} onClick={() => { setText('Refusée'); setExtra('message'); setMsgValue('') }}>Réfuser</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {extra === 'membership' &&
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-4">
                                                    <strong>Fin d'dhésion<span className={`text-danger`}>*</span> :</strong>
                                                </div>
                                                <div className={`col-8 pe-4`}>
                                                    <input className={`form-control ${st.membershipControl} ${err === "req" && !membership ? "is-invalid" : ""}`} value={membership} onChange={(e) => { setMembership(e.target.value); setErr(false) }} type="date" />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {extra === 'message' &&
                                        <>
                                            <div className="row mt-3"><div className="col-12"><strong>Message :</strong></div></div>
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <textarea name="message" value={msgValue} onChange={(e) => { setMsgValue(e.target.value) }} className="form-control" cols="30" rows="3"></textarea>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>}
                        <div className="row mb-1">
                            {loading && <div className="d-flex justify-content-center "><span className={`${st.loader}`}></span></div>}
                            {err &&
                                <div className="d-flex justify-content-center ">
                                    {err === 'success' && <span className={`text-success`}>Opération réussite</span>}
                                    {err === 'server' && <span className={`text-danger`}>Une erreur est survenue</span>}
                                    {err === 'req' && <span className={`text-danger`}>Veuillez remplir le champ vide</span>}
                                </div>
                            }
                        </div>
                        <div className="row mt-2">
                            <div className="col text-end me-3">
                                <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                <button onClick={changeStatus} className={`${st.save}`}>Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TreatProvider;