import { useEffect, useMemo, useState } from "react";
import st from "./openHours.module.css";
import VpnKeyOffOutlinedIcon from '@mui/icons-material/VpnKeyOffOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';

function OpenHours(props) {
    const { oldOpenings, setOpenings } = props;
    const initialValues = useMemo(() => [
        {
            day: "Monday",
            openingTime: oldOpenings && oldOpenings.length > 0 ? oldOpenings[0].openingTime : "00:00",
            closingTime: oldOpenings && oldOpenings.length > 0 ? oldOpenings[0].closingTime : "00:00",
        },
        {
            day: "Tuesday",
            openingTime: oldOpenings && oldOpenings.length > 1 ? oldOpenings[1].openingTime : "00:00",
            closingTime: oldOpenings && oldOpenings.length > 1 ? oldOpenings[1].closingTime : "00:00",
        },
        {
            day: "Wednesday",
            openingTime: oldOpenings && oldOpenings.length > 2 ? oldOpenings[2].openingTime : "00:00",
            closingTime: oldOpenings && oldOpenings.length > 2 ? oldOpenings[2].closingTime : "00:00",
        },
        {
            day: "Thursday",
            openingTime: oldOpenings && oldOpenings.length > 3 ? oldOpenings[3].openingTime : "00:00",
            closingTime: oldOpenings && oldOpenings.length > 3 ? oldOpenings[3].closingTime : "00:00",
        },
        {
            day: "Friday",
            openingTime: oldOpenings && oldOpenings.length > 4 ? oldOpenings[4].openingTime : "00:00",
            closingTime: oldOpenings && oldOpenings.length > 4 ? oldOpenings[4].closingTime : "00:00",
        },
        {
            day: "Saturday",
            openingTime: oldOpenings && oldOpenings.length > 5 ? oldOpenings[5].openingTime : "00:00",
            closingTime: oldOpenings && oldOpenings.length > 5 ? oldOpenings[5].closingTime : "00:00",
        },
        {
            day: "Sunday",
            openingTime: oldOpenings && oldOpenings.length > 6 ? oldOpenings[6].openingTime : "00:00",
            closingTime: oldOpenings && oldOpenings.length > 6 ? oldOpenings[6].closingTime : "00:00",
        }
    ], [oldOpenings]);

    const [workingHours, setWorkingHours] = useState(initialValues);
    useEffect(() => {
        setOpenings(workingHours);
    }, [workingHours, setOpenings]);

    const handleTimeChange = (day, field, value) => {
        const updatedHours = workingHours.map(hour => {
            if (hour.day === day) {
                return { ...hour, [field]: value };
            }
            return hour;
        });
        setWorkingHours(updatedHours);
        setOpenings(updatedHours);
    };

    return (
        <div className={`${st.openHours}`}>
            <div className={`${st.container}`}>
                {workingHours.map(({ day, openingTime, closingTime }) => (
                    <div key={day} className="row mt-2">
                        <div className="col">
                            <h6>
                                {day === "Monday" && "Lundi :"}
                                {day === "Tuesday" && "Mardi :"}
                                {day === "Wednesday" && "Mercredi :"}
                                {day === "Thursday" && "Jeudi :"}
                                {day === "Friday" && "Vendredi :"}
                                {day === "Saturday" && "Samedi :"}
                                {day === "Sunday" && "Dimanche :"}
                            </h6>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-3 pt-1"><VpnKeyOutlinedIcon /></div>
                                        <div className="col-9">
                                            <input
                                                type="time"
                                                value={openingTime}
                                                onChange={(e) => handleTimeChange(day, "openingTime", e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row mt-2 mt-lg-0">
                                        <div className="col-3 pt-1"><VpnKeyOffOutlinedIcon /></div>
                                        <div className="col-9">
                                            <input
                                                type="time"
                                                value={closingTime}
                                                onChange={(e) => handleTimeChange(day, "closingTime", e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-0" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OpenHours;
