import { useEffect, useState } from "react";
import st from "./archivedEstabsList.module.css"
import Pagination from "../../../pagination/pagination";
import { getEstablishments } from "../../../../services/establishmentApi";

import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import { Link } from "react-router-dom";
import DeleteEstablishment from "../../../modals/Establishments/deleteEstablishment/deleteEstablishment";
import ManageArchivedEstablishment from "../../../modals/Establishments/manageArchivedEstablishment/manageArchivedEstablishment";

function ArchivedEstabList(props) {
    const { refreshComponents } = props;
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 10;

    const [search, setSearch] = useState("");
    const [establishments, setEstablishments] = useState([]);
    const [establishmentsToPaginate, setEstablishmentsToPaginate] = useState([]);

    const [reset, setReset] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedEstablishment, setSelectedEstablishment] = useState({});

    useEffect(() => {
        getEstablishments((cb) => {
            if (cb.status === 200) {
                let archivedEstabs
                if (cb.data && cb.data.establishments && cb.data.establishments.length > 0) {
                    archivedEstabs = cb.data.establishments.filter(estab => estab.deletesAt || estab.locked || (new Date(estab.expiresAt) < new Date()));
                }
                if (search === "") {
                    setEstablishmentsToPaginate(archivedEstabs);
                }
                else {
                    setEstablishmentsToPaginate(archivedEstabs.filter((estab) => {
                        for (let key in estab) {
                            if (typeof estab[key] === 'string' && estab[key].toLowerCase().includes(search.toLowerCase())) {
                                return true;
                            }
                        }
                        return false;
                    }));
                }
            }
        })
        setItemOffset(0)
        setPageCount(0)
    }, [search, refresh, refreshComponents])

    const expiresAt = (expiresAt) => {
        const today = new Date();
        const expiration = new Date(expiresAt);
        const daysDiff = Math.ceil((expiration - today) / (1000 * 3600 * 24));
        if (daysDiff > 30) return "green";
        if (daysDiff > 0) return "orange";
        return "rgb(194, 0, 0)";
    };

    return (
        <div className="contianer-fluid p-4">
            <DeleteEstablishment establishment={selectedEstablishment} reset={reset} setRefresh={setRefresh} modalId="deleteEstablishmentModal" />
            <ManageArchivedEstablishment establishment={selectedEstablishment} reset={reset} setRefresh={setRefresh} modalId="manageArchivedEstablishment" />
            <div className="row">
                <div className="col-lg-5 ps-5">
                    <h3>Liste des établissements archivés</h3>
                </div>
                <div className="col-lg-7 mb-2">
                    <div className={`${st.inputGroup}`}>
                        <form autoComplete='off' >
                            <input onChange={(event) => { setSearch(event.target.value) }} value={search} autoComplete='false'
                                type="text" placeholder="Rechercher une demande" className={`border-0 bg-light ${st.formControl}`} />
                            <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={`row mt-4 ${st.tableBox}`}>
                <div className={`col`} style={{ overflow: "auto", marginBottom: "10px" }}>
                    {establishments && establishments.length > 0 ?
                        <table className={`table`}>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "100px" }}>Nom </th>
                                    <th style={{ minWidth: "0px" }}>Téléphone </th>
                                    <th style={{ minWidth: "200px" }}>Adresse</th>
                                    <th style={{ minWidth: "200px" }}>Type</th>
                                    <th style={{ minWidth: "127px" }}>Fin d'adhésion </th>
                                    <th style={{ minWidth: "127px" }}>Status </th>
                                    <th style={{ minWidth: "180px", textAlign: "center" }}>Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {establishments.map((estab, key) =>
                                    <tr key={key} >
                                        <td style={{ paddingTop: "15px" }}><Link className={`${st.estbname}`} to={`/place/${estab.customUrl}`}>{estab.name}</Link></td>
                                        <td style={{ paddingTop: "15px" }}>{estab.phone}</td>
                                        <td style={{ paddingTop: "15px" }}>{estab.location}</td>
                                        <td style={{ paddingTop: "15px" }}>{estab.type && estab.type.length > 0 && estab.type.join(' - ')}</td>
                                        <td style={{ paddingTop: "15px" }}>
                                            <span style={{ color: expiresAt(estab.expiresAt) }}>{estab.expiresAt.substring(0, 10)}</span>
                                        </td>
                                        <td style={{ paddingTop: "15px" }} >
                                            {estab.locked && <span className="txtDanger">Verrouillé </span>}
                                            {estab.deletesAt && <span className="txtWarning" > Supprimé</span>}
                                            {!estab.locked && !estab.deletesAt && (new Date(estab.expiresAt) < new Date()) && <span className="txtDanger">Expiré</span>}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <button onClick={(e) => { e.stopPropagation(); window.open(`/edit-establishment/${estab._id}`, '_blank'); }}
                                                className={`btn me-1 ${st.EditIcon}`}>
                                                <EditIcon />
                                            </button>
                                            <button
                                                onClick={(e) => { e.stopPropagation(); setSelectedEstablishment(estab); setReset(!reset) }}
                                                className={`btn btn-dark me-1 ${st.DeleteIcon}`}
                                                data-bs-toggle="modal" data-bs-target="#manageArchivedEstablishment">
                                                <RoomPreferencesIcon />
                                            </button>
                                            <button
                                                onClick={(e) => { e.stopPropagation(); setSelectedEstablishment(estab); setReset(!reset) }}
                                                className={`btn btn-dark ${st.DeleteIcon}`}
                                                data-bs-toggle="modal" data-bs-target="#deleteEstablishmentModal">
                                                <DeleteOutlineIcon />
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        : (
                            <div className="text-center mt-5">Aucune demande n'est disponible.</div>
                        )}
                </div>
                <Pagination pageCount={pageCount} setPageCount={setPageCount} itemsPerPage={itemsPerPage} itemOffset={itemOffset} setItemOffset={setItemOffset} items={establishmentsToPaginate} setCurrentItems={setEstablishments} />
            </div>
        </div >
    );
}

export default ArchivedEstabList;