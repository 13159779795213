import { getProviders } from "../../../../services/adminApis";
import Pagination from "../../../pagination/pagination";
import st from "./providersList.module.css"
import React, { useState, useEffect } from 'react';

import LockResetIcon from '@mui/icons-material/LockReset';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import EditIcon from '@mui/icons-material/Edit';

import ManageProviderAccount from "../../../modals/manageProviderAccount/manageProviderAccount";
import ChangePass from "../../../modals/changePassword/changePassword";
import UpdateUserAccount from "../../../modals/updateUserAccount/updateUserAccount";

function ProvidersList(props) {
    const { refreshComponents } = props;
    const [search, setSearch] = useState("");

    const [providersToPaginate, setProvidersToPaginate] = useState();
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 10;

    const [providers, setProviders] = useState();
    const [filter, setFilter] = useState('all');
    const [sort, setSort] = useState('nothing');

    //modal :
    const [selectedProvider, setSelectedProvider] = useState();
    const [reset, setReset] = useState(false);
    const [action, setAction] = useState(false);
    const changePasswordModal = "changeProviderPassword";

    const [allMembers, setAllMembers] = useState([]);
    const [activeMembers, setActiveMembers] = useState([]);
    const [outdatedMembers, setOutdatedMembers] = useState([]);

    useEffect(() => {
        getProviders((cb) => {
            if (cb.status === 200) {
                setAllMembers(cb.data.providers);
                setActiveMembers(cb.data.activeMembers);
                setOutdatedMembers(cb.data.outdatedMembers);
            }
        });
        setItemOffset(0);
        setPageCount(0);
    }, [action, refreshComponents]);

    useEffect(() => {
        if (search === "") {
            let filtered;
            if (filter === 'all') {
                setProvidersToPaginate(allMembers);
                filtered = allMembers;
            } else if (filter === 'active') {
                setProvidersToPaginate(activeMembers);
                filtered = activeMembers;
            } else if (filter === 'outdated') {
                setProvidersToPaginate(outdatedMembers);
                filtered = outdatedMembers;
            }

            if (sort === "nameAZ") {
                setProvidersToPaginate([...filtered].sort((a, b) => a.lastname.localeCompare(b.lastname)));
            } else if (sort === "nameZA") {
                setProvidersToPaginate([...filtered].sort((a, b) => b.lastname.localeCompare(a.lastname)));
            }
        } else {
            setProvidersToPaginate(
                allMembers.filter((provider) => {
                    for (let key in provider) {
                        if (
                            typeof provider[key] === 'string' &&
                            provider[key].toLowerCase().includes(search.toLowerCase())
                        ) {
                            return true;
                        }
                    }
                    return false;
                })
            );
        }
    }, [search, filter, sort, allMembers, activeMembers, outdatedMembers]);

    return (
        <div className="contianer-fluid p-4">
            <UpdateUserAccount modalId={"editProviderModal"} user={selectedProvider} reset={reset} setAction={setAction} />
            <ChangePass user={selectedProvider} reset={reset} modalId={changePasswordModal} />
            <ManageProviderAccount modalId="manageProviderAccount" user={selectedProvider} reset={reset} setAction={setAction} />
            <div className="row">
                <div className="col-lg-5 ps-5">
                    <h3>Liste des prestataires</h3>
                </div>
                <div className="col-lg-7 mb-2">
                    <div className={`${st.inputGroup}`}>
                        <input onChange={(event) => { setSearch(event.target.value) }} value={search} autoComplete="off"
                            type="text" placeholder="Rechercher un prestataire" className={`border-0 bg-light ${st.formControl}`} />
                        <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <div className="col-lg-8"></div>
                <div className="col-lg-4 mt-3">
                    <div className="row">
                        <div className="col-6"></div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-6 px-0 text-end">
                                    <div className="dropdown-center">
                                        <span className={`btn btn-dark dropdown-toggle ${st.filter}`} data-bs-toggle="dropdown" aria-expanded="false">Filter</span>
                                        <ul className="dropdown-menu">
                                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'all' ? "active" : ""}`} onClick={() => { setFilter('all') }}>Tous</span></li>
                                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'active' ? "active" : ""}`} onClick={() => { setFilter('active') }}>Active</span></li>
                                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'outdated' ? "active" : ""}`} onClick={() => { setFilter('outdated') }}>Obsolète</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-6 text-end">
                                    <div className="dropdown-center">
                                        <span className={`btn btn-dark dropdown-toggle ${st.sort}`} data-bs-toggle="dropdown" aria-expanded="false">Sort</span>
                                        <ul className="dropdown-menu">
                                            <li><span className={`dropdown-item ${st.sortings} ${sort === 'nothing' ? "active" : ""}`} onClick={() => { setSort('nothing') }}>Rien</span></li>
                                            <li><span className={`dropdown-item ${st.sortings} ${sort === 'nameAZ' ? "active" : ""}`} onClick={() => { setSort('nameAZ') }}>Nom A à Z</span></li>
                                            <li><span className={`dropdown-item ${st.sortings} ${sort === 'nameZA' ? "active" : ""}`} onClick={() => { setSort('nameZA') }}>Nom Z à A</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`row ${st.tableBox}`}>
                <div className={`col`} style={{ overflow: "auto", marginBottom: "10px" }}>
                    {providers && providers.length > 0 ?
                        <table className={`table`}>
                            <thead>
                                <tr>
                                    <th style={{ width: "60px" }}>Avatar</th>
                                    <th style={{ minWidth: "140px" }}>Nom </th>
                                    <th style={{ minWidth: "140px" }}>Prénom </th>
                                    <th style={{ minWidth: "140px" }}>Téléphone </th>
                                    <th style={{ minWidth: "200px" }}>Adresse e-mail </th>
                                    <th style={{ minWidth: "149px", width: "149px" }}>Statut</th>
                                    <th style={{ minWidth: "173px", textAlign: "center" }}>Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {providers.map((provider, key) => provider.isSuspended === false &&
                                    <tr key={key}>
                                        <td>
                                            <img src={provider.avatar ? provider.avatar.includes("http") ?
                                                provider.avatar :
                                                process.env.REACT_APP_SERVER_PUBLIC + "" + provider.avatar : "./images/userdefault.png"}
                                                alt="avatar" className={`${st.avatar}`} />
                                        </td>
                                        <td style={{ paddingTop: "20px" }}>{provider.lastname}</td>
                                        <td style={{ paddingTop: "20px" }}>{provider.firstname}</td>
                                        <td style={{ paddingTop: "20px" }}>{provider.phone}</td>
                                        <td style={{ paddingTop: "20px" }}>{provider.email}</td>
                                        <td style={{ paddingTop: "20px" }}>
                                            {
                                                provider.timeout && (new Date(provider.timeout) > new Date()) ? (
                                                    <div className='text-danger'> {`Suspendu ${Math.ceil((new Date(provider.timeout) - new Date()) / (1000 * 60 * 60 * 24))} jour(s)`}</div>
                                                ) : (!provider.tiemout && !provider.isDeleted && !provider.isSuspended && <div className='text-success'>Actif</div>)
                                            }
                                        </td>

                                        <td style={{ paddingTop: "13px", textAlign: "center" }}>
                                            <button onClick={() => { setSelectedProvider(provider); setReset(!reset) }}
                                                data-bs-toggle="modal" data-bs-target={"#editProviderModal"}
                                                className={`btn ${st.LockResetIcon} me-1`}><EditIcon /></button>

                                            <button onClick={() => { setSelectedProvider(provider); setReset(!reset) }}
                                                data-bs-toggle="modal" data-bs-target={"#" + changePasswordModal}
                                                className={`btn ${st.LockResetIcon} me-1`}><LockResetIcon /></button>

                                            <button
                                                onClick={() => { setSelectedProvider(provider); setReset(!reset) }}
                                                className={`btn btn-dark ${st.ManageAccount}`}
                                                data-bs-toggle="modal" data-bs-target="#manageProviderAccount"><ManageAccountsOutlinedIcon /></button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        : ""}

                </div>
                <Pagination pageCount={pageCount} setPageCount={setPageCount} itemsPerPage={itemsPerPage} itemOffset={itemOffset} setItemOffset={setItemOffset} items={providersToPaginate} setCurrentItems={setProviders} />
            </div>
        </div>
    );
}

export default ProvidersList;