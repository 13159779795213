import { useEffect, useState, useRef } from "react";
import st from "./profile.module.css";
import { getUser, updateUser } from "../../services/userApi";
import ClientNavbar from "../../components/navigation/client-navbar/client-navbar";

function Profile(props) {
  const { refresh, setRefresh, role } = props;
  const initialValues = {
    firstname: "",
    lastname: "",
    phone: "",
    birthdate: "",
    email: ""
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [user, setUser] = useState();
  const [action, setAction] = useState(false);
  const [err, setErr] = useState(false);
  const [avatar, setAvatar] = useState();
  const [load, setLoad] = useState(false);
  const [update, setUpdate] = useState(false);

  const imgView = useRef(null);
  const inputFile = useRef(null);

  useEffect(() => {
    getUser((cb) => {
      if (cb.status === 200) {
        setUser(cb.data.user);
        setFormValues({
          firstname: cb.data.user.firstname ? cb.data.user.firstname : "",
          lastname: cb.data.user.lastname ? cb.data.user.lastname : "",
          phone: cb.data.user.phone ? cb.data.user.phone : "",
          birthdate: cb.data.user.birthdate ? cb.data.user.birthdate.slice(0, 10) : "",
          email: cb.data.user.email
        })
      } else console.error(cb);
    });
  }, [action]);

  const uploadImage = (e) => {
    setErr(false)
    if (inputFile.current.files.length > 0) {
      const imgSize = inputFile.current.files[0].size;
      if (imgSize > 2097152) {
        setLoad(false);
        setErr("image");
      }
      if (imgSize <= 2097152) {
        const imgLink = URL.createObjectURL(inputFile.current.files[0]);
        imgView.current.src = imgLink;
        setAvatar(inputFile.current.files[0]);
      }
    } else {
      console.error("No file selected.");
      setLoad(false);
      setErr("file");
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
  }
  const drop = (e) => {
    e.preventDefault();
    inputFile.current.files = e.dataTransfer.files;
    uploadImage();
  }

  const handleChange = (event) => {
    setErr(false)
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const UpdateUserAccount = (e) => {
    e.preventDefault();
    if (!formValues.firstname || !formValues.lastname || !formValues.email) {
      setErr("req");
      return;
    }
    setErr(false)
    setLoad(true);

    const formData = new FormData();

    for (const key in formValues) {
      if (formValues[key] !== null && formValues[key] !== undefined) {
        formData.append(key, formValues[key])
      }
    }
    if (avatar instanceof File) {
      formData.append("avatar", avatar);
    }
    // for (const pair of formData.entries()) {
    //   console.log(pair[0], pair[1]);        
    // }                                        

    updateUser(formData, (cb) => {
      if (cb.status === 200) {
        setRefresh(!refresh);
        setTimeout(() => { setLoad(false); setUpdate(true); }, 2000);
        setTimeout(() => { setUpdate(false) }, 4000);
        setAction(!action);
      } else if (cb.response.status === 409) {
        setTimeout(() => { setLoad(false); setErr('phone'); }, 2000);
      } else {
        setTimeout(() => { setLoad(false); setErr('server'); }, 2000);
      }
    })
  };

  return (
    <div className="clientPage">
      {role !== 'provider' && <ClientNavbar />}
      <div className={`container ${st.body}`}>
        {user && (
          <>
            <div className="row">
              <div className="col-xl-4">
                <div className={`card ps-3 pe-3 mb-3 ${st.photoCard}`}
                  onDragOver={dragOver}
                  onDrop={drop}>
                  <div className="row text-center mt-4">
                    <h5>Photo de profil</h5>
                  </div>
                  <div className="row pt-2 pb-3">
                    <div className="text-center">
                      <input ref={inputFile} type="file" accept="image/*" onChange={uploadImage} hidden />
                      <img
                        id="img-view" ref={imgView}
                        className={`object-fit-cover ${st.img}`}
                        src={user && user.avatar ?
                          user.avatar.includes('http') ?
                            user.avatar :
                            `${process.env.REACT_APP_SERVER_PUBLIC}${user.avatar}`
                          : "/images/userdefault.png"}
                        alt="avatar"
                        onClick={() => inputFile.current.click()}
                      />
                    </div>
                  </div>
                  {err === "image" && (
                    <div className="row text-center mb-2">
                      <span className='txtDanger'>La taille d'image doit être inferieur à 2048 ko</span>
                    </div>
                  )}
                  {err === "file" && (
                    <div className="row text-center mb-2">
                      <span className='txtDanger'>Impossible de sélectionner l'image</span>
                    </div>
                  )}
                  <div className="row mb-3 text-center">
                    <h6>
                      Faites glisser et déposez ou
                      <span className={`${st.upload}`}
                        onClick={() => inputFile.current.click()}> cliquez ici </span>
                      pour télécharger l'image
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-xl-8">
                <div className={`card ${st.infoCard} mb-4`}>
                  <div className={`ps-5 pt-5`}>
                    <h5>Détails du profil :</h5>
                  </div>
                  <div className={`card-body ${st.cardBody}`}>
                    <div className="row gx-3 mb-3">
                      <div className="col-md-6 ">
                        <label className="small mb-1">Nom<span className="text-danger">*</span></label>
                        <input
                          className={`form-control ${err === "req" && !formValues.lastname ? "is-invalid" : ""}`}
                          type="text"
                          placeholder="Entrez votre nom"
                          name="lastname"
                          onChange={handleChange}
                          value={formValues.lastname}
                        />
                      </div>

                      <div className="col-md-6 ">
                        <label className="small mb-1">Prénom<span className="text-danger">*</span></label>
                        <input
                          className={`form-control ${err === "req" && !formValues.firstname ? "is-invalid" : ""}`}
                          type="text"
                          placeholder="Entez votre prénom"
                          name="firstname"
                          onChange={handleChange}
                          value={formValues.firstname}
                        />
                      </div>
                    </div>

                    <div className="row gx-3 mb-3">
                      <div className="col-md-6">
                        <label className="small mb-1">
                          Numéro de téléphone
                        </label>
                        <input
                          className={`form-control ${err === "phone" ? "is-invalid" : ""}`}
                          id="inputLastName"
                          type="tel"
                          placeholder="Entez votre numéro de téléphone"
                          name="phone"
                          onChange={handleChange}
                          value={!formValues.phone ? '' : formValues.phone}
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="small mb-1">Birthdate</label>
                        <input
                          className={`form-control `}
                          type="date"
                          name="birthdate"
                          onChange={handleChange}
                          value={formValues.birthdate}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3">
                        <label className="small mb-1">Adresse E-mail</label>
                        <input
                          className={`form-control text-muted ${err === "req" && !formValues.email ? "is-invalid" : ""}`}
                          type="email"
                          placeholder="Enter your email address"
                          name="email"
                          onChange={handleChange}
                          value={formValues.email}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      {load && (<div className="d-flex justify-content-center mb-2"><div className={`${st.loader}`}></div></div>)}
                      {update && (<div className="d-flex justify-content-center mb-2"><div className={`text-success`}>Profil enregistré</div></div>)}
                      {err === "req" && (<div className="d-flex justify-content-center mb-2"><div className={`text-danger`}>Veuillez remplir tous les champs obligatoires</div></div>)}
                      {err === "phone" && (<div className="d-flex justify-content-center mb-2"><div className={`text-danger`}>Le numéro de téléphone est déjà utilisé</div></div>)}
                      {err === "server" && (<div className="d-flex justify-content-center mb-2"><div className={`text-danger`}>Une erreur est survenue lors de la mise à jour de votre profil</div></div>)}
                      <div className="d-flex justify-content-center">
                        <button className={`${st.myBtn}`} type="submit" onClick={UpdateUserAccount}>
                          Sauvegarder
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Profile;
