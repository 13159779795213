import { useState } from "react";
import { changeAccountPassword } from "../../../services/userApi";
import st from "./change-password.module.css"

function ChangePassword() {
  const [googleAccount, setGoogleAccount] = useState(localStorage.getItem('googleAccount') || sessionStorage.getItem('googleAccount'))

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const [passFormValues, setPassFormValues] = useState(initialValues);
  const [err, setErr] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setErr(false)
    const { name, value } = event.target;
    setPassFormValues({ ...passFormValues, [name]: value });
  };

  const resetForm = (event) => {
    event.preventDefault();
    setPassFormValues(initialValues);
    setErr(false)
  }
  const changePass = (event) => {
    event.preventDefault();

    let passwordPattern = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
    if (googleAccount === "true") {
      if (
        !passFormValues.newPassword ||
        !passFormValues.confirmNewPassword
      ) {
        setErr("req");
        return;
      }
    } else {
      if (
        !passFormValues.oldPassword ||
        !passFormValues.newPassword ||
        !passFormValues.confirmNewPassword
      ) {
        setErr("req");
        return;
      }
    }

    if (!passwordPattern.test(passFormValues.newPassword)) {
      setErr("pattern");
      return;
    }
    if (passFormValues.newPassword !== passFormValues.confirmNewPassword) {
      setErr("notMatch");
      return;
    }
    const updates = {
      oldpassword: passFormValues.oldPassword,
      newpassword: passFormValues.newPassword,
      googleAccount: googleAccount && googleAccount === 'true' ? true : false
    };
    setErr(false)
    setLoading(true)
    changeAccountPassword(updates, (cb) => {
      if (cb.status === 200) {
        if (localStorage.getItem("googleAccount")) {
          localStorage.setItem("googleAccount", 'false')
        } else if (sessionStorage.getItem("googleAccount")) {
          sessionStorage.setItem("googleAccount", 'false')
        }
        setTimeout(() => { setLoading(false); setErr("success"); }, 1000);
        setTimeout(() => { setErr(false); setPassFormValues(initialValues); setGoogleAccount('false') }, 3000);
        return;
      } else if (cb.response.status === 401) {
        setTimeout(() => { setLoading(false); setErr("wrongPassword"); }, 1000);
        return;
      } else if (cb.response.status === 409) {
        setTimeout(() => { setLoading(false); setErr("same"); }, 1000);
        return;
      } else if (cb.response.status === 406) {
        setTimeout(() => { setLoading(false); setErr("similar"); }, 1000);
        return;
      } else {
        setTimeout(() => { setLoading(false); setErr("server"); }, 1000);
        return;
      }
    });
  };
  return (
    <div className={`card mb-4 ps-3 pe-3 ${st.card}`}>
      <div className="row ps-4 pe-4 pt-4">
        {googleAccount === "false" ? <h5>Changer votre mot de passe</h5> : <h5>Ajouter un mot de passe</h5>}
      </div>
      <div className="ps-4 pe-4">
        <form >
          {googleAccount === "false" &&
            <div className="row mb-3">
              <div className="col">
                <label className="form-label small mb-1">Mot de passe actuel<span className="txtDanger">*</span></label>
                <input
                  className={`form-control ${(err === "req" && !passFormValues.oldPassword) || err === "wrongPassword" || err === "googleAccount" || err === "same" || err === "similar" || err === "server" ? "is-invalid" : ""}`}
                  type={showPass ? "text" : "password"}
                  placeholder="Entrez votre mot de passe actuel"
                  onChange={handleChange}
                  name="oldPassword"
                  value={passFormValues.oldPassword}
                />
              </div>
            </div>
          }
          <div className="row mb-3">
            <div className="col">
              <label className="form-label small mb-1">Nouveau mot de passe<span className="txtDanger">*</span></label>
              <input
                className={`form-control ${(err === "req" && !passFormValues.newPassword) || err === "pattern" || err === "notMatch" || err === "googleAccount" || err === "same" || err === "similar" || err === "server" ? "is-invalid" : ""}`}
                type={showPass ? "text" : "password"}
                placeholder="Entrez un nouveau mot de passe"
                onChange={handleChange}
                name="newPassword"
                value={passFormValues.newPassword}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <label className="form-label small mb-1">Confirmez le mot de passe<span className="txtDanger">*</span></label>
              <input
                className={`form-control ${(err === "req" && !passFormValues.confirmNewPassword) || err === "notMatch" ? "is-invalid" || err === "googleAccount" || err === "server" : ""}`}
                type={showPass ? "text" : "password"}
                placeholder="Confirmer le nouveau mot de passe"
                onChange={handleChange}
                name="confirmNewPassword"
                value={passFormValues.confirmNewPassword}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              {err === "success" && <span className="txtSuccess mb-1">Le mot de passe a été modifié avec succès</span>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="txtDanger">
                {err === "req" && "Tous les champs sont obligatoires !"}
                {err === "pattern" && "Le mot de passe doit contenir au moins 8 caractères et inclure une lettre majuscule ainsi qu'un chiffre."}
                {err === "notMatch" && "Les mots de passe ne correspondent pas !"}
                {err === "wrongPassword" && "Mot de passe incorrect !"}
                {err === "googleAccount" && "C'est un compte Google !"}
                {err === "same" && "Le mot de passe est le même que l'ancien !"}
                {err === "similar" && "Le mot de passe ressemble à l'ancien !"}
                {err === "server" && "Un problème survenue, réessayer plus tard !"}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className={`form-check-reverse form-switch ${st.formCheck}`}>
                <label className="form-check-label">Montrer les mots de passes</label>
                <input className={`form-check-input ${st.formCheckInput}`} type="checkbox" checked={showPass} onChange={(e) => setShowPass(e.target.checked)} />
              </div>
            </div>
          </div>
          {loading &&
            <div className="row" style={{ height: '2rem' }}>
              <div className="col d-flex justify-content-center">
                <span className={`${st.loader}`}></span>
              </div>
            </div>
          }
          <div className="row mt-2 mb-3">
            <div className="col d-flex justify-content-center">
              <button className={`${st.Btn}`} onClick={resetForm} style={{ marginRight: "5px" }}>
                Réinitialiser
              </button>
              <button className={`${st.myBtn}`} onClick={changePass}>
                Enregister
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;