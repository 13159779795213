import { useEffect, useState } from "react";
import st from "./estabsList.module.css"
import Pagination from "../../../pagination/pagination";
import { getEstablishments, toggleHideEstablishment } from "../../../../services/establishmentApi";

import EditIcon from '@mui/icons-material/Edit';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ArchiveEstablishment from "../../../modals/Establishments/archiveEstablishment/archiveEstablishment";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import { Link } from "react-router-dom";
import ManageEstablishment from "../../../modals/Establishments/manageEstablishment/manageEstablishment";

function EstabsList(props) {
    const { refreshComponents } = props;
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 8;

    const [search, setSearch] = useState("");
    const [establishments, setEstablishments] = useState([]);
    const [establishmentsToPaginate, setEstablishmentsToPaginate] = useState([]);

    const [reset, setReset] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedEstablishment, setSelectedEstablishment] = useState({});

    useEffect(() => {
        getEstablishments((cb) => {
            if (cb.status === 200) {
                let activeEstabs
                if (cb.data && cb.data.establishments && cb.data.establishments.length > 0) {
                    activeEstabs = cb.data.establishments.filter(estab => !estab.deletesAt && estab.locked === false && (new Date(estab.expiresAt) > new Date()));
                }
                if (search === "") {
                    setEstablishmentsToPaginate(activeEstabs);
                }
                else {
                    setEstablishmentsToPaginate(activeEstabs.filter((estab) => {
                        for (let key in estab) {
                            if (typeof estab[key] === 'string' && estab[key].toLowerCase().includes(search.toLowerCase())) {
                                return true;
                            }
                        }
                        return false;
                    }));
                }
            }
        })
        setItemOffset(0)
        setPageCount(0)
    }, [search, refresh, refreshComponents])


    const expiresAt = (expiresAt) => {
        const today = new Date();
        const expiration = new Date(expiresAt);
        const daysDiff = Math.ceil((expiration - today) / (1000 * 3600 * 24));
        if (daysDiff > 30) return "green";
        if (daysDiff > 0) return "orange";
        return "red";
    };

    const visibility = (id) => {
        toggleHideEstablishment(id, (cb) => {
            if (cb.status === 200) {
                setEstablishments(pv => pv.map(estb => estb._id === id ?
                    {
                        ...estb,
                        hidden: !estb.hidden
                    } : estb));
            }
        })
    }

    return (
        <div className="contianer-fluid p-4">
            <ArchiveEstablishment modalId='archiveEstablishmentModal' establishment={selectedEstablishment} reset={reset} setRefresh={setRefresh} />
            <ManageEstablishment modalId="manageEstablishment" establishment={selectedEstablishment} reset={reset} setRefresh={setRefresh} />
            <div className="row">
                <div className="col-lg-5 ps-5">
                    <h3>Liste des établissements</h3>
                </div>
                <div className="col-lg-7 mb-2">
                    <div className={`${st.inputGroup}`}>
                        <form autoComplete='off' >
                            <input onChange={(event) => { setSearch(event.target.value) }} value={search} autoComplete='false'
                                type="text" placeholder="Rechercher une demande" className={`border-0 bg-light ${st.formControl}`} />
                            <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={`row mt-4 ${st.tableBox}`}>
                <div className={`col`} style={{ overflow: "auto", marginBottom: "10px" }}>
                    {establishments && establishments.length > 0 ?
                        <table className={`table`}>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "90px" }}></th>
                                    <th style={{ minWidth: "100px" }}>Nom </th>
                                    <th style={{ minWidth: "0px" }}>Téléphone </th>
                                    <th style={{ minWidth: "200px" }}>Adresse</th>
                                    <th style={{ minWidth: "200px" }}>Type</th>
                                    <th style={{ minWidth: "126px", textAlign: "center" }}>Fin d'adhésion </th>
                                    <th style={{ minWidth: "180px", textAlign: "center" }}>Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {establishments.map((estab, key) =>
                                    <tr key={key} >
                                        <td style={{ textAlign: "center" }}>
                                            <RestaurantMenuIcon onClick={() => { window.location.href = `/establishment/${estab._id}/menus` }}
                                                className={`me-2 ${st.RestaurantMenuIcon}`} />
                                            {estab.hidden ?
                                                (
                                                    <button onClick={(e) => { e.stopPropagation(); visibility(estab._id) }}
                                                        className={` ${st.visibility}`}>
                                                        <VisibilityOffOutlinedIcon />
                                                    </button>
                                                )
                                                :
                                                (
                                                    <button onClick={(e) => { e.stopPropagation(); visibility(estab._id) }}
                                                        className={` ${st.visibility}`}>
                                                        <VisibilityOutlinedIcon />
                                                    </button>
                                                )}

                                        </td>
                                        <td style={{ paddingTop: "15px" }}><Link className={`${st.estbname}`} to={`/place/${estab.customUrl}`}>{estab.name}</Link></td>
                                        <td style={{ paddingTop: "15px" }}>{estab.phone}</td>
                                        <td style={{ paddingTop: "15px" }}>{estab.location}</td>
                                        <td style={{ paddingTop: "15px" }}>{estab.type && estab.type.length > 0 && estab.type.join(' - ')}</td>
                                        <td style={{ paddingTop: "15px", textAlign: "center" }}>
                                            <span style={{ color: expiresAt(estab.expiresAt) }}>{estab.expiresAt.substring(0, 10)}</span>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <button onClick={(e) => { e.stopPropagation(); window.location.href = `/edit-establishment/${estab._id}`; }}
                                                className={`btn me-1 ${st.EditIcon}`}>
                                                <EditIcon />
                                            </button>
                                            <button
                                                onClick={(e) => { e.stopPropagation(); setSelectedEstablishment(estab); setReset(!reset) }}
                                                className={`btn btn-dark me-1 ${st.DeleteIcon}`}
                                                data-bs-toggle="modal" data-bs-target="#manageEstablishment">
                                                <RoomPreferencesIcon />
                                            </button>
                                            <button
                                                onClick={(e) => { e.stopPropagation(); setSelectedEstablishment(estab); setReset(!reset) }}
                                                className={`btn btn-dark ${st.DeleteIcon}`}
                                                data-bs-toggle="modal" data-bs-target="#archiveEstablishmentModal">
                                                <LockOutlinedIcon />
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        : (
                            <div className="text-center mt-5">Aucun établissement n'est disponible.</div>
                        )}
                </div>
                <Pagination pageCount={pageCount} setPageCount={setPageCount} itemsPerPage={itemsPerPage} itemOffset={itemOffset} setItemOffset={setItemOffset} items={establishmentsToPaginate} setCurrentItems={setEstablishments} />
            </div>
        </div >
    );
}

export default EstabsList;