import st from './menuList.module.css';
import Edit from '@mui/icons-material/Edit';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { updateMenu } from '../../../../services/menuApi';
import DeleteMenu from '../deleteMenu/deleteMenu';
import AddMenu from '../addMenu/addMenu';

function MenuList(props) {
    const {
        menus,
        setMenus,
        refresh,
        setRefresh,
        activeMenu,
        setActiveMenu,
        edit,
        setEdit, } = props;

    const [selectedMenu, setSelectedMenu] = useState(false);
    const [feedBack, setFeedBack] = useState(false);
    const [err, setErr] = useState(false);
    const [reset, setReset] = useState(false);
    const [success, setSuccess] = useState(false);



    useEffect(() => {
        setTimeout(() => {
            setErr(false);
            setFeedBack(false);
            setSuccess(false);
        }, 2500);
    }, [feedBack, success, err]);

    const handleNameChange = (value, menuId) => {
        setMenus(prevMenus =>
            prevMenus.map(menu =>
                menu._id === menuId ? { ...menu, name: value } : menu
            )
        );
    };


    const editMenuName = (e, id) => {
        setErr(false);
        setSuccess(false);
        e.stopPropagation();
        const editedMenu = menus.find(menu => menu._id === id);
        const newName = editedMenu.name.charAt(0).toUpperCase() + editedMenu.name.slice(1);
        if (editedMenu) {
            updateMenu(id, { name: newName }, (cb) => {
                if (cb.status === 200) {
                    setSuccess(true);
                    setFeedBack("Le nom de menu est modifié avec succès");
                    setRefresh(!refresh);
                    setEdit(false);
                } else {
                    setErr(true);
                    setFeedBack('Un probleme est servenue, réessayer plus tard !');
                }
            });
        }
    };
    return (
        <div>
            <AddMenu
                setRefresh={setRefresh}
                reset={reset} />

            <DeleteMenu
                menu={selectedMenu}
                setRefresh={setRefresh}
                setFeedBack={setFeedBack}
                setErr={setErr}
                setSuccess={setSuccess}
                menus={menus}
                setActiveMenu={setActiveMenu}
                activeMenu={activeMenu}/>

            <div className="row">
                <div className="col-xxl-3 text-center text-xxl-start" ><h4 className='mt-4 ms-1'>Menus</h4></div>
                <div className="col-xxl-9 mt-xxl-1 text-center text-xxl-end">
                    <button data-bs-toggle="modal" data-bs-target={"#addMenuModal"}
                        className={`${st.addMenu} mt-2 mt-xxl-3`} onClick={() => setReset(!reset)} >Ajouter
                    </button>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col text-center">
                    {err && <span className='txtDanger' >{feedBack}</span>}
                    {success && <span className='txtSuccess' >{feedBack}</span>}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <div className={`${menus.length > 3 ? st.menusBox : st.minimizedMenuBox}`}>
                        {menus.map((menu, key) => (
                            <div
                                key={key}
                                onClick={() => setActiveMenu(menu)}
                                className={`${st.cardList} ${menu._id === activeMenu._id && st.selectedcardList} card mx-1`}
                            >
                                <div className="row">
                                    <div className="col relative">
                                        {edit === menu._id ? (
                                            <div>
                                                <div className={`${st.nameBox}`}>
                                                    <input type="text" className={`form-control ${st.nameControl}`}
                                                        onClick={(e) => { e.stopPropagation() }}
                                                        onKeyUp={(e) => e.key === 'Enter' && editMenuName(e, menu._id)}
                                                        onChange={(e) => handleNameChange(e.target.value, menu._id)} value={menu.name}
                                                    />
                                                    <Tooltip title="Enregistrer" arrow>
                                                        <Done className={`${st.saveMenu}`}
                                                            onClick={(e) => { editMenuName(e, menu._id) }} />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        ) : (
                                            <span >
                                                {menu.name}
                                                <span
                                                    onClick={(e) => { setEdit(menu._id); e.stopPropagation(); }}>
                                                    <Tooltip title="Modifer le nom de menu" arrow>
                                                        <Edit className={`${st.editName}`} />
                                                    </Tooltip>
                                                </span>
                                            </span>
                                        )}
                                        <Tooltip title="Supprimer" arrow>
                                            <Close data-bs-toggle="modal" data-bs-target={"#deleteMenuModal"}
                                                className={`${st.deleteMenu}`}
                                                onClick={(e) => { e.stopPropagation(); setSelectedMenu(menu) }}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MenuList;