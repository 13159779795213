import ClientNavbar from "../../components/navigation/client-navbar/client-navbar";
import st from "./security.module.css";
import ChangeEmail from "../../components/user-security/change-email/change-email";
import ChangePassword from "../../components/user-security/change-password/change-password";
import DeactivateAccount from "../../components/user-security/deactivate-account/deactivate-account";

function Security(props) {
  const { role } = props;

  return (
    <div className="clientPage">
      {role !== 'provider' && <ClientNavbar />}
      <div className={`container ${st.body}`}>
        <div className="row">
          <div className="col-xl-6">
            <ChangePassword />
          </div>
          <div className="col-xl-6">
            <div className="row">
              <div className="col-12">
                <ChangeEmail />
              </div>
            </div>
            {role === 'user' &&
              <div className="row">
                <div className="col-12">
                  <DeactivateAccount />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Security;
