import axios from 'axios'
axios.defaults.withCredentials = true;

//Add a new establishment
export const addEstablishment = (data, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/establishment/add`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Add a logo for establishment
export const addEstablishmentLogo = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/establishment/logo/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Add a QR code for establishment
export const addEstablishmentQrCode = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/establishment/qrcode/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Add a QR code for establishment
export const addEstablishmentPhotos = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/establishment/photos/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Edit photos from establishment
export const editEstablishmentPhotos = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/establishment/edit/photos/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Add a QR code for establishment
export const deleteEstablishmentPhoto = (id, data, callback) => {
    axios.delete(`${process.env.REACT_APP_BASE_URL}/establishment/photos/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//get all establishments
export const getEstablishmentsTypes = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/establishment/types`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//get all establishments
export const getEstablishmentsLocations = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/establishment/locations`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//get all establishments
export const getEstablishmentsNames = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/establishment/names`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//get all establishments
export const getEstablishments = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/establishment/list`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get an establishment by id
export const getEstablishmentById = (id, callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/establishment/one/${id}`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get establishments by provider id
export const getEstablishmentsByProviderId = (id, callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/establishment/list/provider/${id}`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get provider's establishments
export const getProviderEstablishments = (callback) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/establishment/provider`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Update an establishment
export const updateEstablishment = (id, data, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/establishment/update/${id}`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Delete an establishment
export const deleteEstablishment = (id, callback) => {
    axios.delete(`${process.env.REACT_APP_BASE_URL}/establishment/delete/${id}`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Toggle hide establishment
export const toggleHideEstablishment = (id, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/establishment/toggle-hide/${id}`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Toggle hide establishment
export const lockEstablishment = (id, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/establishment/lock/${id}`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Toggle hide establishment
export const archiveEstablishment = (id, callback) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/establishment/archive-estab/${id}`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

