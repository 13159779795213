import React, { useEffect, useState, useRef } from 'react';
import AdminSidebar from '../../components/admin/sidebar/admin-sidebar.js.js';
import st from "./adminInterface.module.css"
import UsersList from '../../components/admin/users/usersList/userList.js';
import ArchivedUsersList from '../../components/admin/users/archivedUsers.js/archivedUsersList.js';
import PartnershipRequests from '../../components/admin/providers/partnershipRequests/partnershipRequests.js';
import Home from '../home/home.js';
import AboutUs from '../aboutus/aboutus.js';
import ProvidersList from '../../components/admin/providers/providersList/providersList.js';
import Contact from '../contact/contact.js';
import Mailing from '../../components/admin/configurations/mailing/mailing.js';
import WebsiteInformations from '../../components/admin/configurations/website/websiteInformations.js';
import EstabConfig from '../../components/admin/configurations/establishments/estabConfig.js';
import EstabRequests from '../../components/admin/establishments/estabRequests/estabRequests.js';
import EstabsList from '../../components/admin/establishments/estabsList/estabsList.js';
import ArchivedPorivdersList from '../../components/admin/providers/archivedProvidersList/archivedProvidersList.js';
import ArchivedEstabList from '../../components/admin/establishments/archivedEstabsList/archivedEstabsList.js';
import { ToastContainer, toast } from 'react-toastify';
import Inbox from '../../components/admin/contact/inbox.js';
import AdminDashboard from '../adminDashboard/adminDashboard.js';

function AdminInterface(props) {
    const { user, website, refresh, setRefresh, establishments, menus } = props;
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [refreshComponents, setRefreshComponents] = useState(false);
    const [component, setComponent] = useState(() => sessionStorage.getItem("component") || "dashboard");
    const main = useRef();
    const previousComponent = useRef("dashboard");
    const [notifications, setNotifications] = useState({
        provider: [],
        establishment: []
    });

    const requestNotify = () => toast.warning("Nouvelle notification !, vérifiez vos pages de demandes.", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const messageNotify = () => toast.warning("Nouveau message !, vérifiez votre boîte de réception.", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    useEffect(() => {
        collapsed ? main.current.style.marginLeft = "80px" : main.current.style.marginLeft = "250px";
    }, [collapsed]);

    useEffect(() => {
        sessionStorage.setItem("component", component);

        const previousElement = document.getElementById(previousComponent.current);
        previousElement && (previousElement.style.display = "none");

        const element = document.getElementById(component);
        element && (element.style.display = "block");

        previousComponent.current = component;
    }, [component]);

    useEffect(() => {
        const storedComponent = sessionStorage.getItem("component");
        if (storedComponent) {
            setComponent(storedComponent);
        }
    }, []);




    return (
        <div>
            <ToastContainer limit={1} />
            <div className={`${st.adminBar}`}>
                <span onClick={() => { setCollapsed(!collapsed) }} className={`${st.sidebarToggler} bi bi-list`} style={{ marginRight: "10px" }}></span>
                <span onClick={() => { setToggled(!toggled); setCollapsed(false) }} className={`${st.sidebarToggler1} bi bi-list`} style={{ marginRight: "10px" }}></span>
                {website && website.name}
            </div>
            <div className={`${st.bigMain}`}>
                <div style={{ position: "absolute", width: '100%', height: '100%', overflowY: 'auto' }}>
                    <div id='main' ref={main} className={`${st.main}`}>
                        <div id='dashboard'><AdminDashboard establishments={establishments} menus={menus} /></div>

                        {/* Inbox */}
                        <div id='inbox' style={{ display: "none" }}><Inbox setNotifications={setNotifications} messageNotify={messageNotify} /></div>

                        {/* Website */}
                        <div id='home' style={{ display: "none" }}><Home user={user} website={website} establishments={establishments} menus={menus} /></div>
                        <div id='about' style={{ display: "none" }}><AboutUs user={user} website={website} /></div>
                        <div id='contact' style={{ display: "none" }}><Contact user={user} website={website} /></div>

                        {/* Providers */}
                        <div id='requestsList' style={{ display: "none" }}><PartnershipRequests setNotifications={setNotifications} requestNotify={requestNotify} refreshComponents={refreshComponents} /></div>
                        <div id='providersList' style={{ display: "none" }}><ProvidersList refreshComponents={refreshComponents} /></div>
                        <div id='archivedProvidersList' style={{ display: "none" }}><ArchivedPorivdersList refreshComponents={refreshComponents} /></div>

                        {/* Establishments */}
                        <div id='estabsRequests' style={{ display: "none" }}><EstabRequests setNotifications={setNotifications} requestNotify={requestNotify} refreshComponents={refreshComponents} /></div>
                        <div id='estabsList' style={{ display: "none" }}><EstabsList refreshComponents={refreshComponents} /></div>
                        <div id='archivedEstabsList' style={{ display: "none" }}><ArchivedEstabList refreshComponents={refreshComponents} /></div>

                        {/* Users */}
                        <div id='usersList' style={{ display: "none" }}><UsersList refreshComponents={refreshComponents} /></div>
                        <div id='archivedUsersList' style={{ display: "none" }}><ArchivedUsersList refreshComponents={refreshComponents} /></div>

                        {/* Configurations */}
                        <div id='mailConfig' style={{ display: "none" }}><Mailing website={website} refreshComponents={refreshComponents} /></div>
                        <div id='websiteInfos' style={{ display: "none" }}><WebsiteInformations website={website} refresh={refresh} setRefresh={setRefresh} refreshComponents={refreshComponents} /></div>
                        <div id='estabConfig' style={{ display: "none" }}><EstabConfig website={website} refreshComponents={refreshComponents} /></div>
                    </div>
                </div>
                <AdminSidebar
                    website={website}
                    collapsed={collapsed}
                    toggled={toggled}
                    setCollapsed={setCollapsed}
                    setToggled={setToggled}
                    setComponent={setComponent}
                    setRefreshComponents={setRefreshComponents}
                    notifications={notifications} />
            </div>
        </div>
    );
}

export default AdminInterface;
