import { useEffect } from 'react';
import st from './adminDashboard.module.css'
import { getEstabsRequests, getProviders, getRequests, getUsers } from '../../services/adminApis';
import { useState } from 'react';
import UsersChart from '../../components/admin/dashboardCharts/usersChart';
import EstabsRequestsChart from '../../components/admin/dashboardCharts/establishmentsRequestsChart';


import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MessageIcon from '@mui/icons-material/Message';
import { getContacts } from '../../services/contact';
import MenusPerDayChart from '../../components/admin/dashboardCharts/menusPerDayChart';
import UserPerDay from '../../components/admin/dashboardCharts/userPerDayChart';
import MessagesPerDay from '../../components/admin/dashboardCharts/messagesPerDayChart';
import ProvidersRequestsChart from '../../components/admin/dashboardCharts/providersRequestsChart';

function AdminDashboard(props) {
    const { establishments, menus } = props

    const [users, setUsers] = useState()
    const [activeUsers, setActiveUsers] = useState()
    const [suspendedUsers, setSuspendedUsers] = useState()
    const [timeoutedUsers, setTimeoutedUsers] = useState()

    const [providers, setProviders] = useState()
    const [contacts, setContacts] = useState()
    const [messages, setMessages] = useState()

    const [req, setReq] = useState()
    const [heldProviderReq, setHeldProviderReq] = useState()
    const [deniedProviderReq, setDeniedProviderReq] = useState()
    const [notTreatedProviderReq, setNotTreatedProviderReq] = useState()
    const [TreatedProviderReq, setTreatedProviderReq] = useState()

    const [estabRequests, setEstabRequests] = useState()
    const [heldRequests, setHeldRequests] = useState()
    const [deniedRequests, setDeniedRequests] = useState()
    const [notTreatedRequests, setNotTreatedRequests] = useState()
    const [TreatedRequests, setTreatedRequests] = useState()

    useEffect(() => {
        getUsers((cb) => {
            if (cb.status === 200) {
                setUsers(cb.data.activeUsers)
                setActiveUsers(cb.data.activeUsers.length)
                setSuspendedUsers(cb.data.suspendedUsers.length)
                setTimeoutedUsers(cb.data.timeoutedUsers.length)
            }
        })

        getProviders((cb) => {
            if (cb.status === 200) {
                setProviders(cb.data.providers.length)
            }
        })

        getEstabsRequests((cb) => {
            if (cb.status === 200) {
                setHeldRequests(cb.data.held.length)
                setDeniedRequests(cb.data.denied.length)
                setNotTreatedRequests(cb.data.notTreated.length)
                setTreatedRequests(cb.data.treated.length)
                setEstabRequests(cb.data.requests.length)
            }
        })

        getContacts((cb) => {
            if (cb.status === 200) {
                setContacts(cb.data.contacts)
                setMessages(cb.data.contacts.length)
            }
        })

        getRequests((cb) => {
            if (cb.status === 200) {
                setReq(cb.data.requests.length)
                setDeniedProviderReq(cb.data.denied.length)
                setHeldProviderReq(cb.data.held.length)
                setNotTreatedProviderReq(cb.data.notTreated.length)
                setTreatedProviderReq(cb.data.treated.length)
            }
        })
    }, [])

    return (
        <div className='container py-3'>
            <div className="row pb-4">
                <div className="col p-2" >
                    <div className={`p-3 ${st.card}`}>
                        <h6 className={`text-muted ${st.cardTitle}`}>Prestataires</h6>
                        <div className="position-relative">
                            <PersonIcon className={st.icon} /><h5 className='ms-1'>{providers}</h5>
                        </div>
                    </div>
                </div>
                <div className="col p-2" >
                    <div className={`p-3 ${st.card}`}>
                        <h6 className={`text-muted ${st.cardTitle}`}>Établissements</h6>
                        <div className="position-relative">
                            <StoreIcon className={st.icon} /><h5 className='ms-1'>{establishments.length}</h5>
                        </div>
                    </div>
                </div>
                <div className="col p-2" >
                    <div className={`p-3 ${st.card}`}>
                        <h6 className={`text-muted ${st.cardTitle}`}>Menus</h6>
                        <div className="position-relative">
                            <MenuBookIcon className={st.icon} /><h5 className='ms-1'>{menus.length}</h5>
                        </div>
                    </div>
                </div>
                <div className="col p-2">
                    <div className={`p-3 ${st.card}`}>
                        <h6 className={`text-muted ${st.cardTitle}`}>Messages</h6>
                        <div className="position-relative">
                            <MessageIcon className={st.icon} /><h5 className='ms-1'>{messages}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 pb-4">
                    <ProvidersRequestsChart requests={req} deniedRequests={deniedProviderReq} notTreatedRequests={notTreatedProviderReq} treatedRequests={TreatedProviderReq} heldRequests={heldProviderReq} />
                </div>
                <div className="col-lg-6 pb-4">
                    <EstabsRequestsChart requests={estabRequests} deniedRequests={deniedRequests} notTreatedRequests={notTreatedRequests} TreatedRequests={TreatedRequests} heldRequests={heldRequests} />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 pb-4">
                    <UserPerDay users={users} />
                </div>
                <div className="col-xl-4 pb-4">
                    <UsersChart activeUsers={activeUsers} suspendedUsers={suspendedUsers} timeoutedUsers={timeoutedUsers} />
                </div>
                <div className="col-xl-4 pb-4">
                    <MessagesPerDay contacts={contacts} />
                </div>
            </div>
            <div className="row ">
                <div className="col pb-4">
                    <MenusPerDayChart menus={menus} />
                </div>
            </div>

        </div>
    );
}

export default AdminDashboard;
