import { useRef } from "react";
import st from "./deleteCategory.module.css"
import { deleteCategory } from "../../../../services/menuApi";
function DeleteCategory(props) {
    const { setMenus, menuId, categories, setCategories, activeCategory, setActiveCategory, selectedCategory, setRefreshItems, setErr, setFeedBack, setSuccess } = props;
    const closeBtn = useRef(null);
    const removeCategory = () => {
        setErr(false);
        setSuccess(false);
        setFeedBack(false);
        deleteCategory({ menu: menuId, category: selectedCategory._id }, (cb) => {
            if (cb.status === 200) {
                setMenus(menus => menus.map(menu => menu._id === menuId ?
                    {
                        ...menu,
                        categories: categories.filter(cat => cat._id !== selectedCategory._id)
                    }
                    : menu
                ))
                setCategories(prevCategories => prevCategories.filter(cat => cat._id !== selectedCategory._id));
                setRefreshItems(r => !r)
                if (categories && selectedCategory._id === activeCategory._id) {
                    if (categories[0]._id !== selectedCategory._id) {
                        setActiveCategory(categories[0]);
                    } else {
                        setActiveCategory(categories[1]);
                    }
                }
                if (categories.length === 1) {
                    console.log("here");
                    setActiveCategory();
                }

                setSuccess(true);
                setFeedBack('Supprimé avec succès')
                closeBtn.current.click();
                return;
            } else {
                setErr(true);
                setFeedBack('Un probleme est servenue, réessayer plus tard!')
            }
        })
    }

    return (
        <div className={`modal fade`} id="deleteCategoryModal" tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-md`}>
                <div className={`modal-content ${st.modalContent}`}>
                    <div className="row relative">
                        <div className="col">
                            <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                            <h4 className='mt-4 ms-4'>Supprimer categorie</h4>
                        </div>
                    </div>
                    <div className="row mt-2 ps-4 pe-4">
                        <div className="col">
                            <span>Êtes-vous sur de vouloir supprimer {selectedCategory && selectedCategory.name} ?</span> <br />
                            <span>Tous les articles de cette catégorie seront supprimés ! </span>
                        </div>
                    </div>
                    <div className="row mt-4 mb-3 pe-md-3" >
                        <div className="col text-md-end text-center">
                            <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                            <button onClick={removeCategory} className={`${st.save}`}>Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteCategory;