import ReactECharts from 'echarts-for-react';

function ItemsPerMenu(props) {
    const { providerMenus  } = props

    const itemsPerMenu = providerMenus.map(menu => {
        let itemCount = 0;
        menu.categories.forEach(category => {
            itemCount += category.items.length;
        });
        return { value: itemCount, name: menu.name };
    });

    const option = {
        title: {
            text: "Acticles par menu",
            textStyle: {
                color: 'black'
            },
            left: '50%',
            top: '10%',
            textAlign: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: 10,
            left: 'center'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                center: ['50%', '70%'],
                startAngle: 180,
                endAngle: 360,
                data: itemsPerMenu
            }
        ]
    };

    return (
        <div className="card" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
            <ReactECharts option={option}  style={{ height: "300px", width: "100%" }} />
        </div>
    );
}

export default ItemsPerMenu;