import { useEffect, useState } from "react";
import { changeAccountEmail, checkAccountEmail, getUser } from "../../../services/userApi";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import st from "./change-email.module.css";

function ChangeEmail() {
  const [userEmail, setUserEmail] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState(false);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    getUser((cb) => {
      if (cb.status === 200) {
        setUserEmail(cb.data.user.email);
        setEmail(cb.data.user.email);
      }
    });
  }, []);

  const checkEmail = (event) => {
    event.preventDefault();
    let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!userEmail) {
      setErr("emailreq");
      return;
    }
    if (!emailPattern.test(userEmail)) {
      setErr("emailPattern");
      return;
    }
    checkAccountEmail({ email: userEmail }, (cb) => {
      setErr(false);
      setLoading(true);
      if (cb.status === 200) {
        setTimeout(() => { setLoading(false); setPassword(''); setValid(true); }, 2000);
      } else if (cb.response.status === 422) {
        setTimeout(() => { setLoading(false); setErr("inUse"); }, 2000);
        return;
      } else {
        setTimeout(() => { setLoading(false); setErr("server"); }, 2000);
        return;
      }
    });
  };

  const changeEmail = (event) => {
    event.preventDefault();
    if (!password) {
      setErr("passreq");
      return;
    }
    changeAccountEmail({ email: userEmail, password: password }, (cb) => {
      setErr(false);
      setLoading(true);
      if (cb.status === 200) {
        setTimeout(() => { setLoading(false); setErr("success"); }, 2000);
        setTimeout(() => { setErr(false); setValid(false); setPassword(""); }, 4000);
      } else if (cb.response.status === 401) {
        setTimeout(() => { setLoading(false); setErr('incorrect'); }, 2000);
      } else if (cb.response.status === 400) {
        setTimeout(() => { setLoading(false); setErr('google'); }, 2000);
      } else if (cb.response.status === 422) {
        setTimeout(() => { setLoading(false); setErr('passreq'); }, 2000);
      } else {
        setTimeout(() => { setLoading(false); setErr('server'); }, 2000);
      }
    });
  }
  return (
    <div className={`card mb-4 ps-4 pe-4 ${st.card}`}>
      <div className={`row pt-4 ${!valid && 'ps-4 pe-4'}`}>
        <h5 className={`${valid && 'px-0'}`}>
          {valid && <ArrowBackIcon onClick={() => { setValid(!valid) }} className={st.arrow} />}
          Changer votre Adresse E-mail
        </h5>
      </div>
      <div className={`row ps-4 pe-4 pb-3 ${st.transitioncontainer} ${valid ? st.slidefromright : ''}`}>
        {!valid ? (
          <div>
            <div className="row mb-2">
              <div className="col">
                <input
                  className={`mt-1 form-control ${(err === "emailreq" && !userEmail) || err === "inUse" || err === "emailPattern" ? "is-invalid" : ""}`}
                  type="text"
                  placeholder="Entrez une nouvelle adresse e-mail"
                  onChange={(e) => { setErr(false); setUserEmail(e.target.value); }}
                  name="email"
                  value={userEmail}
                />
              </div>
            </div>
            <div className="row mt-2" >
              <div className="col mb-2">
                <span className="txtDanger">
                  {err === "emailreq" && "Ce champ est obligatoire !"}
                  {err === "inUse" && "Cette adresse email est déjà utilisée !"}
                  {err === "emailPattern" && "Le format d'adresse e-email est invalide !"}
                </span>
              </div>
            </div>
            {loading &&
              <div className="row" style={{ height: '2rem' }}>
                <div className="col d-flex justify-content-center">
                  <span className={`${st.loader}`}></span>
                </div>
              </div>
            }
            <div className="row mt-2">
              <div className="col d-flex justify-content-center">
                <button className={`${st.resBtn}`} onClick={() => { setUserEmail(email); setErr(false) }}> Réinitialiser </button>
                <button className={`${st.myBtn}`} onClick={checkEmail}> Suivant </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="col">
                <label className="form-label small mb-1">
                  Votre mot de passe<span className="txtDanger">*</span>
                </label>
                <input
                  className={`form-control ${(err === "passreq" && !password) ? "is-invalid" : ""}`}
                  type={showPass ? "text" : "password"}
                  placeholder="Entrez votre mot de passe"
                  onChange={(e) => { setErr(false); setPassword(e.target.value); }}
                  name="password"
                  value={password}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-8 ">
                <span className="txtSuccess">
                  {err === "success" && "votre adresse e-mail a été modifiée avec succès"}
                </span>
                <span className="txtDanger">
                  {err === "passreq" && "Ce champ est obligatoire !"}
                  {err === "incorrect" && "Mot de passe incorrect !"}
                  {err === "google" && "C'est un compte Google !"}
                </span>
              </div>
            </div>
            <div className="row mt-2 mb-3">
              <div className="col-12">
                <div className={`form-check-reverse form-switch ${st.formCheck}`}>
                  <label className="form-check-label">Montrer le mot de passe</label>
                  <input className={`form-check-input ${st.formCheckInput}`} type="checkbox" checked={showPass} onChange={(e) => setShowPass(e.target.checked)} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8"></div>
              <div className="col-lg-4 text-end">
                {loading && <span className={`${st.loader}`}></span>}
                <button className={`${st.saveBtn}`} onClick={changeEmail}> Enregister </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChangeEmail;
