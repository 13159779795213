import { useRef } from "react";
import st from "./inspectMessage.module.css"

function InspectMessage(props) {
    const { message } = props;
    const closeBtn = useRef();
    return (
        <div className={`modal fade`} id="inspectMessage" tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-md`}>
                <div className={`modal-content ${st.modalContent}`}>
                    <div className="row relative">
                        <div className="col">
                            <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                            <h4 className='mt-4 ms-4'>Message de {message && message.firstname}</h4>
                        </div>
                    </div>
                    <div className="modal-body mt-1 px-0 ps-4 pe-4">
                        <div className="row ms-1 me-1">
                            <div className="col">
                                <p>{message && message.message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InspectMessage;