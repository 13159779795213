import {useState } from "react";
import ConfirmDeactivateAccount from "../../modals/confirm-deactivate-account/confirm-deactivate-account";
import st from "./deactivate-account.module.css";

function DeactivateAccount() {
  const [reset,setReset] = useState(false);
  return (
    <div>
      <div className={`card mb-4 ps-3 pe-3 ${st.card}`}>
        <div className={`row ps-4 pt-4`}>
          <h5>Désactiver votre compte</h5>
        </div>
        <div className="row ps-4 pe-4 pb-3">
          <p>
            La désactivation de votre compte est temporaire. Votre compte et
            votre profil seront désactivés et votre nom et vos photos seront
            supprimés.
          </p>
          <div className="text-end mt-2">
            <button
              className={`${st.delBtn}`} data-bs-toggle="modal" data-bs-target="#deleteUserModal" type="submit" onClick={()=>{setReset(!reset)}}
            >
              J'ai compris, désactiver mon compte
            </button>
          </div>
        </div>
      </div>
      <ConfirmDeactivateAccount reset={reset} />
    </div>
  );
}

export default DeactivateAccount;
