import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

function MessagesPerDay(props) {
    const { contacts } = props;
    const [msgs, setMsgs] = useState([]);

    useEffect(() => {
        if (contacts && contacts.length > 0) {
            const messagesPerDay = {};
            contacts.forEach(contact => {
                const msgDate = new Date(contact.createdAt).toLocaleDateString();
                if (messagesPerDay[msgDate]) {
                    messagesPerDay[msgDate]++;
                } else {
                    messagesPerDay[msgDate] = 1;
                }
            });
            const data = Object.entries(messagesPerDay).map(([date, count]) => ({
                date,
                count
            }));
            setMsgs(data);
        }
    }, [contacts]);

    const option = {
        title: {
            text: "Messages reçus par jour",
            textStyle: {
                color: 'black'
            },
            left: '50%',
            top: '5%',
            textAlign: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            data: msgs.map(item => item.date)
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: 'Messages reçus',
                data: msgs.map(item => item.count),
                type: 'scatter',
                smooth: true,
                itemStyle: {
                    color: '#ffcc44'
                }
            }
        ]
    };

    return (
        <div className="card" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
            <ReactECharts
                style={{ height: "300px", width: "100%" }}
                option={option}
            />
        </div>
    );
}

export default MessagesPerDay;
