import axios from 'axios'
axios.defaults.withCredentials = true;

//Sign Up
export const signup = (newUser, token, callback) => {
    const headers = {
        authorization: `Bearer ${token}`
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/auth`, newUser, { headers })
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Provider registration
export const providerSignup = (id, newUser, token, callback) => {
    const headers = {
        authorization: `Bearer ${token}`
    }
    axios.put(`${process.env.REACT_APP_BASE_URL}/auth/${id}`, newUser, { headers })
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Login 
export const login = (credentials, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, credentials)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Logout
export const logout = (callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {}, { withCredentials: true })
        .then((res) => callback(res))
        .catch((err) => callback(err));
}


//Login with google
export const loginWithGoogle = (code, callback) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/google/login`, { code })
        .then((res) => callback(res))
        .catch((err) => callback(err));
}
