import React, { useState, useEffect } from 'react';
import st from './providerRequest.module.css'

import { submitRequest } from '../../../services/website';
import MyLocationIcon from '@mui/icons-material/MyLocation';

import { getEstablishmentsTypes } from "../../../services/establishmentApi";
import io from "socket.io-client";

function ProviderRequest(props) {
    const { user, err, setErr, resetValues } = props;

    const [providedTypes, setProvidedTypes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [socket, setSocket] = useState(null);
    useEffect(() => {
        socketRefresh();
    }, []);

    const socketRefresh = () => {
        const newSocket = io.connect(process.env.REACT_APP_SOCKET_URL);
        setSocket(newSocket);
        return () => {
            newSocket.disconnect();
        };
    }

    const initialValues = {
        lastname: "",
        firstname: "",
        email: "",
        phone: "",
        estabname: "",
        location: "",
        type: []
    }
    const [formValues, setFormValues] = useState(initialValues);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getEstablishmentsTypes((cb) => {
            setProvidedTypes(cb.data.establishmentsTypes);
        })
    }, [])

    const toggleSelectCard = (type) => {
        setErr(false);
        let updatedSelectedTypes;
        if (selectedTypes.includes(type)) {
            if (selectedTypes.length > 1) {
                updatedSelectedTypes = selectedTypes.filter((selectedType) => selectedType !== type);
            }
        } else {
            updatedSelectedTypes = [...selectedTypes, type];
        }
        if (updatedSelectedTypes) {
            setSelectedTypes(updatedSelectedTypes);
        }
    }

    useEffect(() => {
        if (user) {
            setFormValues({
                lastname: user.lastname || "",
                firstname: user.firstname || "",
                email: user.email || "",
                phone: user.phone || "",
                estabname: "",
                location: "",
                type: []
            });
        } else {
            setFormValues({
                lastname: "",
                firstname: "",
                email: "",
                phone: "",
                estabname: "",
                location: "",
                type: []
            });
        }
        setSubmitted(false);
        setSelectedTypes([]);
    }, [user, resetValues]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErr(false);
        setFormValues({ ...formValues, [name]: value });
    }

    const submit = () => {
        if (selectedTypes.length < 1) {
            setErr("type");
            return;
        }
        let fv = formValues;
        fv.type = selectedTypes;
        const empty = Object.values(fv).some(value => value === "");
        if (empty) {
            setErr("req");
            return;
        }
        let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(fv.email)) {
            setErr("emailPattern");
            return;
        }
        setLoading(true);

        submitRequest(fv, (cb) => {
            if (cb.status === 200) {
                socket.emit('sendProviderRequest', { request: cb.data.request });
                socketRefresh();
                setErr(false);
                setTimeout(() => {
                    setLoading(false);
                    setFormValues(initialValues);
                    setSubmitted(true);
                }, 500);
            } else if (cb.response) {
                setTimeout(() => {
                    if (cb.response.status === 409) {
                        if (cb.response.data.duplicated === "phone") {
                            setErr("phone");
                            setLoading(false);
                            return;
                        }
                        if (cb.response.data.duplicated === "email") {
                            setErr("email"); setLoading(false); return;
                        }
                    }
                    else {
                        setTimeout(() => {
                            setLoading(false);
                            setErr("server")
                        }, 500);
                        console.error(cb);
                    }
                }, 500);
            }
        })
    }



    return (
        <div className={`modal fade ${st.modal}`} id="providerRequest" tabIndex="1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className={`modal-content ${!submitted && st.modalContent}`}>
                    {
                        loading ? (
                            <span className={`m-5 ${st.loaderBox}`}><span className={`${st.loader}`}></span></span>
                        ) :
                            (<div className="modal-body">
                                <div className="row p-1">
                                    <div className="col-10"><h3>Rejoignez-nous</h3></div>
                                    <div className="col-2 text-end"><button id='closePorviderRequest' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                                </div>

                                {
                                    submitted ? (<>
                                        <div className='row'>
                                            <div className="col px-4">
                                                <p>Votre demande a été soumise avec succès. Vous recevrez un email dès que celle-ci sera traitée.</p>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col text-sm-end text-center">
                                                <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                            </div>
                                        </div>
                                    </>

                                    ) : (
                                        <>
                                            <div className="row px-2">
                                                <p>Pour devenir prestataire, veuillez remplir ce formulaire afin de gérer votre demande. (<span >Tous les champs sont obligatoires</span>).</p>
                                            </div>
                                            <form className='px-2'>
                                                <div className="row">
                                                    <div className="col px-0">
                                                        <hr className='m-0 p-0' />
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col ps-1 pe-0">
                                                        <div>
                                                            <h5>Informations personnelles</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Nom<span className="text-danger">*</span></label>
                                                            <input type="text" placeholder='Entrer votre nom' value={formValues.lastname} onChange={handleChange} name="lastname"
                                                                className={`form-control ${(err && !formValues.lastname) && "is-invalid"}`} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Prénom<span className="text-danger">*</span></label>
                                                            <input type="text" placeholder='Entrer votre prénom' value={formValues.firstname} onChange={handleChange} name="firstname"
                                                                className={`form-control ${(err && !formValues.firstname) && "is-invalid"}`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Adresse e-mail<span className="text-danger">*</span></label>
                                                            <input type="text" placeholder='Entrer votre adresse email' value={formValues.email} onChange={handleChange} name="email"
                                                                className={`form-control ${((err && !formValues.email) || err === 'email' || err === 'emailPattern') && "is-invalid"}`} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Numéro de téléphone<span className="text-danger">*</span></label>
                                                            <input type="tel" placeholder='Entrer votre téléphone' value={formValues.phone} onChange={handleChange} name="phone"
                                                                className={`form-control ${((err && !formValues.phone) || err === 'phone') && "is-invalid"}`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col px-0">
                                                        <hr className='m-0 p-0' />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col ps-1 pe-0">
                                                        <div>
                                                            <h5>Informations sur l'établissement</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-lg-6 mb-2">
                                                        <div className={`form-group`}>
                                                            <label className='form-label'>Nom de l'établissement<span className="text-danger">*</span></label>
                                                            <input type="text" name='estabname' placeholder="Nom d'établissement"
                                                                className={`form-control ${err && !formValues.estabname && "is-invalid"}`}
                                                                value={formValues.estabname} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-2">
                                                        <div className={`form-group`}>
                                                            <label className='form-label'>Adresse<span className="text-danger">*</span></label>
                                                            <div className={`${st.myLocation}`}>
                                                                <input type="text" value={formValues.location}
                                                                    onChange={handleChange} name='location'
                                                                    placeholder="EX : Midoun, Djerba, Tunisie" className={`form-control ${st.location} ${err && !formValues.location && "is-invalid"}`} />
                                                                <div className={`${st.myLocationIcon}`}>
                                                                    <MyLocationIcon />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col mb-2">
                                                        <div className={`position-relative ${st.typesContainer}`}>
                                                            <label className='form-label'>Type d'établissement<span className="text-danger">*</span></label>
                                                            <div className="row px-5">
                                                                {
                                                                    providedTypes.map((type, key) => (
                                                                        <div key={key} onClick={() => { toggleSelectCard(type) }}
                                                                            className={`${selectedTypes && selectedTypes.includes(type) ? st.selectedCard : st.card} col-lg m-2 position-relative card py-2 d-flex align-items-center justify-content-center`}>
                                                                            <span>{type}</span>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row p-2">
                                                <div className="col text-center">
                                                    <span className="text-danger">
                                                        {err === "type" && "Vous devez sélectionner au moins un type"}
                                                        {err === "req" && "Tous les champs sont obligatoires"}
                                                        {err === "emailPattern" && "Le format d'adresse e-email est invalide !"}
                                                        {err === "phone" && "Le numéro de téléphone est déja utilisé"}
                                                        {err === "email" && "L'adresse email est déja utilisé"}
                                                        {err === "server" && "Un problem est survenue, réessayer plus tard !"}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col text-sm-end text-center">
                                                    <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                                    <button onClick={submit} className={`${st.save}`}>Enregistrer</button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div >)
                    }


                </div >
            </div >
        </div >
    );
}

export default ProviderRequest;