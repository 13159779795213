import { getEstablishmentsTypes } from "../../../../services/establishmentApi";
import st from "./estabConfig.module.css"
import { useEffect, useRef, useState } from "react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Tooltip from '@mui/material/Tooltip';
import Done from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { addEstablishmentHighlight, deleteEstablishmentHighlight, getEstablishmentHighlights, setEstablishmentsTypes } from "../../../../services/website";

function EstabConfig() {
    const [loaded, setLoaded] = useState(false);
    const [types, setTypes] = useState([]);
    const [err, setErr] = useState(false);
    const [success, setSuccess] = useState(false);
    const [type, setType] = useState("");

    const [confirm, setConfirm] = useState(false);

    const [highlights, setHighlights] = useState([]);
    const [highlightPhoto, setHighlightPhoto] = useState();
    const [highlightName, setHighlightName] = useState("");
    const initialImgSrc = "/images/uploadLogo.png";
    const imgView = useRef(null);
    const inputFile = useRef(null);


    useEffect(() => {
        var estabs = false;
        var hg = false;
        getEstablishmentsTypes((cb) => {
            if (cb.data && cb.data.establishmentsTypes) {
                setTypes(cb.data.establishmentsTypes);
            }
            estabs = true;
            if (estabs && hg) {
                setLoaded(true);
            }
        })
        getEstablishmentHighlights((cb) => {
            if (cb.data && cb.data.highlights) {
                setHighlights(cb.data.highlights);
            }
            hg = true;
            if (estabs && hg) {
                setLoaded(true);
            }
        })
    }, []);

    const handleTypechange = (e) => {
        setType(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    }

    const createType = () => {
        setErr(false);
        setSuccess(false);
        if (type !== "") {
            let newTypes = [...types, type];
            setEstablishmentsTypes({ types: newTypes }, (cb) => {
                if (cb.status === 200) {
                    setTypes(newTypes);
                    setType("");
                    setSuccess("type");
                    setTimeout(() => {
                        setSuccess(false);
                    }, 1500);
                    return;
                } else {
                    setTimeout(() => {
                        setErr(false);
                    }, 2000);
                }
            })
        }
    }

    const deleteType = (index) => {
        setErr(false);
        setSuccess(false);
        let newTypes = [...types.filter((t, i) => i !== index)];
        setTypes(newTypes)
        setEstablishmentsTypes({ types: newTypes }, (cb) => {
            if (cb.status === 200) {
                setSuccess("type");
                setTimeout(() => {
                    setSuccess(false);
                }, 2000);
                return;
            } else {
                setErr("type");
                setTimeout(() => {
                    setErr(false);
                }, 1500);
            }
        })
    }

    const uploadImage = (e) => {
        setErr(false)
        if (inputFile.current.files.length > 0) {
            const imgLink = URL.createObjectURL(inputFile.current.files[0]);
            imgView.current.src = imgLink;
            setHighlightPhoto(inputFile.current.files[0]);
        } else {
            console.error("No file selected.");
            setErr("emptyFile");
        }
    };

    const drop = (e) => {
        e.preventDefault();
        inputFile.current.files = e.dataTransfer.files;
        uploadImage();
    }

    const createHighlight = () => {
        setErr(false);
        if (!highlightName) {
            setErr("highlightReq");
            return;
        }
        const formData = new FormData();
        if (highlightPhoto instanceof File) {
            formData.append("photo", highlightPhoto);
        } else {
            setErr("highlightReq");
            return;
        }
        formData.append("name", highlightName);
        addEstablishmentHighlight(formData, (cb) => {
            if (cb.status === 200) {
                setSuccess("highlightAdded")
                setHighlights(cb.data.highlights);
                inputFile.current.value = null;
                imgView.current.src = initialImgSrc;
                setHighlightName('');
                setTimeout(() => {
                    setSuccess(false);
                    setErr(false);
                }, 1500);
            }
        })
    }

    const removeHighlight = (id) => {
        deleteEstablishmentHighlight(id, cb => {
            if (cb.status === 200) {
                setHighlights(prv => prv.filter(h => h._id !== id));
                setSuccess("deletedHighlight");
                setTimeout(() => {
                    setSuccess(false);
                    setErr(false);
                }, 1500);
            } else {
                setErr()
            }
        })
    }

    return (
        <div className="container-fluid p-4">
            <div className="row">
                <div className="col ps-5">
                    <h3>Configuration d'établissements</h3>
                </div>
            </div>

            {loaded ?
                (
                    <>
                        <div className="row mt-2">
                            <div className="col ps-5">
                                <h5>Les types disponibles des établissements :</h5>
                            </div>
                        </div>
                        <div className="row mb-2 px-sm-5 mt-3">
                            <div className="col px-5">
                                <div className={`${st.addType}`}>
                                    <input value={type} onChange={handleTypechange} onKeyUp={(e) => e.key === 'Enter' && createType()}
                                        placeholder='Ajouter un type' className={`form-control ${st.typeControl}`} type="text" />
                                    <i className={`${st.saveType}`}>
                                        <Tooltip title="Enregistrer" arrow>
                                            <Done onClick={createType} />
                                        </Tooltip>
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <span className={err ? "txtDanger" : success ? "txtSuccess" : ""}>
                                    {err === "type" && "Un problème est survenu."}
                                    {success === "type" && "Enregistré avec succès"}
                                </span>
                            </div>
                        </div>
                        <div className={`${st.cardsBox} row mt-3 d-flex justify-content-center`}>
                            {types && types.length > 0 && types.map((type, index) => (
                                <div key={index} className={`${st.card} mt-2 mx-1 position-relative`}>
                                    <div className={`${st.cardName}`}>
                                        {type}
                                    </div>
                                    <div className={`${st.cardDel}`}>
                                        <CloseOutlinedIcon onClick={() => { deleteType(index) }} />
                                    </div>
                                </div>
                            ))}

                        </div>
                        <div className="row mt-2">
                            <div className="col ps-5">
                                <h5>Les commodités disponibles des établissements :</h5>
                            </div>
                        </div>
                        <div className="row mt-4 mb-2">
                            <div className={`col-3 ${st.photoColumn}`}
                                onDragOver={(e) => { e.preventDefault(); }}
                                onDrop={drop}>
                                <input ref={inputFile} type="file" accept="image/*" onChange={uploadImage} hidden />
                                <img id="img-view" ref={imgView} className={`object-fit-fill ${st.photo}`}
                                    src={initialImgSrc} alt="" onClick={() => inputFile.current.click()} />
                            </div>
                            <div className="col-9">
                                <div className={`${st.addHighlight} me-sm-5 mt-3`}>
                                    <input onKeyUp={(e) => e.key === 'Enter' && createHighlight()}
                                        onChange={(e) => setHighlightName(e.target.value)} value={highlightName}
                                        placeholder='Ajouter une commodité' className={`form-control ${st.highlightControl}`} type="text" />
                                    <i className={`${st.saveHighlight}`}>
                                        <Tooltip title="Enregistrer" arrow>
                                            <Done onClick={createHighlight} />
                                        </Tooltip>
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <span className={err ? "txtDanger" : success ? "txtSuccess" : ""}>
                                    {err === "highlightReq" && "Les deux champs sont obligatoires !."}
                                    {success === "highlightAdded" && "Enregistré avec succès"}
                                    {success === "deletedHighlight" && "Supprimé avec succès"}
                                </span>
                            </div>
                        </div>
                        <div className="row mt-4 d-flex justify-content-center">
                            {highlights.length > 0 && highlights.map((h, i) => (
                                <div key={i} className={`${st.box} text-center position-relative`}>
                                    <img className={`mt-2 ${confirm === h._id ? st.deletingIcon : st.icon}`} src={`${process.env.REACT_APP_SERVER_PUBLIC}${h.photo}`} alt="" />
                                    <h6 className={`mt-2 ${confirm === h._id && st.deletingTitle}`}>{h.name}</h6>
                                    <ClearIcon onClick={() => setConfirm(h._id)}
                                        className={`position-absolute top-0 end-0 me-2 mt-2 ${st.deleteIcon}`} />
                                    {
                                        confirm === h._id && <div className={`${st.confirmBox}`}>
                                            <span className={`${st.confirmTxt}`} onClick={() => removeHighlight(h._id)}>Confirmer</span>
                                            <span className={`${st.cancelTxt}`} onClick={() => setConfirm(false)}>Annuler</span>
                                        </div>
                                    }

                                </div>
                            ))}
                        </div>
                    </>
                ) :
                (
                    <div className={`${st.loadingBox}`}>
                        <span className={`${st.loader}`} ></span>
                    </div>
                )
            }
        </div >
    );
}

export default EstabConfig;