import { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

function UserPerDay(props) {
    const { users } = props;
    const [user, setUser] = useState([]);

    useEffect(() => {
        if (users && users.length > 0) {
            const usersPerDay = {};
            users.forEach(user => {
                const reqiterDate = new Date(user.createdAt).toLocaleDateString();
                if (usersPerDay[reqiterDate]) {
                    usersPerDay[reqiterDate]++;
                } else {
                    usersPerDay[reqiterDate] = 1;
                }
            });

            const data = Object.entries(usersPerDay).map(([date, count]) => ({ date, count }));
            setUser(data);
        }
    }, [users]);

    const option = {
        title: {
            text: "Utilisateurs inscrits par jour",
            textStyle: {
                color: 'black'
            },
            left: '50%',
            top: '5%',
            textAlign: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            data: user.map(item => item.date)
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: 'Utilisateurs inscrits par jour',
                data: user.map(item => item.count),
                type: 'line',
                smooth: true,
                lineStyle: {
                    color: '#ffcc44'
                },
                itemStyle: {
                    color: 'black'
                }
            }
        ]
    };

    return (
        <div className="card" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
            <ReactECharts
                style={{ height: "300px", width: "100%" }}
                option={option}
            />
        </div>
    );
}

export default UserPerDay;
