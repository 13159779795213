import "./App.css";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { logout } from "./services/authApi";
import { googleLogout } from "@react-oauth/google";

import Home from "./pages/home/home";
import Login from "./pages/login/login";
import Signup from "./pages/signup/signup";
import ResetPassword from "./pages/resetPassowrd/resetPassword";
import AboutUs from "./pages/aboutus/aboutus";

import AppNavbar from "./components/navigation/app-navigation/app-navbar";
import AdminInterface from "./pages/admin-interface/adminInterface";
import { getUser } from "./services/userApi";
import { getWebsiteInfos } from "./services/website";
import Profile from "./pages/profile/profile";
import Security from "./pages/security/security";
import Dashboard from "./pages/dashboard/dashboard";
import Loading from "./pages/loading/loading";
import Contact from "./pages/contact/contact";
import Blog from "./pages/blog/blog";
import ClientNavbar from "./components/navigation/client-navbar/client-navbar";
import EstablishmentsList from "./pages/establishments/establishmentsList/establishmentsList";
import EstablishmentRequest from "./pages/establishments/establishmentRequest/establishmentRequest";
import { getEstablishments } from "./services/establishmentApi";
import EstablishmentsPage from "./pages/establishments/establishmentPage/establishmentPage";
import EditEstablishment from "./pages/establishments/editEstablishment/editEstablishment";
import Menu from "./pages/menus/menu";
import { getAllMenus } from "./services/menuApi";
import NotFound from "./pages/notFound/notFound";
import EstabMenu from "./pages/estabMenu/estabMenu";

function App() {
  const [websiteInfos, setWebsiteInfos] = useState();
  const [refresh, setRefresh] = useState();
  const [user, setUser] = useState(localStorage.getItem("username") || sessionStorage.getItem("username"));
  const [expiresAt, setExpiresAt] = useState(localStorage.getItem("expiresAt") || sessionStorage.getItem("expiresAt"));
  //const role = localStorage.getItem("role") || sessionStorage.getItem("role");
  const [role, setRole] = useState("");
  const [userLogged, setUserLogged] = useState({});
  const [establishments, setEstablishments] = useState([]);
  const [menus, setMenus] = useState([]);

  const [loaded, setLoaded] = useState(false);



  const signout = () => {
    logout((cb) => {
      if (cb.status === 200) {
        localStorage.clear();
        sessionStorage.clear();
        googleLogout();
        setUser(null);
        setExpiresAt(null);
        window.location.href = "/login";
      } else {
        console.error(cb);
      }
    });
  }

  useEffect(() => {
    getWebsiteInfos((cb) => {
      if (cb.status === 200) {
        setWebsiteInfos(cb.data.infos);
        document.getElementById("website-title").textContent = cb.data.infos.name;
        if (cb.data.infos.logo) {
          document.getElementById("website-icon").href = process.env.REACT_APP_SERVER_PUBLIC + "" + cb.data.infos.logo;
        }
      }
    })
    getEstablishments((cb) => {
      if (cb.status === 200) {
        setEstablishments(cb.data.establishments);
      }
    })
    getAllMenus((cb)=>{
      if (cb.status === 200) {
        setMenus(cb.data.menus);
      }
    })
  }, [refresh])


  useEffect(() => {
    if (user) {
      getUser((cb) => {
        if (cb.status === 200) {
          setUserLogged(cb.data.user);
          setRole(cb.data.user.role)
          if (cb.data.user.isSuspended || (new Date(cb.data.user.timeout) > new Date())) {
            signout();
          }
        } else {
          localStorage.clear();
          sessionStorage.clear();
          googleLogout();
          setUser(null);
          setExpiresAt(null);
          window.location.href = "/login";
        }
      })
    }
  }, [user, refresh])

  useEffect(() => {
    const today = new Date().getTime();
    const timeDifference = expiresAt - today;
    if (timeDifference <= 0) {
      if (expiresAt) {
        signout();
      }
    }

  }, [expiresAt]);

  useEffect(() => {
    if (websiteInfos && userLogged && establishments && menus && !loaded) {
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    }
  }, [websiteInfos, userLogged, establishments, menus, loaded])
  return (
    <>
      {loaded ? (<Router>
        {user && userLogged && <AppNavbar user={userLogged} website={websiteInfos} />}
        {role && role === 'provider' && <ClientNavbar role={userLogged.role} />}
        <Routes>
          <Route path="/" element={user && userLogged && userLogged.role === "admin" ? <Navigate to="/admin" /> : <Home user={userLogged} website={websiteInfos} establishments={establishments} menus={menus} />} />
          <Route path="/about-us" element={user && userLogged && userLogged.role === "admin" ? <Navigate to="/admin" /> : <AboutUs user={userLogged} website={websiteInfos} />} />
          <Route path="/blog" element={user && userLogged && userLogged.role === "admin" ? <Navigate to="/admin" /> : <Blog user={userLogged} website={websiteInfos} />} />
          <Route path="/contact" element={user && userLogged && userLogged.role === "admin" ? <Navigate to="/admin" /> : <Contact user={userLogged} website={websiteInfos} />} />
          

          {establishments && establishments.filter(establishment => !establishment.hidden && !establishment.locked && !establishment.deletesAt && new Date(establishment.expiresAt) > new Date()).map((establishment, key) => (
            <Route
              key={key}
              path={`/place/${establishment.customUrl}`}
              element={<EstablishmentsPage user={userLogged} website={websiteInfos} establishment={establishment} />}
            />
          ))}
          {menus && menus.map((m, k)=>
            <Route key={k} path={`/menu/${m._id}`} element={<EstabMenu selectedMenu={m} user={userLogged} website={websiteInfos} />} />
          )}
          <Route path="/admin" element={userLogged.role === "admin" ?
            <AdminInterface user={userLogged} website={websiteInfos} establishments={establishments} menus={menus} refresh={refresh} setRefresh={setRefresh} /> : <Navigate to="/" />} />
          {user && role === "admin" && <Route path="edit-establishment/:id" element={<EditEstablishment website={websiteInfos} setRefresh={setRefresh} role={role} />} />}
          {user && role === "admin" && <Route path="establishment/:id/menus" element={<Menu user={userLogged} website={websiteInfos} />} />}
          {user ?
            <Route path="/account">
              <Route path="profile" element={<Profile website={websiteInfos} refresh={refresh} setRefresh={setRefresh} role={role} />} />
              <Route path="security" element={<Security website={websiteInfos} role={role} />} />
              {role === "provider" && <Route path="dashboard" element={<Dashboard role={role} website={websiteInfos} />} />}
              {role === "provider" && <Route path="establishments" element={<EstablishmentsList user={userLogged} website={websiteInfos} />} />}
              {role === "provider" && <Route path="establishment-request" element={<EstablishmentRequest user={userLogged} website={websiteInfos} />} />}
              {role === "provider" && <Route path="edit-establishment/:id" element={<EditEstablishment user={userLogged} website={websiteInfos} setRefresh={setRefresh} />} />}
              {role === "provider" && <Route path="establishment/:id/menus" element={<Menu user={userLogged} website={websiteInfos} />} />}
            </Route>

            :
            <Route path="*" element={<Navigate to="/login" />} />}

          {user ? (
            <>
              <Route path="/login" element={<Navigate to="/" />} />
              <Route path="/signup" element={<Navigate to="/" />} />
              <Route path="/reset-password" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login website={websiteInfos} />} />
              <Route path="/signup" element={<Signup website={websiteInfos} />} />
              <Route path="/reset-password" element={<ResetPassword website={websiteInfos} />} />
            </>
          )}
          <Route path="*" element={<NotFound user={userLogged} website={websiteInfos} />} />
        </Routes>
      </Router>) : (<Loading />)}
    </>

  );
}

export default App;
