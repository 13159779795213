import { useEffect, useState } from "react";
import st from "./socials.module.css";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

function Socials(props) {
    const { oldSocials, setSocials } = props;
    const [socialMedia, setSocialMedia] = useState([
        { name: "Facebook", url: oldSocials && oldSocials.name === "Facebook" ? oldSocials.url : "" },
        { name: "LinkedIn", url: "" },
        { name: "Pinterest", url: "" },
        { name: "Instagram", url: "" },
        { name: "X", url: "" },
        { name: "Tiktok", url: "" },
        { name: "Email", url: "" }
    ]);
    useEffect(() => {
        if (oldSocials) {
            setSocialMedia(prevSocialMedia => prevSocialMedia.map(sm => ({
                ...sm,
                url: oldSocials.find(social => social.name === sm.name)?.url || ""
            })))

            setSocials(prv=>prv.map(sm => ({
                ...sm,
                url: oldSocials.find(social => social.name === sm.name)?.url || ""
            })))
        }
    }, [oldSocials, setSocials]) 



    const getPlaceholder = (name) => {
        switch (name) {
            case "Facebook":
                return "www.facebook.com";
            case "Instagram":
                return "www.instagram.com";
            case "X":
                return "www.x.com";
            case "LinkedIn":
                return "www.linkedin.com";
            case "Pinterest":
                return "www.pinterest.com";
            case "Tiktok":
                return "www.tiktok.com";
            case "Email":
                return "john-doe@example.com";
            default:
                return "";
        }
    };

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        const updatedSocialMedia = [...socialMedia];
        updatedSocialMedia[index].url = value;
        setSocialMedia(updatedSocialMedia);
        setSocials(updatedSocialMedia);
    };

    return (
        <div className={`${st.openHours}`}>
            <div className={`${st.container}`}>
                {socialMedia.map(({ name, url }, key) => (
                    <div key={key} className="row mt-2 ">
                        <div className="col d-flex justify-content-center">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ minWidth: '30px', width: '30px' }}>
                                            {name === "Facebook" && <FacebookOutlinedIcon className={`${st.icons} ${st.fb}`} />}
                                            {name === "Instagram" && <InstagramIcon className={`${st.icons} ${st.instagram}`} />}
                                            {name === "X" && <XIcon className={`${st.icons} ${st.x}`} />}
                                            {name === "Pinterest" && <PinterestIcon className={`${st.icons} ${st.pinterest}`} />}
                                            {name === "LinkedIn" && <LinkedInIcon className={`${st.icons} ${st.linkedin}`} />}
                                            {name === "Tiktok" && <i className={`bi bi-tiktok ${st.tiktok}`}></i>}
                                            {name === "Email" && <AlternateEmailIcon className={`${st.icons} ${st.email}`} />}
                                        </td>
                                        <td style={{ minWidth: '170px', width: '400px' }}>
                                            <input
                                                type="text"
                                                name={name}
                                                value={url}
                                                onChange={(e) => handleInputChange(e, key)}
                                                className={`form-control ${st.formControl}`}
                                                placeholder={getPlaceholder(name)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Socials;
