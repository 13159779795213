import { Link, useLocation } from "react-router-dom";
import st from "./client-navbar.module.css";
function ClientNavbar(props) {
  const {role}= props
  const location = useLocation();

  return (
    <div className={`fixed-top ${st.navbar}`}>
      <div className="container">
        <nav>
          <ul className={`${st.navList}`}>
          {role === 'provider' &&
            <>
            <li className={`${st.navListLinks}`}>
              <Link to="/account/dashboard" className={`${st.navLinks} ${location.pathname === '/account/dashboard' ? st.active : ''}`}>Tableau de bord</Link>
            </li>
            <li className={`${st.navListLinks}`}>
              <Link to="/account/establishments" className={`${st.navLinks} ${location.pathname === '/account/establishments' ? st.active : ''}`}>Établissements</Link>
            </li>
            </>
            }
            <li className={`${st.navListLinks}`}>
              <Link to="/account/profile" className={`${st.navLinks} ${location.pathname === '/account/profile' ? st.active : ''}`}>Profil</Link>
            </li>
            <li className={`${st.navListLinks}`}>
              <Link to="/account/security" className={`${st.navLinks} ${location.pathname === '/account/security' ? st.active : ''}`}>Sécurité</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default ClientNavbar;
