import st from './photos.module.css';
import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useEffect, useState } from 'react';
import { editEstablishmentPhotos } from '../../../../services/establishmentApi';

function Photos(props) {
    const { establishment } = props;
    const establishmentId = establishment ? establishment._id : "";

    const [droppedImages, setDroppedImages] = useState([]);
    const [imageCount, setImageCount] = useState(0);
    const [imagesSuccess, setImagesSuccess] = useState(false);
    const [imagesLoading, setImagesLoading] = useState(false);

    const [photos, setPhotos] = useState([]);
    const [photosToDelete, setPhotosToDelete] = useState([]);

    useEffect(() => {
        if (establishment && establishment.photos.length > 0) {
            setPhotos(establishment.photos);
            setImageCount(establishment.photos.length);
        }
    }, [establishment])

    const handleProvidedPhotosRemove = (id) => {
        setImagesLoading(false);
        const photoToDelete = photos.find((photo) => photo._id === id)
        setPhotosToDelete([...photosToDelete, photoToDelete]);
        setPhotos(photos.filter((photo) => photo._id !== id));
        setImageCount(prevCount => prevCount - 1);
    };


    const handleImageDrop = (e) => {
        e.preventDefault();
        setImagesLoading(false);
        const newImages = Array.from(e.dataTransfer.files).filter((file, index) => index < 5 - imageCount);
        setDroppedImages(prevImages => [...prevImages, ...newImages]);
        setImageCount(prevCount => prevCount + newImages.length);
    }

    const handleInputChange = (e) => {
        setImagesLoading(false);
        const newImages = Array.from(e.target.files).filter((file, index) => index < 5 - imageCount);
        setDroppedImages(prevImages => [...prevImages, ...newImages]);
        setImageCount(prevCount => prevCount + newImages.length);
    }

    const handleImageRemove = (indexToRemove) => {
        setImagesLoading(false);
        setDroppedImages(prevImages =>
            prevImages.filter((_, index) => index !== indexToRemove)
        );
        setImageCount(prevCount => prevCount - 1);
    };

    const submitPhotos = () => {
        const formData = new FormData();

        droppedImages.forEach((image) => {
            formData.append(`photos`, image);
        });

        photosToDelete.map((photo, i) =>
            formData.append(`deletingPhotos${i}`, photo._id)
        );

        setImagesLoading(true);

        editEstablishmentPhotos(establishmentId, formData, (cb) => {
            if (cb.status === 200) {
                setPhotos(cb.data.photos);
                setDroppedImages([]);
                setImageCount(cb.data.photos.length);
                setTimeout(() => {
                    setImagesLoading(false);
                }, 500);
                setTimeout(() => {
                    setImagesSuccess(true);
                }, 500);
                setTimeout(() => {
                    setImagesSuccess(false);
                }, 3000);
            } else {
                setImagesLoading(false);
            }
        })
    }

    return (
        <div className={`card mt-1 p-4 ${st.mediaCard}`}>
            <div className="row ps-3 mb-2 relative">
                <div className="col">
                    <small><span style={{ fontWeight: "500", fontSize: "1.25rem" }} >Photos du couverture :</span> (5 photos maximum)</small>
                    <Tooltip
                        title="Faites glisser et déposez ou cliquez sur < Ajouter une photo > pour télécharger vos photos"
                        arrow>
                        <ErrorOutlineIcon className={`${st.information}`} />
                    </Tooltip>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                {photos.length > 0 && photos.map((photo, index) => (
                    <div key={index} className={`col-sm-4 p-2 ${st.droppedImage}`}>
                        <ClearIcon className={`${st.undoPicture}`} onClick={() => handleProvidedPhotosRemove(photo._id)} />
                        <img src={`${process.env.REACT_APP_SERVER_PUBLIC}${photo.photo}`}
                            alt={`dropped-${index}`} className={`object-fit-cover  ${st.pictures}`} />

                    </div>
                ))}
                {droppedImages.map((image, index) => (
                    <div key={index} className={`col-sm-4 p-2 ${st.droppedImage}`}>
                        <ClearIcon className={`${st.undoPicture}`} onClick={() => handleImageRemove(index)} />
                        <img src={URL.createObjectURL(image)}
                            alt={`dropped-${index}`} className={`object-fit-cover  ${st.pictures}`} />
                    </div>
                ))}
            </div>
            <input type="file" accept="image/*" onChange={handleInputChange} multiple hidden />

            {imageCount < 5 && (
                <div className="row mt-2 mb-1">
                    <div className='col d-flex justify-content-center'>
                        <div className={`${st.photoAdder}`}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleImageDrop}
                            onClick={() => { document.getElementById('image-input').click() }}>
                            <b>Ajouter une photo</b>
                            <CloudDownloadOutlinedIcon />
                            <input id="image-input" type="file" accept="image/*"
                                onChange={handleInputChange} multiple hidden />
                        </div>
                    </div>
                </div>
            )}
            {imagesSuccess && (
                <div className="row mt-2 text-center">
                    <span className='txtSuccess'>Enregistré avec succès</span>
                </div>
            )}
            {imagesLoading && (
                <div className="row">
                    <div className="d-flex justify-content-center mt-4">
                        <div className={`${st.loading}`}></div>
                    </div>
                </div>)}
            <div className="row">
                <div className="col mt-2 text-lg-end text-center">
                    <button onClick={submitPhotos} className={`${st.savePhotos}`} >Enregistrer</button>
                </div>
            </div>
        </div>
    );
}

export default Photos;