import st from "./signup-complete.module.css";
import { useEffect, useState } from "react";
import { providerSignup, signup } from "../../services/authApi";
import { jwtDecode } from "jwt-decode";

function SignupComplete(props) {
  const { token, website } = props;
  const [showPass, setShowPass] = useState(false);
  const [userId, setUserId] = useState("");

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPass: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken) {
          setFormValues({
            email: decodedToken.email || "",
            firstname: decodedToken.firstname || "",
            lastname: decodedToken.lastname || "",
            password: "",
            confirmPass: ""
          });
          if (decodedToken._id) {
            setUserId(decodedToken._id);
          }
        }
      } catch (error) {
        window.location.href = '/signup'
      }
    }
  }, [token]);

  const [err, setErr] = useState(false);

  const handelChange = (event) => {
    setErr(false);
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const register = (event) => {
    event.preventDefault();
    let passwordPattern = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
    let namePattern = /^[a-zA-Z\s]+$/;
    let fv = formValues;
    if (!fv.firstname || !fv.lastname || !fv.password || !fv.confirmPass) {
      setErr("req");
      return;
    }
    if (!namePattern.test(fv.firstname)) {
      setErr("firstname");
      return;
    }
    if (!namePattern.test(fv.lastname)) {
      setErr("lastname");
      return;
    }
    if (!passwordPattern.test(fv.password)) {
      setErr("password");
      return;
    }

    if (fv.password !== fv.confirmPass) {
      setErr("pass");
      return;
    }
    const user = { password: fv.password, firstname: fv.firstname, lastname: fv.lastname, email: fv.email }

    if (userId === "") {
      signup(user, token, (cb) => {
        if (cb.status === 201) {
          sessionStorage.setItem("username", cb.data.username);
          sessionStorage.setItem("expiresAt", cb.data.expiresAt);
          sessionStorage.setItem("googleAccount", cb.data.googleAccount);
          sessionStorage.setItem("role", cb.data.role);
          window.location.href = "/";
        } else if (cb.response.status === 401) {
          setErr("session");
        } else {
          setErr("server")
        }
      });
    } else {
      providerSignup(userId, user, token, (cb) => {
        if (cb.status === 200) {
          sessionStorage.setItem("username", cb.data.username);
          sessionStorage.setItem("expiresAt", cb.data.expiresAt);
          sessionStorage.setItem("googleAccount", cb.data.googleAccount);
          sessionStorage.setItem("role", cb.data.role);
          window.location.href = "/account/establishments";
          return;

        } else if (cb.response.status === 403) {
          setErr("used");
          return;

        } else if (cb.response.status === 401) {
          setErr("session");
          return;

        } else {
          setErr("server");
          return;

        }
      })
    }

  };

  return (
    <form onSubmit={register}>
      <div className="row mb-4">
        <div className="col-4 d-flex justify-content-center">
          <div>
            <img onClick={() => window.location.href = "/"}
              className={st.logo}
              src={website && website.logo ? `${process.env.REACT_APP_SERVER_PUBLIC + "" + website.logo}` : "/images/logo-icon.png"}
              alt="menuonline"
            />
          </div>
        </div>
        <div className="col-8">
          <p className={`${st.formtitle}`}>S'inscrire</p>
        </div>
      </div>
      <div className="row">
        <div className="">
          Bienvenue <b>
            {formValues.lastname && formValues.firstname ?
              `${formValues.lastname} ${formValues.firstname}` :
              `${formValues.email}`}</b>
          , veuillez compléter votre inscription
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-6">
          <div className="form-group">
            <label className="form-label">Nom</label>
            <input
              type="text"
              className={`form-control ${(err === "req" && !formValues.lastname) || err === "lastname" ? "is-invalid" : ""}`}
              value={formValues.lastname}
              name="lastname"
              onChange={handelChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label className="form-label">Prénom</label>
            <input
              type="text"
              className={`form-control ${(err === "req" && !formValues.firstname) || err === "firstname" ? "is-invalid" : ""}`}
              value={formValues.firstname}
              name="firstname"
              onChange={handelChange}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <div className="form-group">
            <label className="form-label">Adresse email</label>
            <input
              type="text"
              className={`form-control ${(err === "req" && !formValues.lastname) || err === "lastname" ? "is-invalid" : ""}`}
              value={formValues.email} disabled
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-6">
          <div className="form-group">
            <label className="form-label">Mot de passe</label>
            <input
              type={showPass ? "text" : "password"}
              className={`form-control ${(err === "req" && !formValues.password) || err === "pass" || err === "password" ? "is-invalid" : ""
                }`}
              value={formValues.password}
              name="password"
              onChange={handelChange}
            />
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="form-label">Confirmer</label>
            <input
              type={showPass ? "text" : "password"}
              className={`form-control ${(err === "req" && !formValues.confirmPass) || err === "pass" ? "is-invalid" : ""}`}
              value={formValues.confirmPass}
              name="confirmPass"
              onChange={handelChange}
            />
          </div>
        </div>
      </div>

      <div className="row text-danger">
        <div className="col text-danger">
          {err === "used" && "Vous êtes déjà inscrit !"}
          {err === "pass" && "Les mots de passe ne correspondent pas !"}
          {err === "req" && "Tous les champs sont obligatoires !"}
          {err === "exists" && "Ces informations existent déjà !"}
          {err === "session" && "Session expirée !"}
          {err === "server" && "Un problème survenue, réessayer plus tard !"}
          {err === "lastname" && "Le nom ne peut contenir ni caractères spéciaux ni chiffres !"}
          {err === "firstname" && "Le prénom ne peut contenir ni caractères spéciaux ni chiffres !"}
          {err === "password" && "Le mot de passe doit contenir au moins 8 caractères et inclure une lettre majuscule ainsi qu'un chiffre."}
        </div>
      </div>
      <div className="row">
        <div className="col mt-2 d-flex justify-content-end">

          <div className={`form-check-reverse form-switch ${st.formCheck}`}>
            <label className="form-check-label">Montrer les mots de passes</label>
            <input className={`form-check-input ${st.formCheckInput}`} type="checkbox" checked={showPass} onChange={(e) => setShowPass(e.target.checked)} />
          </div>

        </div>

      </div>

      <div className="row mt-3">
        <div className="col-lg d-flex justify-content-center text-center">
          <button type="submit" className={st.btn}>
            S'inscrire
          </button>
        </div>
      </div>
    </form>
  );
}

export default SignupComplete;
