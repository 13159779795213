import { useRef } from 'react';
import { deleteItem } from '../../../../services/menuApi';
import st from './deleteItem.module.css'
function DeleteItem(props) {
    const {
        setRefresh,
        menuId,
        activeCategory,
        item,
        setErr,
        setFeedBack,
        setSuccess } = props;

    const closeBtn = useRef(null);

    const removeItem = () => {
        setErr(false);
        setFeedBack(false);
        setSuccess(false);
        let data = {
            menu: menuId,
            category: activeCategory._id,
        }
        deleteItem(item._id, data, (cb) => {
            if (cb.status === 200) {
                setRefresh(r => !r);
                setSuccess(true);
                setFeedBack("Article supprimé avec succès");
                closeBtn.current.click();
                return;
            } else {
                setErr(true);
                setFeedBack("Un problem est survenue, réessayer plus tard !");
            }
        })
    }
    return (
        <div className={`modal fade`} id="deleteItemModal" tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog modal-md`}>
                <div className={`modal-content ${st.modalContent}`}>
                    <div className="row relative">
                        <div className="col">
                            <button ref={closeBtn} type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" aria-label="Close"></button>
                            <h4 className='mt-4 ms-4'>Supprimer article</h4>
                        </div>
                    </div>
                    <div className="row mt-2 ps-4 pe-4">
                        <div className="col">
                            <span>Êtes-vous sur de vouloir supprimer l'article <b>{item.name}</b>?</span> <br />
                        </div>
                    </div>

                    <div className="row mt-4 mb-3 pe-md-3" >
                        <div className="col text-md-end text-center">
                            <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                            <button onClick={removeItem} className={`${st.save}`}>Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteItem;