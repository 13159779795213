import { useEffect, useState, useRef } from "react";
import st from "./general.module.css";
import { getEstablishmentsTypes } from "../../../services/establishmentApi";
import MyLocationIcon from '@mui/icons-material/MyLocation';

function General(props) {
    const { establishment, err, setErr, formValues, setFormValues, oldUrl } = props
    const [providedTypes, setProvidedTypes] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const customUrl = useRef(oldUrl);

    useEffect(() => {
        getEstablishmentsTypes((cb) => {
            setProvidedTypes(cb.data.establishmentsTypes);
        })
    }, [])

    useEffect(() => {
        setFormValues({
            location: establishment ? establishment.location : establishment ? establishment.location : "",
            phone: establishment ? establishment.phone : "",
            name: establishment ? establishment.name : "",
            type: establishment ? establishment.type : "",
            description: establishment ? establishment.description : "",
            customUrl: establishment ? establishment.customUrl : ""
        });
        setSelectedTypes(establishment ? establishment.type : []);
    }, [setFormValues, establishment])

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleChange = (e) => {
        setErr(false);
        const { name, value } = e.target;
        let modifiedValue = value;
        if (name === 'name' || name === 'location' || name === 'description') {
            modifiedValue = capitalizeFirstLetter(value);
        }
        if (name === 'customUrl') {
            modifiedValue = value.replace(/\s+/g, '-');
        }
        setFormValues({ ...formValues, [name]: modifiedValue });
        if (name === 'customUrl' && modifiedValue === "") {
            customUrl.current = oldUrl;
        } else if (name === 'customUrl' && modifiedValue !== "") {
            customUrl.current = modifiedValue;
        }
    }


    const toggleSelectCard = (type) => {
        let updatedSelectedTypes;
        if (selectedTypes.includes(type)) {
            if (selectedTypes.length > 1) {
                updatedSelectedTypes = selectedTypes.filter((selectedType) => selectedType !== type);
            }
        } else {
            updatedSelectedTypes = [...selectedTypes, type];
        }
        if (updatedSelectedTypes) {
            setSelectedTypes(updatedSelectedTypes);
        }
    }

    useEffect(() => {
        setFormValues(prevFormValues => ({ ...prevFormValues, type: selectedTypes }));
    }, [selectedTypes, setFormValues]);


    return (
        <div>
            <div className="ps-4 pe-4">
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className={`form-group`}>
                            <label className='form-label'>Nom<span className="text-danger">*</span></label>
                            <input type="text" name='name' placeholder="Nom d'établissement"
                                className={`form-control ${err && formValues.name === "" && "is-invalid"}`}
                                value={formValues.name} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className={`form-group`}>
                            <label className='form-label'>Téléphone<span className="text-danger">*</span></label>
                            <input type="number" name='phone' placeholder='Téléphone'
                                className={`form-control ${err && formValues.phone === "" && "is-invalid"}`}
                                value={formValues.phone} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className={`form-group`}>
                            <label className='form-label'>Adresse<span className="text-danger">*</span></label>
                            <div className={`${st.myLocation}`}>
                                <input type="text" value={formValues.location}
                                    onChange={handleChange} name='location'
                                    placeholder="EX : Midoun, Djerba, Tunisie" className={`form-control ${st.location} ${err && !formValues.location && "is-invalid"}`} />
                                <div className={`${st.myLocationIcon}`}>
                                    <MyLocationIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className={`form-group`}>
                            <label className='form-label'>Identifiant personalisé :
                                <small className="text-muted"> ( Votre indetifiant actuel est : {customUrl.current} )</small>
                            </label>
                            <input type="text" name='customUrl' placeholder="Identifiant personalisé"
                                className={`form-control ${err && formValues.name === "" && "is-invalid"}`}
                                value={formValues.customUrl} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <div className={`position-relative ${st.typesContainer}`}>
                            <label className='form-label'>Type d'établissement<span className="text-danger">*</span></label>
                            <div className="row px-5">
                                {
                                    providedTypes.map((type, key) => (
                                        <div key={key} onClick={() => { toggleSelectCard(type) }}
                                            className={`${selectedTypes && selectedTypes.includes(type) ? st.selectedCard : st.card} col-lg m-2 position-relative card py-2 d-flex align-items-center justify-content-center`}>
                                            <span>{type}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <div className={`form-group`}>
                            <label className='form-label'>Description</label>
                            <textarea rows={2} type="number" name='description' placeholder="À propos d'établissement"
                                className={`form-control ${err && formValues.phone === "" && "is-invalid"}`}
                                value={formValues.description} onChange={handleChange} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default General;