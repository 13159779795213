import st from "./dashboard.module.css"
import Footer from "../../components/footer/footer";
import StoreIcon from '@mui/icons-material/Store';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import { useEffect, useState } from "react";
import { getProviderEstablishments } from "../../services/establishmentApi";
import { getAllMenus } from "../../services/menuApi";
import ItemsPerMenu from "../../components/providerDashborad/itemsPerMenu";
import MenuPerEstab from "../../components/providerDashborad/menuPerEstab";
function Dashboard(props) {
    const { website } = props;
    const [establishments, setEstablishments] = useState([])
    const [menus, setMenus] = useState([])

    useEffect(() => {
        getProviderEstablishments((cb) => {
            if (cb.status === 200) {
                setEstablishments(cb.data.establishments)
            }
        })
        getAllMenus((cb) => {
            if (cb.status === 200) {
                setMenus(cb.data.menus)
            }
        })
    }, [])


    const providerMenus = menus && menus.filter(menu => {
        return establishments && establishments.some(establishment => establishment._id === menu.establishment);
    });

    const countItems = () => {
        let totalItems = 0;
        providerMenus.forEach(menu => {
            menu.categories.forEach(category => {
                totalItems += category.items.length;
            });
        });
        return totalItems;
    };

    return (
        <div className="clientPage">
            <div className="container py-3">
                <div className="row">
                    <div className="col p-2" >
                        <div className={`p-3 ${st.card}`}>
                            <h6 className={`text-muted ${st.cardTitle}`}>Établissements</h6>
                            <div className="position-relative">
                                <StoreIcon className={st.icon} /><h5 className='ms-1'>{establishments && establishments.length} </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col p-2" >
                        <div className={`p-3 ${st.card}`}>
                            <h6 className={`text-muted ${st.cardTitle}`}>Menus</h6>
                            <div className="position-relative">
                                <MenuBookIcon className={st.icon} /><h5 className='ms-1'>{providerMenus && providerMenus.length} </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col p-2" >
                        <div className={`p-3 ${st.card}`}>
                            <h6 className={`text-muted ${st.cardTitle}`}>Articles</h6>
                            <div className="position-relative">
                                <BrunchDiningIcon className={st.icon} /><h5 className='ms-1'>{countItems()} </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-6 mb-3">
                        <MenuPerEstab establishments={establishments} menus={menus} />
                    </div>
                    <div className="col-lg-6">
                        <ItemsPerMenu providerMenus={providerMenus} />
                    </div>
                </div>
            </div>
            <Footer website={website} />
        </div>
    );
}

export default Dashboard;