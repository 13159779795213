import st from "./signup.module.css";
import SignupVerify from "../../components/signup-verify/signup-verify";
import SignupComplete from "../../components/signup-complete/signup-complete";

function Signup(props) {
  const { website } = props;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get('token');

  return (
    <div className={`${st.signup} position-relative`}>
      <img fetchpriority="high" className={`object-fit-cover ${st.backgroundImg}`} src={website && website.authBackground ? `${process.env.REACT_APP_SERVER_PUBLIC}${website.authBackground}` : "/images/guestbg.jpg"} alt="" />
      <div className="container">
        <div className={`row  ${st.intro}`}>
          <div className="col-lg-6 text-center">
            <div>
              <h3 className={`d-flex justify-content-end ${st.title}`}>
                LE MENU DIGITAL
              </h3>
              <h2 className={`${st.bigtitle}`}>
                C'est l'avenir de la restauration !
              </h2>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <div className={`card p-5 ${st.card}`}>
              <div>
                {token ? <SignupComplete token={token} website={website} /> : <SignupVerify website={website} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
